<template>
  <figure
    v-if="iconPath"
    class="ui-icon"
  >
    <div
      class="icon-wrapper"
      :data-icon="icon"
      :data-inverted="inverted"
      :data-use-background="useBackground"
      :data-has-click="hasClick"
      v-on="hasClick ? { click: clickFunction } : {}"
    >
      <i
        class="icon-container"
        :style="iconStyles"
      />
    </div>
  </figure>
</template>

<script>
import { ICONS } from '@/constants/constants-icons';
import VueTypes from 'vue-types';
import consoleLog from '@/assets/js/helpers/console-log';

export default {
  name: 'UiIcon',
  props: {
    icon: VueTypes.oneOf([...Object.values(ICONS)]).isRequired,
    useBackground: {
      type: Boolean,
      default: false,
    },
    // @todo Is inverted ever good for something?
    inverted: {
      type: Boolean,
      default: false,
    },
    clickFunction: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      iconPath: '',
    };
  },
  computed: {
    iconStyles() {
      return {
        maskImage: `url(${this.iconPath})`,
      };
    },
    hasClick() {
      return typeof this.clickFunction === 'function';
    },
  },
  watch: {
    icon: {
      immediate: true,
      handler() {
        this.setIconPath();
      },
    },
  },
  methods: {
    async setIconPath() {
      try {
        /* eslint-disable-next-line prefer-template */
        const { default: iconPath } = await import('@/assets/uiIcons/' + this.icon + '.svg');
        this.iconPath = iconPath;
      } catch (error) {
        this.iconPath = '';
        consoleLog(error);
      }
    },
  },
};
</script>

<style lang="scss">
.ui-icon {
  --icon-color: currentColor;
  --icon-size: 24px;
  --bg-color: #{$brand-gray-lighter};
  --bg-size-rate: 2.5;
  --bg-size: calc(var(--icon-size) / var(--bg-size-rate));
  --bg-radius: 50%;

  display: inline-block;
  margin: 0;

  .icon-wrapper {
    opacity: 1;
    transition: opacity .3s ease;
    position: relative;

    .icon-container {
      display: block;
      width: var(--icon-size);
      height: var(--icon-size);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: var(--icon-color);
      transition: mask-image .2s ease, background-color .2s ease;
    }

    &[data-has-click] {
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    &[data-use-background] {
      padding: var(--bg-size);
      background-color: var(--bg-color);
      border-radius: var(--bg-radius);
    }

    &[data-inverted] {
      background-color: rgba(0, 0, 0, .4);

      .icon-container {
        background-color: $color-white;
      }
    }
  }
}
</style>

<style lang="scss" theme="cupra">
.ui-icon {
  --bg-size-rate: 3.5;
  --bg-radius: 3px;
}
</style>
