import { CONFIGURATOR_STEPS } from '@/constants/constants-configurator';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';

const SuccessStore = {
  state: {
    successOrderId: null,
  },
  getters: {
    successOrderId(state) {
      return state.successOrderId;
    },
  },
  mutations: {
    UPDATE_SUCCESS_ORDER_ID(state, orderId = null) {
      state.successOrderId = orderId;
    },
  },
  actions: {
    async customerOrderSuccess({ getters, dispatch, commit }, orderId) {
      const preReservationAction = getters.isChangingOrder() ? 'Updated' : 'Completed';
      const originalDealer = getters.isChangingOrder()
        ? getters.getOpenedOrderState().dealerNumber
        : getters.activePreReservation.dealerNumber;

      commit('UPDATE_SUCCESS_ORDER_ID', orderId);
      commit('UPDATE_OTP_SENT', false);
      await dispatch('setModelConfigStepComplete');
      await dispatch('trackCustomerPreReservationConversion', { action: preReservationAction });

      if (originalDealer !== getters.selectedDealer) {
        await dispatch('trackCustomerPreReservationConversion', { action: 'Changed dealer' });
      }

      await dispatch('completeModelPrecedingConfigSteps', { stepKey: CONFIGURATOR_STEPS.confirmation });
      await dispatch('setModelCurrentConfigStep', { stepKey: CONFIGURATOR_STEPS.confirmation });

      this._vm.$tracking.datadog.configuratorOrderCompleted();
      this._vm.$tracking.adobe.configuratorComplete();
    },
    trackCustomerPreReservationConversion({ getters }, { action = '' }) {
      const preReservationId = getters.isChangingOrder()
        ? getters.getOpenedOrderState().preReservationId
        : getters.activePreReservation.id;

      const actionPrefix = getters.isChangingOrder()
        ? 'Updating Order:'
        : 'New Order:';

      if (getters.getModelPreReservationRequired() && preReservationId) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$tracking.trackEvent('GoogleAnalyticsGeneralEvent', {
          category: 'PreReservationConversion',
          action: `${actionPrefix} ${action}`,
          label: preReservationId,
        });
      }
    },
    clearSuccessData({ commit }) {
      commit('UPDATE_SUCCESS_ORDER_ID');
    },
    // @todo @deprecated dealerOrderSuccess and paymentOrderSuccess are not used
    async dealerOrderSuccess({ dispatch, commit }, { orderId }) {
      const router = await getClientRouter();

      router.push(`/order-complete/${orderId}`);
      commit('UPDATE_OPENED_ORDER_STATE');
      await dispatch('resetModelConfigurator');
    },
    async paymentOrderSuccess({ dispatch, commit }, orderId = null) {
      const router = await getClientRouter();
      commit('UPDATE_SUCCESS_ORDER_ID', orderId);
      commit('UPDATE_OTP_SENT', false);
      dispatch('logout', false);
      router.push('/configurator/payment/success');
    },
  },
};

export default SuccessStore;
