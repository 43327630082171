import httpInternal from '@/http/httpInternal';
import httpExternal from '@/http/httpExternal';

const ApiQueueStore = {
  state: {
    position: -1,
    estimatedWait: -1,
    apiQueueReady: false,
  },
  getters: {
    apiQueue: (state) => state,
    apiQueueReady: (state) => state.apiQueueReady,
    apiQueueUrls: (state, getters) => {
      const urls = [];
      if ('ussEnrollUserUrl' in getters) urls.push(getters.ussEnrollUserUrl);
      if ('ussEnrollNewUserUrl' in getters) urls.push(getters.ussEnrollNewUserUrl);
      return urls;
    },
    shouldUrlQueue: (state, getters) => (url) => getters.apiQueueUrls.includes(url),
  },
  mutations: {
    UPDATE_API_QUEUE_READY(state, apiQueueReady = false) {
      state.apiQueueReady = apiQueueReady;
    },
    UPDATE_API_QUEUE_POSITION(state, { position = -1, estimatedWait = -1 } = {}) {
      state.position = position;
      state.estimatedWait = estimatedWait;
    },
  },
  actions: {
    async initApiQueueHandling({ getters, commit, dispatch }) {
      if (!getters.hasWebSocketUrl) return;

      const apiQueueHandling = async (config) => {
        if (!getters.shouldUrlQueue(config.url)) {
          return config;
        }

        if (!getters.isSocketConnected) {
          const waitConnection = async () => new Promise((resolve, reject) => {
            let unwatchConnectionError;

            const unwatchIsSocketConnected = this.watch(
              (storeState, storeGetters) => storeGetters.isSocketConnected,
              (newValue) => {
                if (newValue === true) {
                  unwatchIsSocketConnected();
                  unwatchConnectionError();
                  resolve();
                }
              },
            );

            unwatchConnectionError = this.watch(
              (storeState, storeGetters) => storeGetters.socketConnectionError,
              (newValue) => {
                if (newValue === true) {
                  unwatchIsSocketConnected();
                  unwatchConnectionError();
                  reject();
                }
              },
            );

            dispatch('connectToWebSocket');
          });

          try {
            await waitConnection();
          } catch (error) {
            return config;
          }
        }

        return new Promise((resolve) => {
          let unwatchIsSocketConnected;

          const unwatchPosition = this.watch(
            (storeState, storeGetters) => storeGetters.apiQueue.position,
            (newValue) => {
              if (newValue === 0) {
                resolve(config);
                commit('UPDATE_API_QUEUE_POSITION');
                unwatchPosition();
                unwatchIsSocketConnected();
              }

              if (newValue === -1) {
                unwatchPosition();
                resolve(false); // This causes errors but no better solution right now
              }
            },
          );

          unwatchIsSocketConnected = this.watch(
            (storeState, storeGetters) => storeGetters.isSocketConnected,
            (newValue) => {
              if (newValue === false) {
                dispatch('setAlert', { key: 'apiQueueError', message: 'error.apiQueueDisconnected' });
                commit('UPDATE_API_QUEUE_POSITION');
                unwatchPosition();
                unwatchIsSocketConnected();
                resolve(false); // This causes errors but no better solution right now
              }
            },
          );

          dispatch('placeInApiQueue');
        });
      };

      httpExternal.interceptors.request.use(apiQueueHandling);
      httpInternal.interceptors.request.use(apiQueueHandling);
      commit('UPDATE_API_QUEUE_READY', true);
    },
    placeInApiQueue({ dispatch }) {
      dispatch('sendSocketData', { action: 'placeInQueue' });
    },
    leaveApiQueue({ commit, dispatch }) {
      dispatch('sendSocketData', { action: 'leaveQueue' });
      commit('UPDATE_API_QUEUE_POSITION');
    },
  },
};

export default ApiQueueStore;
