<template>
  <div
    v-if="showAdminLink"
    ref="adminLink"
    class="admin-link"
    :title="title"
  >
    <UiIcon
      class="admin-link-icon"
      :icon="ICONS.cog"
      :use-background="true"
      :click-function="onClick"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ICONS } from '@/constants/constants-icons';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';
import { isUrl } from '@/assets/js/helpers/general-helpers';

const HOVER_ATTRIBUTE = 'data-admin-link-hover';
export default {
  name: 'AdminLink',
  components: {
    UiIcon,
  },
  props: {
    adminLink: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      ICONS,
      hoverTrigger: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'isAdminMode',
    ]),
    validLink() {
      return isUrl(this.adminLink);
    },
    showAdminLink() {
      return this.isAdminMode && this.validLink;
    },
  },
  watch: {
    showAdminLink: {
      immediate: true,
      handler(isEnabled) {
        this.$nextTick(() => {
          const { adminLink } = this.$refs;
          if (adminLink) this.hoverTrigger = adminLink.parentElement;
          if (!this.hoverTrigger) return;

          isEnabled
            ? this.hoverTrigger.setAttribute(HOVER_ATTRIBUTE, true)
            : this.hoverTrigger.removeAttribute(HOVER_ATTRIBUTE);
        });
      },
    },
  },
  methods: {
    onClick() {
      window.open(this.adminLink, '_blank');
    },
  },
};
</script>

<style lang="scss">
.admin-link {
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  z-index: 1;

  .admin-link-icon {
    --icon-size: 22px;
  }

  .icon-wrapper {
    box-shadow: 0 0 3px rgba(0,0,0,.4);
  }
}

[data-show-admin-hover], {
  .admin-link {
    transition: opacity .3s ease;
  }

  &:hover {
    .admin-link {
      opacity: 1;
    }
  }
}

[data-admin-link-hover] {
  > .admin-link {
    transition: opacity .3s ease;
  }

  &:hover {
    > .admin-link {
      opacity: 1;
    }
  }
}

</style>
