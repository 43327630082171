import setupRouter from '@/common-router';
import { ROUTES } from '@/constants/constants-routes';
import { ROUTE_CONFIGURATOR_PARAMS } from '@/constants/constants-router';

export default setupRouter([
  {
    name: ROUTES.configurator,
    path: `/bygg-ny-bil/${ROUTE_CONFIGURATOR_PARAMS}`,
  },
]);
