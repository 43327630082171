import httpInternal from '@/http/httpInternal';

const CreditCheckStore = {
  state: {
    creditCheckDecision: [],
  },
  getters: {
    creditCheckDecision: (state) => state.creditCheckDecision || [],
  },
  mutations: {
    UPDATE_CREDIT_CHECK_DECISION(state, decision) {
      state.creditCheckDecision = decision;
    },
  },
  actions: {
    async fetchAndSetCreditCheckDecision({ getters, commit, dispatch }) {
      dispatch('setLoader', true);
      let result = [];

      const payload = {
        firstName: getters.userInfo.firstName,
        lastName: getters.userInfo.lastName,
        ssn: getters.userInfo.ssn,
      };

      try {
        const { data: response = [] } = await httpInternal({
          method: 'post',
          url: getters.apiCreditCheckDecisionUrl,
          data: payload,
        });

        result = response.decision;
      } catch (e) {
        dispatch('setLoader', false);
        dispatch('setAlert', {
          key: 'creditCheckFormError',
          message: 'configurator.creditCheck.error',
          log: e,
        });
      } finally {
        commit('UPDATE_CREDIT_CHECK_DECISION', result);
        dispatch('setLoader', false);
      }
    },
  },
};

export default CreditCheckStore;
