<template src="./appHeader.html" />

<script>
import { mapGetters } from 'vuex';
import Velocity from 'velocity-animate';
import LayoutBaseContainer from '@/components/layout/LayoutBaseContainer.vue';
import CmsSnippet from '@/components/cms/CmsSnippet.vue';
import UiLink from '@/components/ui/uiLink/UiLink.vue';
import UiModal from '@/components/ui/uiModal/UiModal.vue';
import DealerLogoutButton from '@/components/administration/DealerLogoutButton.vue';
import { menuScripts } from '@/assets/js/bricks/menu';
import { globalSticky } from '@/assets/js/helpers/sticky-global';
import { isUrl } from '@/assets/js/helpers/general-helpers';

export default {
  name: 'AppHeader',
  components: {
    LayoutBaseContainer,
    CmsSnippet,
    UiLink,
    UiModal,
    DealerLogoutButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'isDealerLoggedIn',
      'getLoggedInDealer',
      'getConfigByKeyString',
    ]),
    logoUrl() {
      const redirectUrl = this.getConfigByKeyString('client.homePageRedirectLink.href');
      return isUrl(redirectUrl) ? redirectUrl : '/';
    },
  },
  mounted() {
    if (this.dealerLoggedIn) {
      menuScripts();
      globalSticky();
    }
  },
  methods: {
    onSnippetLoaded() {
      const self = this;
      menuScripts();
      globalSticky();
      Velocity(
        self.$refs.appHeader,
        { opacity: 1 },
        {
          duration: 300,
          complete() {
            self.$refs.appHeader.style.removeProperty('opacity');
          },
        },
      );
    },
    logoutCallback() {
      menuScripts();
    },
  },
};
</script>

<style lang="scss" src="./appHeader.scss"></style>
<style lang="scss" theme="audi" src="./appHeader.audi.scss"></style>
<style lang="scss" theme="seat" src="./appHeader.seat.scss"></style>
<style lang="scss" theme="cupra" src="./appHeader.cupra.scss"></style>
<style lang="scss" theme="skoda" src="./appHeader.skoda.scss"></style>
<style lang="scss" theme="vw" src="./appHeader.vw.scss"></style>
<style lang="scss" theme="catalog" src="./appHeader.catalog.scss"></style>
