import get from 'lodash/get';
import httpInternal from '@/http/httpInternal';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  getClientRouter,
} from '@/assets/js/helpers/config-helpers';
import { setupInternalHttpClient } from '@/assets/js/helpers/http-helpers';
import { setI18nLanguage } from '@/setup/i18n-setup';
import i18n from '@/setup/i18n-init';
import registerServiceWorker from '@/registerServiceWorker';
import { ROUTES } from '@/constants/constants-routes';
import { isUrl } from '@/assets/js/helpers/general-helpers';

const ConfigStore = {
  state: {
    appConfig: {},
    appScope: undefined,
    appLoadedLanguages: [],
    appPreviewAuthorizationKey: undefined,
    appCriticalError: false,
  },
  getters: {
    appConfig: (state) => state.appConfig,
    appScope: (state) => state.appScope,
    appPreviewAuthorizationKey: (state) => state.appPreviewAuthorizationKey,
    appCriticalError: (state) => state.appCriticalError,
    getConfigByKeyString: (state) => (keyString) => get(state.appConfig, keyString),
    isAdminMode: (state, getters) => !!getters.appPreviewAuthorizationKey,
    getSimplifiedRoutePath: () => (route) => {
      const configuratorRoutes = [
        ROUTES.configurator,
        ROUTES.stockCarsConfigurator,
      ];

      if (configuratorRoutes.includes(route.name)) {
        const { stepKey } = route.params ?? {};
        if (!stepKey) return undefined;

        return `/${route.name}/${stepKey}`;
      }

      if (route.name === ROUTES.orderVerification) {
        return `/${route.name}`;
      }

      return route.path;
    },
  },
  mutations: {
    UPDATE_APP_CONFIG(state, appConfig) {
      state.appConfig = appConfig;
    },
    UPDATE_APP_SCOPE(state, appScope) {
      state.appScope = appScope;
    },
    UPDATE_APP_CRITICAL_ERROR(state, criticalError = false) {
      state.appCriticalError = criticalError;
    },
    UPDATE_APP_PREVIEW_AUTHORIZATION_KEY(state, previewAuthorizationKey = undefined) {
      state.appPreviewAuthorizationKey = previewAuthorizationKey;
    },
    ADD_APP_LANGUAGE(state, language = '') {
      if (!state.appLoadedLanguages.includes(language)) {
        state.appLoadedLanguages.push(language);
      }
    },
  },
  actions: {
    async initAppEnvironment({ commit, dispatch }, appEnvOptions = {}) {
      try {
        const router = await getClientRouter();

        registerServiceWorker(this, router);

        const scope = process.env.client;

        setupInternalHttpClient(scope);
        commit('UPDATE_APP_SCOPE', scope);

        const primaryConfigPromises = [
          dispatch('fetchAndSetAppConfig'),
          dispatch('fetchAndSetAppLanguage'),
        ];

        await Promise.all(primaryConfigPromises);

        const secondaryConfigPromises = [
          dispatch('initWebSocket'),
          dispatch('initAppBackButtonOverride'),
          dispatch('initDeviceCheck'),
          dispatch('initAppProgressBar'),
          dispatch('initAppRouter'),
          dispatch('initTracking'),
          dispatch('initNps'),
          dispatch('initApiQueueHandling'),
          dispatch('fetchAndSetAnnouncements'),
          dispatch('checkAndSetPreviewAuthorizationKey'),
        ];

        if (appEnvOptions.useDealers) secondaryConfigPromises.push(dispatch('initDealers'));

        await Promise.all(secondaryConfigPromises);
      } catch (error) {
        consoleLog(`Application critical error: ${error}`);
        dispatch('setAppCriticalError');
      }
    },
    async fetchAndSetAppConfig({ getters, commit }) {
      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetConfigUrl,
          },
        );
        commit('UPDATE_APP_CONFIG', response.data);
      } catch (error) {
        consoleLog(error);
        throw error;
      }
    },
    async fetchAndSetAppLanguage({ state, getters, commit }, language = process.env.language) {
      if (!state.appLoadedLanguages.includes(language)) {
        commit('ADD_APP_LANGUAGE', language);
        setI18nLanguage(language);
      }

      try {
        const translationsResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetTranslationsUrl,
          params: {
            language,
          },
        });

        i18n.setLocaleMessage(language, translationsResponse.data);
        commit('ADD_APP_LANGUAGE', language);
        setI18nLanguage(language);
      } catch (error) {
        consoleLog(error);
        throw error;
      }
    },
    async initAppBackButtonOverride(context, force = false) {
      if (window.history.length < 3 || force) {
        const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;

        window.history.pushState({}, null, '/');
        window.history.pushState({}, null, url);
      }
    },
    async initAppProgressBar() {
      const router = await getClientRouter();

      this._vm.$Progress.start();
      router.beforeEach((to, from, next) => {
        const stopNavigation = router.beforeHooks.findIndex((x) => x.stopNavigation) !== -1;
        const noPageTransition = router.beforeHooks
          .findIndex((x) => x.noPageTransition) !== -1;

        if (to.path !== from.path && !stopNavigation && !noPageTransition) {
          if (to.meta.progress !== undefined) {
            const meta = to.meta.progress;
            this._vm.$Progress.parseMeta(meta);
          }
          this._vm.$Progress.start();
        }

        next();
      });
    },
    async initAppRouter({ getters, dispatch }) {
      const router = await getClientRouter();
      const { route: currentRoute } = router;
      const homePageRedirectUrl = getters.getConfigByKeyString('client.homePageRedirectLink.href');

      const shouldRedirectHome = (
        route = {},
      ) => route.name === ROUTES.home && isUrl(homePageRedirectUrl);

      const redirectHome = () => {
        window.location = homePageRedirectUrl;
      };

      if (shouldRedirectHome(currentRoute)) {
        redirectHome();
        return;
      }

      // @todo @refactor DealerLoggedIn is not used anymore
      router.beforeEach((to, from, next) => {
        if (shouldRedirectHome(to)) {
          redirectHome();
          return;
        }

        const stopNavigation = router.beforeHooks.findIndex((x) => x.stopNavigation) !== -1;
        const noPageTransition = router.beforeHooks
          .findIndex((x) => x.noPageTransition) !== -1;

        if (stopNavigation) {
          next();
          return;
        }

        let nextFunction = next;
        if (to.matched.some((record) => record.meta.requiresAuth)) {
          if (getters.dealerLoggedIn === false) {
            nextFunction = next({
              path: '/signin',
              query: { redirect: to.fullPath },
            });
          }
        }

        if (to.matched.some((record) => record.meta.requiresUnauth === true)) {
          if (getters.dealerLoggedIn === true) {
            nextFunction = next({
              path: '/',
            });
          }
        }

        if (to.path !== from.path && !noPageTransition) {
          dispatch('pageTransitionOut', nextFunction);
        } else {
          next();
        }
      });

      router.afterEach((to) => {
        this._vm.$tracking.datadog.startDataDogView(getters.getSimplifiedRoutePath(to));
      });
    },
    setAppCriticalError({ commit }) {
      commit('UPDATE_APP_CRITICAL_ERROR', true);
    },

    checkAndSetPreviewAuthorizationKey({ commit }) {
      const searchParams = new URLSearchParams(window.location.search);
      const pak = searchParams.get('pak');

      if (pak) {
        commit('UPDATE_APP_PREVIEW_AUTHORIZATION_KEY', pak);
      }
    },
  },
};

export default ConfigStore;
