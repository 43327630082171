import Vue from 'vue';
import consoleLog from '@/assets/js/helpers/console-log';
import { getClientStore, getClientRouter } from '@/assets/js/helpers/config-helpers';
import { roundNumber } from '@/assets/js/helpers/general-helpers';
import { FINANCING_OPTIONS } from '@/constants/constants-financing';
import { CLIENTS } from '@/constants/constants-clients';
import { formatCarBuyDepartmentName } from '@/assets/js/helpers/carbuy-helpers';
import { SELECTION_TYPES } from '@/constants/constants-configurator';

// DEBUG MODE FOR ADOBE
// _satellite.setDebug(true);

const ECOM_ADOBE_LAUNCH_SCRIPT = {
  dev: '//assets.adobedtm.com/9ec761efc3e5/48fc5dc21bc3/launch-6e6450dcdb7b-development.min.js',
  stage: '//assets.adobedtm.com/9ec761efc3e5/48fc5dc21bc3/launch-ec22582b1983-staging.min.js',
  prod: '//assets.adobedtm.com/9ec761efc3e5/48fc5dc21bc3/launch-239cc95af5f0.min.js',
};

const CARBUY_ADOBE_LAUNCH_SCRIPT = {
  dev: '//assets.adobedtm.com/9ec761efc3e5/6d9473eeda51/launch-678201468dc9-development.min.js',
  stage: '//assets.adobedtm.com/9ec761efc3e5/6d9473eeda51/launch-3be07d57ef9b-staging.min.js',
  prod: '//assets.adobedtm.com/9ec761efc3e5/6d9473eeda51/launch-ef41802f3f68.min.js',
};

const ADOBE_LAUNCH_SCRIPT = process.env.client === CLIENTS.carbuy
  ? CARBUY_ADOBE_LAUNCH_SCRIPT
  : ECOM_ADOBE_LAUNCH_SCRIPT;

const ADOBE_EVENTS = {
  general: {
    pageLoaded: 'Page Loaded', // Done
    pageError: 'Page Error', // Done (on CMS pages)
    scrollDepth: 'Scroll Depth Tracking', // Done
    internalSearch: 'Internal Search Terms', // N/A
    accordionClick: 'Accordionclicks', // Done
    buttonClick: 'Click', // Done
    mapClick: 'mapInteraction', // Done
  },
  configurator: {
    lineSelected: 'Form View', // Done
    stepChanged: 'Form Event', // Done
    firstInteraction: 'Form Start', // Done
    complete: 'Form Complete', // Done
    progressBarClick: 'Step overview', // Done
    financingSlider: 'Slider', // Done
    addOption: 'scAdd', // Done
    removeOption: 'scRemove', // Done
    transaction: 'purchase', // Done
  },
  carBuy: {
    filter: {
      addFilter: 'filter', // Done
      removeFilter: 'removeFilter', // Done
    },
    bookViewing: {
      viewed: 'Form View', // Done
      started: 'Form Start', // Done
      complete: 'Form Complete', // Done
      stepChanged: 'Form Event', // Done
    },
    car: {
      viewed: 'prodView', // Done
      booked: 'test drive bookings', // Done
    },
  },
};

const CONFIGURATOR_FORM_TYPE = 'Order car';

const CARBUY_BOOK_VIEWING_FORM = 'Book Viewing';
const CARBUY_BOOK_VIEWING_FORM_TYPE = 'New appointment';

const AdobeTracking = Vue.extend({
  data() {
    return {
      app: {},
      initialized: false,
      scriptLoaded: false,
      scrollTrackingPercentages: [10, 25, 50, 75, 90, 100],
      trackedScrollDepths: [],
      lastScrollPos: 0,
    };
  },
  computed: {
    enabled() {
      return this.initialized && this.scriptLoaded;
    },
  },
  methods: {
    getModelById() {
      return this.app.$store.getters.getModelById();
    },
    getMarketModelById() {
      return this.app.$store.getters.getMarketModelById();
    },
    getModelCurrentConfigStep() {
      return this.app.$store.getters.getModelCurrentConfigStep();
    },
    getModelCurrentConfigStepIndex() {
      return this.app.$store.getters.getModelCurrentConfigStepIndex();
    },
    getMarketModelOptionPrice({
      marketModelOptionId,
      financingOptionType,
      ignoreAdditionalOptions,
      alwaysIncludeVat = true,
    } = {}) {
      const args = {
        marketModelOptionId, financingOptionType, ignoreAdditionalOptions, alwaysIncludeVat,
      };

      return this.app.$store.getters.getMarketModelOptionPrice(args);
    },
    getMarketModelCategoryById({ categoryId } = {}) {
      return this.app.$store.getters.getMarketModelCategoryById({ categoryId });
    },
    getFinancingOptionData() {
      return this.app.$store.getters.getFinancingOptionData();
    },
    getMarketModelTotalCost({ alwaysIncludeVat = true } = {}) {
      const args = { alwaysIncludeVat };

      return this.app.$store.getters.getConfigurationTotalCostPerFinancingOption(args);
    },
    getMarketModelSelectionsData() {
      return this.app.$store.getters.getMarketModelSelectionsData();
    },
    getSuccessOrderId() {
      return this.app.$store.getters.successOrderId;
    },

    async initAdobeTracking() {
      if (!this.initialized) {
        this.app.$router = await getClientRouter();
        this.app.$store = await getClientStore();

        this.initialized = true;
      }

      const scriptSrc = ADOBE_LAUNCH_SCRIPT[process.env.environment];

      if (this.scriptLoaded) return;
      if (!scriptSrc) return;

      const adobeScript = document.createElement('script');
      adobeScript.async = true;
      adobeScript.src = scriptSrc;

      adobeScript.onload = () => {
        this.scriptLoaded = true;
        window.digitalData = window.digitalData || [];
        this.setupScrollTracking();
      };

      adobeScript.onerror = () => {
        this.scriptLoaded = false;
      };

      document.head.appendChild(adobeScript);
    },

    setupScrollTracking() {
      this.app.$router.afterEach(() => {
        this.lastScrollPos = 0;
        this.trackedScrollDepths = [];
      });

      window.addEventListener('scroll', () => {
        const scrollingDown = this.lastScrollPos < window.scrollY;

        if (scrollingDown) {
          const scrollDepth = roundNumber(
            (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100,
          );

          this.scrollTrackingPercentages.forEach((trackPercentage) => {
            const alreadyTracked = this.trackedScrollDepths.includes(trackPercentage);
            if (scrollDepth >= trackPercentage && !alreadyTracked) {
              this.trackedScrollDepths.push(trackPercentage);
              this.scrollTracking(trackPercentage);
            }
          });
        }

        this.lastScrollPos = window.scrollY;
      });
    },

    pushAdobeData(internalKey = '', data = {}) {
      if (!this.enabled) return;

      consoleLog(`[ADOBE TRACKING]: ${internalKey}`, data);
      window.digitalData.push(data);
    },

    // --- General tracking START ---
    pageLoaded() {
      if (!this.enabled) return;

      const {
        name: category, fullPath: hierarchy, path: pageID,
      } = this.app.$router.currentRoute;

      this.pushAdobeData('pageLoaded', {
        event: ADOBE_EVENTS.general.pageLoaded,
        page: {
          pageID,
          pageName: window.location.hostname,
          destinationURL: document.location?.href,
          referringURL: document.referrer,
          language: 'nb-NO',
          category,
          hierarchy,
        },
      });
    },

    pageError(error) {
      if (!this.enabled) return;

      this.pushAdobeData('pageError', {
        event: ADOBE_EVENTS.general.pageError,
        eventInfo: {
          errorCode: error, //  i.e. 404/500 errors
        },
      });
    },

    scrollTracking(scrollPercent = '0') {
      if (!this.enabled) return;

      this.pushAdobeData('scrollTracking', {
        event: ADOBE_EVENTS.general.scrollDepth,
        scrollDepth: {
          scroll: `${scrollPercent}%`, // i.e. "10%" "25%" "50%" "75%" "90%" or "100%"
        },
      });
    },

    internalSearch(searchTerm = '', searchResults = 0) {
      if (!this.enabled) return;

      this.pushAdobeData('internalSearch', {
        event: ADOBE_EVENTS.general.internalSearch,
        search: {
          searchTerm, // i.e. "Audi Q8"
          searchResults,
        },
      });
    },

    accordionClick({ headline = '', title = '' } = {}) {
      if (!this.enabled) return;

      this.pushAdobeData('accordionClick', {
        event: ADOBE_EVENTS.general.accordionClick,
        interaction: {
          interactionLabel: title, // The text of the accordion i.e. "Hva påvirker levering"
          interactionType: 'Accordion',
          time: Date.now(), // epoch time
          faqCategory: headline, // The headline of the accordion; i.e. "Pris" or "Bestilling"
        },
      });
    },

    buttonClick(label = '') {
      if (!this.enabled) return;

      this.pushAdobeData('buttonClick', {
        event: ADOBE_EVENTS.general.buttonClick,
        interaction: {
          interactionLabel: label,
          interactionType: 'click',
          interactionComponent: 'button',
          time: Date.now(), // epoch time
        },
      });
    },

    mapClick() {
      if (!this.enabled) return;

      this.pushAdobeData('mapClick', {
        event: ADOBE_EVENTS.general.mapClick,
        interaction: {
          interactionType: 'click',
          interactionComponent: 'map',
          time: Date.now(), // epoch time
        },
      });
    },
    // --- General tracking END ---

    // --- Configurator tracking START ---
    getActiveConfiguratorFormName(includeFormPrefix = true) {
      const { brand, label: modelName, year } = this.getModelById() ?? {};
      const { name: marketModelName } = this.getMarketModelById() ?? {};
      const prefix = includeFormPrefix ? 'Configurator/' : '';

      return brand && modelName && year && marketModelName
        ? `${prefix}${brand}/${modelName}/${year}/${marketModelName}`
        : undefined;
    },

    getActiveConfiguratorCurrentStepData() {
      return {
        stepName: this.getModelCurrentConfigStep()?.label,
        stepNumber: this.getModelCurrentConfigStepIndex() + 1,
      };
    },

    getConfiguratorOptionTrackingData(marketModelOption) {
      const price = this.getMarketModelOptionPrice({
        marketModelOptionId: marketModelOption?.id,
        financingOptionType: FINANCING_OPTIONS.cash,
        alwaysIncludeVat: true,
      });
      const categoryData = this.getMarketModelCategoryById({
        categoryId: marketModelOption?.categoryId,
      });
      const { name: category } = categoryData ?? {};

      return {
        productID: marketModelOption?.id,
        productName: marketModelOption?.label,
        price,
        category,
        carType: this.getActiveConfiguratorFormName(false),
      };
    },

    configuratorStepChanged() {
      if (!this.enabled) return;

      const formName = this.getActiveConfiguratorFormName();
      if (!formName) return;

      const stepData = this.getActiveConfiguratorCurrentStepData();

      this.pushAdobeData('configuratorStepChanged', {
        event: ADOBE_EVENTS.configurator.stepChanged,
        form: {
          formName, // e.i "Bestil Skoda Enyaq IV80X"
          stepName: stepData.stepName, // e.i. "Utstyrsversjon", "Eksteriør", "Interiør"
          stepNumber: stepData.stepNumber, // e.g. 1,
          time: Date.now(), // epoch time
        },
      });
    },

    configuratorLineSelected() {
      if (!this.enabled) return;

      const formName = this.getActiveConfiguratorFormName();
      if (!formName) return;

      this.pushAdobeData('configuratorLineSelected', {
        event: ADOBE_EVENTS.configurator.lineSelected,
        form: {
          formName, // e.i. "Bestil Skoda Enyaq IV80X"
          formType: CONFIGURATOR_FORM_TYPE, // Should reflect the type of form, e.i "Order car"
          time: Date.now(), // epoch time
        },
      });
    },

    configuratorProgressBarClick() {
      if (!this.enabled) return;

      const formName = this.getActiveConfiguratorFormName();
      if (!formName) return;

      const stepData = this.getActiveConfiguratorCurrentStepData();

      this.pushAdobeData('configuratorProgressBarClick', {
        event: ADOBE_EVENTS.configurator.progressBarClick,
        interaction: {
          interactionType: 'Click',
          interactionLabel: stepData.stepName, // Insert the name of the step in the progress bar
          interactionComponent: 'Progress bar',
          formName, // e.i "Bestil Skoda Enyaq IV80X"
          stepName: stepData.stepName, // e.i. "Utstyrsversjon", "Eksteriør", "Interiør", "Utstyr"
          stepNumber: stepData.stepNumber, // e.g. 1,
          time: Date.now(), // epoch time
        },
      });
    },

    configuratorFirstInteraction() {
      if (!this.enabled) return;

      const formName = this.getActiveConfiguratorFormName();
      if (!formName) return;

      this.pushAdobeData('configuratorFirstInteraction', {
        event: ADOBE_EVENTS.configurator.firstInteraction,
        form: {
          formName, // e.i "Bestil Skoda Enyaq IV80X"
          formType: CONFIGURATOR_FORM_TYPE, // Should reflect the type of form, e.i "Order car"
          time: Date.now(), // epoch time
        },
      });
    },

    configuratorComplete() {
      if (!this.enabled) return;

      const formName = this.getActiveConfiguratorFormName();
      if (!formName) return;

      this.pushAdobeData('configuratorComplete', {
        event: ADOBE_EVENTS.configurator.complete,
        form: {
          formName, // e.i "Bestil Skoda Enyaq IV80X"
          formType: CONFIGURATOR_FORM_TYPE, // Should reflect the type of form, e.i "Order car"
          time: Date.now(), // epoch time
        },
      });
    },

    configuratorFinancingSlider({ sliderName, sliderValue }) {
      if (!this.enabled) return;

      const financingOptionLabel = this.getFinancingOptionData()
        ?.content?.labels?.name;

      this.pushAdobeData('configuratorFinancingSlider', {
        event: ADOBE_EVENTS.configurator.financingSlider,
        interaction: {
          interactionType: 'Slider',
          interactionLabel: financingOptionLabel, // Name of the tap where the slider is represented
          interactionComponent: sliderName, // Insert the name of the slider
          interactionValue: sliderValue, // Should reflect the value of the slider
        },
      });
    },

    configuratorAddOption(marketModelOption) {
      if (!this.enabled) return;
      if (marketModelOption?.type !== SELECTION_TYPES.optional) return;

      const optionData = this.getConfiguratorOptionTrackingData(marketModelOption);

      this.pushAdobeData('configuratorAddOption', {
        event: ADOBE_EVENTS.configurator.addOption,
        product: {
          mainproducts: {
            productInfo: [
              {
                productID: optionData.productID,
                productName: optionData.productName, // Option name, e.i. "19" Proteus sølv"
                price: optionData.price, // Should always reflect the price for 1 unit
                quantity: 1,
                category: optionData.category, // Category name, e.i. "Interiør"
                cartype: optionData.carType, // e.i "Skoda Enyaq IV80X"
              },
            ],
          },
        },
      });
    },

    configuratorRemoveOption(marketModelOption) {
      if (!this.enabled) return;
      if (marketModelOption?.type !== SELECTION_TYPES.optional) return;
      const optionData = this.getConfiguratorOptionTrackingData(marketModelOption);

      this.pushAdobeData('configuratorRemoveOption', {
        event: ADOBE_EVENTS.configurator.removeOption,
        product: {
          mainproducts: {
            productInfo: [
              {
                productID: optionData.productID,
                productName: optionData.productName, // Option name, e.i. "19" Proteus sølv"
                price: optionData.price, // Should always reflect the price for 1 unit
                quantity: 1,
                category: optionData.category, // Category name, e.i. "Interiør"
                cartype: optionData.carType, // e.i "Skoda Enyaq IV80X"
              },
            ],
          },
        },
      });
    },

    configuratorTransaction() {
      if (!this.enabled) return;

      const orderId = this.getSuccessOrderId();

      if (!orderId) return;

      const totalPrice = this.getMarketModelTotalCost({
        financingOptionType: FINANCING_OPTIONS.cash,
      });

      const carType = this.getActiveConfiguratorFormName(false);

      const financingOptionLabel = this.getFinancingOptionData()
        ?.content?.labels?.name;

      const options = this.getMarketModelSelectionsData()
        .map((selection) => {
          const price = this.getMarketModelOptionPrice({
            marketModelOptionId: selection.id,
            financingOptionType: FINANCING_OPTIONS.cash,
            ignoreAdditionalOptions: true,
            alwaysIncludeVat: true,
          });

          return {
            quantity: 1,
            productInfo: {
              productSKU: selection.id,
              productName: selection.label,
              price,
              currency: 'NOK',
            },
          };
        });

      this.pushAdobeData('configuratorTransaction', {
        event: ADOBE_EVENTS.configurator.transaction,
        // Totals for the transaction
        transaction: {
          transactionID: orderId,
          total: {
            price: totalPrice,
            currency: 'NOK',
            financing: financingOptionLabel, // e.i. "Leasing"
            cartype: carType, // e.i "Skoda Enyaq IV80X"
          },
          // Collection of Item Objects
          product: options,
        },
      });
    },
    // --- Configurator tracking END ---

    // --- CarBuy tracking START ---
    getActiveCarBuyCarTrackingData() {
      const activeCar = this.app.$store.getters.carBuyActiveCar ?? {};

      return {
        productID: activeCar.carId,
        productSKU: activeCar.chassisNumber,
        productName: activeCar.prospectHeading,
        carPrice: activeCar.price,
        dealer: formatCarBuyDepartmentName(activeCar.departmentName),
      };
    },

    carBuyAddFilter({ filterKey, facet }) {
      if (!this.enabled) return;

      this.pushAdobeData('carBuyAddFilter', {
        event: ADOBE_EVENTS.carBuy.filter.addFilter,
        filter: {
          filterLabel: this.app.$store.getters.getCarBuyFilterGroupLabel(filterKey), // Pris
          filterType: 'checkbox', // slider
          filterValue: facet,
          results: this.app.$store.getters.carBuyTotalHits, // number of results
          time: Date.now(),
        },
      });
    },

    carBuyRemoveFilter({ filterKey, facet }) {
      if (!this.enabled) return;

      this.pushAdobeData('carBuyRemoveFilter', {
        event: ADOBE_EVENTS.carBuy.filter.removeFilter,
        filter: {
          filterLabel: this.app.$store.getters.getCarBuyFilterGroupLabel(filterKey), // Pris
          filterType: 'checkbox', // slider
          filterValue: facet,
          time: Date.now(),
        },
      });
    },

    carBuyBookViewingViewed() {
      if (!this.enabled) return;

      this.pushAdobeData('carBuyBookViewingViewed', {
        event: ADOBE_EVENTS.carBuy.bookViewing.viewed,
        form: {
          formName: CARBUY_BOOK_VIEWING_FORM, // e.g. Book trial ride or Book showing
          time: Date.now(), // epoch time
        },
      });
    },

    carBuyBookViewingStarted() {
      if (!this.enabled) return;

      this.pushAdobeData('carBuyBookViewingStarted', {
        event: ADOBE_EVENTS.carBuy.bookViewing.started,
        form: {
          formName: CARBUY_BOOK_VIEWING_FORM, // e.g. Book trial ride or Book showing
          formType: CARBUY_BOOK_VIEWING_FORM_TYPE, // i.e. New appointment
          time: Date.now(), // epoch time
        },
      });
    },

    carBuyBookViewingComplete() {
      if (!this.enabled) return;

      const { mobileNumber, email } = this.app.$store.getters.userInfo;

      this.pushAdobeData('carBuyBookViewingComplete', {
        event: ADOBE_EVENTS.carBuy.bookViewing.complete,
        form: {
          formName: CARBUY_BOOK_VIEWING_FORM, // e.g. Book trial ride or Book showing
          formType: CARBUY_BOOK_VIEWING_FORM_TYPE, // i.e. New appointment
          time: Date.now(), // epoch time
        },
        // @todo Is this really in line with GDPR?
        contact: {
          mobileNumber,
          email,
        },
      });
    },

    carBuyBookViewingStepChanged() {
      if (!this.enabled) return;

      const {
        navLabel: stepName,
        order: stepNumber,
      } = this.app.$store.getters.getCarBuyBookViewingStepByKey();

      this.pushAdobeData('carBuyBookViewingStepChanged', {
        event: ADOBE_EVENTS.carBuy.bookViewing.stepChanged,
        form: {
          formName: CARBUY_BOOK_VIEWING_FORM, // e.g. Book trial ride or Book showing
          formType: CARBUY_BOOK_VIEWING_FORM_TYPE, // i.e. New appointment
          stepName,
          stepNumber,
          time: Date.now(), // epoch time
        },
      });
    },

    carBuyCarViewed() {
      if (!this.enabled) return;

      const {
        productSKU, productID, productName, dealer,
      } = this.getActiveCarBuyCarTrackingData();

      this.pushAdobeData('carBuyCarViewed', {
        event: ADOBE_EVENTS.carBuy.car.viewed,
        product: {
          productInfo: [
            {
              productID,
              productSKU,
              productName, // e.g. "Volkswagen Caddy", "Volkswagen Arteon"
              dealer,
            },
          ],
        },
      });
    },

    carBuyCarBooked() {
      if (!this.enabled) return;

      const {
        productSKU, productID, productName, carPrice, dealer,
      } = this.getActiveCarBuyCarTrackingData();

      this.pushAdobeData('carBuyCarBooked', {
        event: ADOBE_EVENTS.carBuy.car.booked,
        product: {
          productInfo: [
            {
              productID,
              productSKU,
              productName, // e.g. "Volkswagen Caddy", "Volkswagen Arteon"
              carPrice,
              dealer,
            },
          ],
        },
      });
    },
    // --- CarBuy tracking END ---

  },
});

export default AdobeTracking;
