import SmartDigitalTracking from '@/tracking/smartDigital';
import GtmTracking from '@/tracking/gtm';
import isEmpty from 'lodash/isEmpty';
import AdobeTracking from '@/tracking/adobe';
import { DataDogTracking } from '@/tracking/datadog';

const Tracking = {
  async install(Vue, options = {}) {
    /* eslint-disable-next-line no-param-reassign */
    Vue.prototype.$tracking = Tracking;

    this.options = options;
    if (!options) this.options = {};
    if (!options.gtm) this.options.gtm = {};
    if (!options.smartDigital) this.options.smartDigital = {};
    if (!options.adobe) this.options.adobe = {};

    if (options.gtm.enabled) {
      this.gtm = new GtmTracking({
        data: {
          options: options.gtm,
        },
      });
    }

    if (options.smartDigital.enabled) {
      this.smartDigital = new SmartDigitalTracking({
        data: {
          options: options.smartDigital,
        },
      });
    }

    this.adobe = new AdobeTracking();
    if (options.adobe.enabled) {
      await this.adobe.initAdobeTracking();
    }
  },
  async installDataDog() {
    this.datadog = new DataDogTracking();
    await this.datadog.initDataDogTracking();
  },
  async trackEvent(event, eventData = {}, { gtm = true, smartDigital = true } = {}) {
    if (smartDigital && this.options.smartDigital.enabled) {
      await this.smartDigital.trackEvent(event, eventData);
    }
    if (gtm && this.options.gtm.enabled) {
      this.gtm.trackEvent(event, eventData);
    }
  },
  trackPushDataLayer(dataLayerData = {}) {
    if (this.options.gtm.enabled) {
      if (isEmpty(dataLayerData)) return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerData);
    }
  },
  trackTransaction(transactionData = {
    event: '',
    transactionId: '',
    transactionAffiliation: '',
    transactionTotal: 0,
    transactionTax: 0,
    transactionShipping: 0,
    transactionProducts: [],
  }) {
    if (this.options.gtm.enabled) {
      if (!transactionData.event || !transactionData.transactionId) return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(transactionData);
    }
  },
};

export default Tracking;
