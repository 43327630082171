<template>
  <component
    :is="elementTag"
    class="ui-link"
    :data-has-link="hasLink"
    :data-has-icon="hasIcon"
    :data-has-icon-before="hasIconBefore"
    :data-has-icon-after="hasIconAfter"
    v-bind="elementAttributes"
    @[elementEvent].stop.prevent="onClickFunction"
  >
    <UiIcon
      v-if="hasIconBefore"
      class="link-icon"
      :icon="icon"
    />
    <span class="link-text">
      <slot />
    </span>
    <UiIcon
      v-if="hasIconAfter"
      class="link-icon"
      :icon="iconAfter"
    />
  </component>
</template>

<script>
import { getModalCount, clearModalHooks } from '@/assets/js/helpers/modal-helpers';
import UiFunctionLinkBase from '@/components/ui/UiFunctionLinkBase.vue';
import VueTypes from 'vue-types';
import { ICONS } from '@/constants/constants-icons';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';

export default {
  name: 'UiLink',
  components: {
    UiIcon,
  },
  extends: UiFunctionLinkBase,
  props: {
    alwaysDisplayAsLink: {
      type: Boolean,
      default: false,
    },
    icon: VueTypes.oneOf([...Object.values(ICONS)]),
    iconAfter: VueTypes.oneOf([...Object.values(ICONS)]),
  },
  data() {
    return {
      defaultTag: 'span',
    };
  },
  computed: {
    hasLink() {
      return !!this.link || this.alwaysDisplayAsLink;
    },
    hasIconBefore() {
      return !!this.icon;
    },
    hasIconAfter() {
      return !!this.iconAfter;
    },
    hasIcon() {
      return !!this.hasIconBefore || this.hasIconAfter;
    },
  },
  methods: {
    onClickFunction() {
      if (!this.link) return null;

      if (typeof this.link === 'function') return this.link();

      if (getModalCount() > 0) clearModalHooks(this.$router);

      return this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss" src="./uiLink.scss" />
<style lang="scss" theme="carbuy" src="./uiLink.carbuy.scss" />
<style lang="scss" theme="cupra" src="./uiLink.cupra.scss" />
<style lang="scss" theme="skoda" src="./uiLink.skoda.scss" />
