export const ROUTES = {
  home: 'home',
  addToCart: 'add-to-cart',
  checkout: 'checkout',
  paymentVerification: 'payment-verification',
  orderVerification: 'order-verification',
  cancelOrder: 'cancel-order',
  modelsOverview: 'models-overview',
  configurator: 'configurator',
  carsInStock: 'cars-in-stock',
  stockCars: 'stock-cars',
  dealerStockCars: 'dealer-stock-cars',
  stockCarsConfigurator: 'stock-cars-configurator',
  dealerStockCarsConfigurator: 'dealer-stock-cars-configurator',
  eSimActivation: 'e-sim-activation',
  eSimDeactivation: 'e-sim-deactivation',
  fleetReservation: 'fleet-reservation',
  tradeIn: 'trade-in',
  scriveVerification: 'scrive-verification',
  openOrderAdmin: 'open-order-admin',
  openOrder: 'open-order',
  carBuyBookViewingSkuLoader: 'car-buy-book-viewing-sku-loader',
  carBuyBookViewing: 'car-buy-book-viewing',
  carBuyFinnRouteHandler: 'car-buy-finn-route-handler',
  carBuyPage: 'car-buy-page',
  carBuyCar: 'car-buy-car',
  carBuyPurchaseConfirmation: 'car-buy-purchase-confirmation',
  carBuyPurchaseVerification: 'car-buy-purchase-verification',
  carBuyPurchaseCancel: 'car-buy-purchase-cancel',
  carBuyTradeIn: 'car-buy-tradein-page',
  catalogProductPage: 'catalog-product-page',
  catalogProductPagePath: 'products',
  catalogCategories: 'catalog-categories-page',
  catalogCategoriesPath: 'categories',
  dealerLogin: 'dealer-login',
};
