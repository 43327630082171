<template>
  <div id="app-critical-error">
    <div class="error-image-container">
      <UiImage class="error-page-image" :image-source="imageSrc" alt="temp" />
    </div>
    <LayoutPageContent class="error-page-content">
      <div class="error-title">
        {{ errorTitle }}
      </div>
      <component :is="errorComponent" class="error-content" />
    </LayoutPageContent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutPageContent from '@/components/layout/LayoutPageContent.vue';
import consoleLog from '@/assets/js/helpers/console-log';
import { CLIENTS } from '@/constants/constants-clients';
import UiImage from '@/components/ui/uiImage/UiImage.vue';
import UiLink from '@/components/ui/uiLink/UiLink.vue';
import { ICONS } from '@/constants/constants-icons';

const ERROR_CONTENT_COMMON = {
  components: {
    UiLink,
  },
  data() {
    return {
      ERROR_TEXT_GENERAL: 'Vi opplever dessverre problemer med nettsiden for øyeblikket, og jobber med å løse dette så raskt som mulig.',
      ICONS,
    };
  },
};

const ERROR_CONTENT = {
  [CLIENTS.cupra]: {
    template: `
<div>
  <p>
    {{ ERROR_TEXT_GENERAL }}
  </p>
  <p>
    Har du spørsmål eller trenger du veiledning? <UiLink link="https://www.cupraofficial.no/kjope-bil/cupra-garage">Kontakt oss</UiLink>, så hjelper vi deg!
  </p>
  <p>
    Gå til <UiLink link="https://cupraofficial.no" :icon-after="ICONS.arrowForward">cupraofficial.no</UiLink>
  </p>
</div>
`,
  },
  [CLIENTS.skoda]: {
    template: `
<div>
  <p>
    {{ ERROR_TEXT_GENERAL }}
  </p>
  <p>
    Gå til <UiLink link="https://skoda.no" :icon-after="ICONS.arrowForward">skoda.no</UiLink>
  </p>
</div>
`,
  },
  default: {
    title: 'Siden er nede',
    template: `
<div>
  <p>
    {{ ERROR_TEXT_GENERAL }}
  </p>
</div>
`,
  },
};

export default {
  name: 'AppCriticalError',
  components: {
    UiLink,
    LayoutPageContent,
    UiImage,
  },
  data() {
    return {
      imageSrc: '',
    };
  },
  computed: {
    ...mapGetters([
      'appScope',
    ]),
    errorTitle() {
      return ERROR_CONTENT?.[this.appScope]?.title ?? ERROR_CONTENT.default.title;
    },
    errorComponent() {
      return {
        ...ERROR_CONTENT_COMMON,
        template: ERROR_CONTENT?.[this.appScope]?.template ?? ERROR_CONTENT.default.template,
      };
    },
  },
  created() {
    this.setImageSrc();
  },
  mounted() {
    this.pageTransitionIn();
  },
  methods: {
    ...mapActions([
      'pageTransitionIn',
    ]),
    async setImageSrc() {
      try {
        /* eslint-disable-next-line prefer-template */
        const { default: image } = await import('@/assets/images/themes/' + this.appScope + '/error-page-image.jpg');
        this.imageSrc = image;
      } catch (error) {
        this.imageSrc = '';
        consoleLog(error);
      }
    },
  },
};
</script>

<style lang="scss">
#app-critical-error {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media #{$mq_screen__m} {
    flex-direction: row;
    flex: 1 0 auto;
    align-items: normal;
  }

  .error-image-container {
    width: 100%;

    @media #{$mq_screen__m} {
      width: 50%;
    }
  }

  .error-page-image {
    padding-bottom: 75%;

    @media #{$mq_screen__s} {
      padding-bottom: 50%;
    }

    @media #{$mq_screen__m} {
      padding-bottom: 0;
      height: 100%;
    }
  }

  .error-page-content {
    padding-left: 20px;
    padding-right: 20px;

    @media #{$mq_screen__m} {
      padding-left: 3%;
      padding-right: 3%;
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: center;
    }
  }

  .error-title {
    font-size: 2rem;
    font-weight: $font-weight__light;
    line-height: 1;
    padding-bottom: .35em;

    @media #{$mq_screen__s} {
      font-size: 2.5rem;
    }

    @media #{$mq_screen__m} {
      font-size: 3rem;
    }
  }

  .error-content {
    max-width: 500px;

    .ui-icon {
      --icon-size: 1em;
    }
  }
}
</style>
