import httpInternal from '@/http/httpInternal';
import consoleLog from '@/assets/js/helpers/console-log';

const AnnouncementsStore = {
  actions: {
    async fetchAndSetAnnouncements({ getters, dispatch }) {
      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetAnnouncementsUrl,
          },
        );
        const { announcements = [] } = response.data;

        if (Array.isArray(announcements)) {
          announcements.forEach((announcement) => {
            dispatch('setAnnouncement', { message: announcement.message });
          });
        }
      } catch (error) {
        consoleLog(error);
      }
    },
    async setAnnouncement({ dispatch }, { message = '' } = {}) {
      if (!message || typeof message !== 'string') return;

      dispatch('setAlert', {
        key: message.toLowerCase().replace(/\s/g, '-'),
        message,
        type: 'success',
        disableLoader: false,
        autoRemove: false,
      });
    },
  },
};

export default AnnouncementsStore;
