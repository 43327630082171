<template>
  <div
    ref="outer"
    class="transition-height"
  >
    <transition
      name="transition-height"
      :duration="{ enter: duration, leave: duration }"
      appear
      mode="out-in"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
import Velocity from 'velocity-animate';
import { getElementHeight, getElementWidth } from '@/assets/js/helpers/general-helpers';

export default {
  name: 'TransitionHeight',
  props: {
    duration: {
      type: Number,
      default: 300,
    },
    isToggle: {
      type: Boolean,
      default: false,
    },
    isReady: {
      type: Boolean,
      default: true,
    },
    onEnterFunction: {
      type: Function,
      default: undefined,
    },
    // Very sketchy and specific use cases
    transitionWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'transition-ready',
  ],
  data() {
    return {
      savedEl: undefined,
      savedDone: undefined,
    };
  },
  watch: {
    isReady(isReady) {
      if (!isReady || !this.savedEl || !this.savedDone) return;
      this.onBeforeEnter();
      this.onEnter(this.savedEl, this.savedDone);
      this.savedEl = undefined;
      this.savedDone = undefined;
    },
  },
  methods: {
    onBeforeEnter() {
      if (!this.isReady) return;

      const { outer } = this.$refs;
      if (!outer) return;

      outer.style.height = `${outer.offsetHeight}px`;

      if (this.transitionWidth) {
        outer.style.width = `${outer.offsetWidth}px`;
      }

      if (outer.style.opacity === '') outer.style.opacity = '0';
      Velocity(outer, 'stop');
    },
    onEnter(el, done) {
      setTimeout(() => {
        if (!this.isReady) {
          this.savedEl = el;
          this.savedDone = done;
          return;
        }

        if (typeof this.onEnterFunction === 'function') {
          this.onEnterFunction();
        }

        this.$nextTick(() => {
          const { outer } = this.$refs;

          if (!outer) {
            done();
            return;
          }

          const computedStyles = getComputedStyle(this.$el);
          const extraTop = parseInt(computedStyles.getPropertyValue('padding-top'), 10);
          const extraBottom = parseInt(computedStyles.getPropertyValue('padding-bottom'), 10);

          const options = { height: getElementHeight(el) + extraTop + extraBottom };

          if (this.transitionWidth) {
            options.width = getElementWidth(el);
          }

          Velocity(outer, 'stop');
          Velocity(
            outer,
            options,
            {
              duration: this.duration,
              complete: () => {
                this.$emit('transition-ready');

                Velocity(
                  outer,
                  { opacity: 1 },
                  {
                    duration: this.duration,
                    complete: () => {
                      done();
                      outer.removeAttribute('style');
                    },
                  },
                );
              },
            },
          );
        });
      }, 0);
    },
    onBeforeLeave() {
      const { outer } = this.$refs;

      outer.style.height = `${outer.offsetHeight}px`;
      if (this.transitionWidth) {
        outer.style.width = `${outer.offsetWidth}px`;
      }
      Velocity(outer, 'stop');
    },
    onLeave(el, done) {
      const { outer } = this.$refs;

      const isToggleOptions = { height: 0 };
      if (this.transitionWidth) {
        isToggleOptions.width = 0;
      }

      Velocity(outer, 'stop');
      setTimeout(() => {
        Velocity(
          outer,
          { opacity: 0 },
          {
            duration: this.duration,
            easing: 'ease-out',
            complete: () => {
              done();
              if (this.isToggle) {
                Velocity(
                  outer,
                  isToggleOptions,
                  {
                    duration: this.duration,
                    complete: () => {
                      done();
                      outer.removeAttribute('style');
                    },
                  },
                );
              }
            },
          },
        );
      }, 0);
    },
  },
};
</script>

<style scoped lang="scss">
  .transition-height {
    &.velocity-animating {
      overflow: hidden;
    }
  }
</style>
