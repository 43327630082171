<template>
  <transition
    name="status-banner"
    appear
  >
    <div
      v-if="show"
      class="status-banner"
      :data-position="position"
    >
      <div class="status-inner base-container">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'StatusBanner',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    position: VueTypes.oneOf(['left', 'right', 'bottom']).def('bottom'),
  },
};
</script>

<style lang="scss">
  .status-banner {
    display: flex;
    background: rgba(0,0,0,0.9);
    padding: 1.25rem 0;
    min-height: 6.25rem;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
    transform: translateY(0);
    transition: transform .3s ease;

    .status-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      color: #fff;
      font-size: 1rem;
      font-weight: $font-weight__light;
    }

    &.status-banner-enter-active {
      transition-delay: 1s;
    }

    &.status-banner-enter,
    &.status-banner-leave-to,
    {
      transform: translateY(calc(100% + 30px));
    }

    &.status-banner-enter-to,
    &.status-banner-leave {
      transform: translateY(0);
    }

    @media #{$mq_screen__s} {
      &[data-position="left"],
      &[data-position="right"] {
        bottom: 20px;
        max-width: 435px;
        min-width: 340px;
      }

      &[data-position="left"] {
        left: 20px;
        right: unset;
      }

      &[data-position="right"] {
        left: unset;
        right: 20px;
      }
    }
  }
</style>

<style lang="scss" theme="cupra">
.status-banner {
  background-color: $brand-tertiary;
}
</style>
