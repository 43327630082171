import Vue from 'vue';
import VueNativeSock from '@/moduleOverrides/vue-native-websocket/Main';
import consoleLog from '@/assets/js/helpers/console-log';

let pingIntervalRegistration;

const stopWebSocketPing = () => {
  clearInterval(pingIntervalRegistration);
};

const startWebSocketPing = () => {
  // @todo Is this constant pinging necessary?
  stopWebSocketPing();
  pingIntervalRegistration = setInterval(() => {
    Vue.prototype.$socket.send(JSON.stringify({ action: 'ping' }));
  }, 20000);
};

const WebSocketStore = {
  state: {
    socket: {
      isConnected: false,
      reconnectError: false,
      connectionError: false,
      reconnectCount: 0,
    },
  },
  getters: {
    webSocketUrl: (state, getters) => getters.getConfigByKeyString('websocket.url'),
    hasWebSocketUrl: (state, getters) => !!getters.webSocketUrl,
    isSocketConnected: (state) => state.socket.isConnected,
    socketConnectionError: (state) => state.socket.connectionError,
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      startWebSocketPing();
    },
    SOCKET_ONCLOSE(state) {
      stopWebSocketPing();
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state) {
      stopWebSocketPing();
      state.socket.connectionError = true;
    },
    // eslint-disable-next-line no-unused-vars
    SOCKET_ONMESSAGE(state, data) {
      // messages from server contains an action property
      // which is used to call an action here in VUE.
    },
    SOCKET_RECONNECT(state, count) {
      state.socket.reconnectCount = count;
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    SOCKET_RESET_CONNECTION_ERROR(state) {
      state.socket.connectionError = false;
    },
  },
  actions: {
    connectToWebSocket({ commit }) {
      commit('SOCKET_RESET_CONNECTION_ERROR');
      Vue.prototype.$connect();
    },
    sendSocketData(placeholder, data) {
      Vue.prototype.$socket.send(JSON.stringify(data));
    },
    identifyClient({ getters }) {
      const clientData = {
        scope: getters.appScope,
      };
      Vue.prototype.$socket.send(JSON.stringify({
        action: 'identifyClient',
        data: { client: clientData },
      }));
    },
    renewApiQueuePosition({ commit }, data) {
      const {
        queuePosition: position,
        estimatedWait,
      } = data;
      commit('UPDATE_API_QUEUE_POSITION', { position, estimatedWait });
    },
    renewContentVersion({ dispatch }, data) {
      const { contentHash: contentVersion } = data;
      dispatch('setContentVersion', { contentVersion });
    },
    renewAnnouncement({ dispatch }, data) {
      const { message = '' } = data.announcement;
      dispatch('setAnnouncement', { message });
    },
    async initWebSocket({ getters, dispatch }) {
      if (!getters.hasWebSocketUrl) return;

      try {
        Vue.use(VueNativeSock, getters.webSocketUrl, {
          store: this.$store,
          format: 'json',
          connectManually: true,
          reconnection: true,
          reconnectionDelay: 60000,
        });
        await dispatch('connectToWebSocket');
      } catch (error) {
        consoleLog(error);
      }
    },
  },
};

export default WebSocketStore;
