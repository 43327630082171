function initialState() {
  return {
    password: '',
    confirmPassword: '',
  };
}

const UserCreationStore = {
  state: initialState(),
  getters: {
    userCreation: (state) => state,
  },
  mutations: {
    UPDATE_USER_CREATION_INPUT_BY_KEY(state, { key = '', value = '' }) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = value;
      }
    },
    RESET_USER_CREATION(state) {
      const s = initialState();
      Object.keys(s)
        .forEach((key) => {
          this._vm.$set(state, key, s[key]);
        });
    },
  },
  actions: {
    async setUserCreationInputAndValidate({ commit, dispatch }, { key = '', value = '' }) {
      commit('UPDATE_USER_CREATION_INPUT_BY_KEY', { key, value });
      dispatch('validate', { key, value });
    },
    async validateUserCreation({ getters, dispatch }, setError = true) {
      return dispatch('validateMultiple', {
        entries: {
          password: getters.userCreation.password,
          confirmPassword: getters.userCreation.confirmPassword,
        },
        setError,
      });
    },
  },
};

export default UserCreationStore;
