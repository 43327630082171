import httpInternal from '@/http/httpInternal';

const AdministrationStore = {
  state: {
    magentoDealers: [],
  },
  getters: {
    magentoDealers(state) {
      return state.magentoDealers;
    },
  },
  mutations: {
    updateMagentoDealers(state, magentoDealers = []) {
      state.magentoDealers = magentoDealers;
    },
  },
  actions: {
    getOrders({ getters }) {
      return httpInternal(
        {
          method: 'get',
          url: getters.apiGetOrdersUrl,
          withCredentials: true,
        },
      );
    },
    async getMagentoDealers({ getters, commit, dispatch }) {
      try {
        const response = await httpInternal(
          {
            method: 'post',
            url: getters.apiGetMagentoDealersUrl,
            withCredentials: true,
          },
        );
        commit('updateMagentoDealers', response.data.dealers);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          dispatch('setAlert', { key: 'createDealerError', message: 'error.userNotInCorrectCustomerGroup' });
        }
      }
    },
    async createMagentoDealer({ getters, dispatch }, payload) {
      try {
        return await httpInternal(
          {
            method: 'post',
            url: getters.apiCreateMagentoDealerUrl,
            data: {
              email: payload.email,
              firstName: payload.firstName,
              lastName: payload.lastName,
              password: payload.password,
              salesmanNumber: payload.salesmanNumber,
              dealerNumber: payload.dealerNumber,
            },
            withCredentials: true,
          },
        );
      } catch (error) {
        if (error.response && error.response.status === 403) {
          dispatch('setAlert', { key: 'createDealerError', message: 'error.userNotInCorrectCustomerGroup' });
        }
        throw error;
      }
    },
    async updateMagentoDealer({ getters, dispatch }, payload) {
      try {
        return await httpInternal(
          {
            method: 'post',
            url: getters.apiUpdateMagentoDealerUrl,
            data: {
              id: payload.id,
              email: payload.email,
              firstName: payload.firstName,
              lastName: payload.lastName,
              password: payload.password,
              salesmanNumber: payload.salesmanNumber,
              dealerNumber: payload.dealerNumber,
            },
            withCredentials: true,
          },
        );
      } catch (error) {
        if (error.response && error.response.status === 403) {
          dispatch('setAlert', { key: 'createDealerError', message: 'error.userNotInCorrectCustomerGroup' });
        }
        throw error;
      }
    },
  },
};

export default AdministrationStore;
