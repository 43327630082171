<template>
  <div class="model-standard-equipment">
    <div
      v-if="$slots.link"
      class="modal-link"
      @click="showModal = true"
    >
      <slot name="link" />
    </div>
    <UiModal
      class="model-standard-equipment-modal"
      :is-open="showModal"
      :mobile-fullscreen="true"
      @close="showModal = false"
    >
      <template #header>
        <div
          v-if="$slots.title"
          class="modal-title"
        >
          <slot name="title" />
        </div>

        <div
          v-if="$slots.subtitle"
          class="modal-subtitle"
        >
          <slot name="subtitle" />
        </div>
      </template>

      <template #body>
        <div class="standard-equipment">
          <div class="equipment-list">
            <slot name="body" />
          </div>
        </div>
      </template>
    </UiModal>
  </div>
</template>

<script>
import UiModal from '@/components/ui/uiModal/UiModal.vue';

export default {
  name: 'ModelStandardEquipment',
  components: {
    UiModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" src="./modelStandardEquipment.scss"></style>
