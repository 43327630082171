/* eslint-disable */
import Swiper from 'swiper';

function featureTabsScripts() {
  'use strict';

  let featureTabCarousels = {
    init: function () {
      this.setupFeatureTabs();
    },
    setupFeatureTabs: function () {
      let swiperContainers = $('.feature-carousel-tabs .feature-carousel-container');

      swiperContainers.each(function(index){

        let that = $(this);

        if (!that[0].swiper) {
          that.addClass('swiper-'+index);
          let container =  that.closest('.feature-carousel-tabs');
          let tabs = container.find('.feature-tab');
          let tabsOffset = []

          tabs.each(function() {
            tabsOffset.push($(this).offset().left);
          });

          let featureSwiper = new Swiper('.feature-carousel-tabs .feature-carousel-container.swiper-'+index, {
            slidesPerView: 1,
            preloadImages: false,
            lazy: true,
            autoHeight: true
          });

          let updateTabs = function(index) {
            let currentTab = container.find($('[data-tab="'+index+'"]'));
            tabs.removeClass('active-tab');
            currentTab.addClass('active-tab');
          };

          let scrollTabs = function(index) {
            container.find('.feature-tab-list').animate({
              scrollLeft: tabsOffset[index]
            }, 500);
          };

          let changeSlide = function(index) {
            featureSwiper.slideTo(index, 500);
          };

          tabs.click(function() {
            let index = $(this).data('tab');
            changeSlide(index);
          });

          featureSwiper.on('slideChange', function() {
            updateTabs(this.realIndex);
            scrollTabs(this.realIndex);
          });
        }

      });

    }
  };

  featureTabCarousels.init();
}

export {featureTabsScripts};
