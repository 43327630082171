/* eslint-disable */
import { mediaCheck } from '@/assets/js/vendor/matchMedia.js';

function responsiveBg() {
  'use strict';

  let responsiveBackgroundImage = {
    init: function(){
      let self = this;
      let rbgs = $('[data-responsive-background-image]');
      rbgs.each(function(){
        self.create($(this));
      })
    },
    create: function(elem) {
      let self = this;
      elem.loaded = false;
      elem.container = elem.parent();
      elem.sources = elem.find('[data-media-query]');
      elem.loader = elem.container.find('.loader');
      elem.loadingImg = elem.find('img');

      // Remove loader when opacity transition is complete
      if (elem.loader.length > 0){
        elem.loader.one("transitionend", function(e){
          elem.loader.remove();
        });
      }

      // Use image to detect first load time and remove after
      if (elem.loadingImg.length > 0){
        elem.loadingImg.on('load', function() {
          self.update(elem, elem.loadingImg.attr('src'));
          elem.loadingImg.remove();
        });
      }
      self.setMedia(elem);
    },
    setMedia: function(elem){
      let self = this;
      // Set up media checks for each available source
      elem.sources.each(function(){
        let query = $(this).attr('data-media-query');
        let src = $(this).attr('data-default-src');

        mediaCheck({
          media: query,
          entry: function () {
            // Load image in img tag first time in order to fade in when loaded
            if (!elem.loaded && elem.loadingImg.length > 0){
              elem.loadingImg.attr('src', src);
            }else{
              self.update(elem, src);
            }
          }
        });
      });
    },
    update: function(elem, source){
      elem.css('background-image', 'url("' + source + '")');
      if (!elem.loaded){
        elem.parent().addClass('loaded');
        elem.loaded = true;
      }
    }

  };

  responsiveBackgroundImage.init();
}

export { responsiveBg };
