<template>
  <footer
    id="app-footer"
    ref="appFooter"
    class="page-footer"
    style="opacity: 0;"
  >
    <slot name="before-footer" />
    <div class="footer">
      <LayoutBaseContainer class="footer-cms">
        <CmsSnippet
          snippet-id="footer"
          :animate="true"
          @snippetLoaded="onSnippetLoaded"
        />
      </LayoutBaseContainer>
    </div>
  </footer>
</template>

<script>
import Velocity from 'velocity-animate';
import LayoutBaseContainer from '@/components/layout/LayoutBaseContainer.vue';
import CmsSnippet from '@/components/cms/CmsSnippet.vue';

export default {
  name: 'AppFooter',
  components: {
    LayoutBaseContainer,
    CmsSnippet,
  },
  methods: {
    onSnippetLoaded() {
      const { appFooter } = this.$refs;
      if (!appFooter) return;

      Velocity(
        appFooter,
        { opacity: 1 },
        {
          duration: 300,
          complete() {
            appFooter.removeAttribute('style');
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" src="./appFooter.scss"></style>
<style lang="scss" theme="audi" src="./appFooter.audi.scss"></style>
<style lang="scss" theme="seat" src="./appFooter.seat.scss"></style>
<style lang="scss" theme="cupra" src="./appFooter.cupra.scss"></style>
<style lang="scss" theme="skoda" src="./appFooter.skoda.scss"></style>
<style lang="scss" theme="vw" src="./appFooter.vw.scss"></style>
<style lang="scss" theme="catalog" src="./appFooter.catalog.scss"></style>
