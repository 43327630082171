import httpInternal from '@/http/httpInternal';
import { MODULES } from '@/constants/constants-modules';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';
import { ROUTES } from '@/constants/constants-routes';
import consoleLog from '@/assets/js/helpers/console-log';

const createStockCarModelId = (stockCarRegistrationNumber = '') => {
  const stockCarModelPrefix = 'stock_model_';
  return `${stockCarModelPrefix}${stockCarRegistrationNumber}`;
};

const StockCarsStore = {
  state: {
    stockCars: [],
    stockCarsDataTimestamp: null,
  },
  getters: {
    getStockCars: (state) => state.stockCars || [],

    getStockCarIncludedOptions: (state, getters) => (
      stockCarRegistrationNumber = getters.getModelSelectedMarketModelId(),
    ) => getters.getMarketModelOptions({ marketModelId: stockCarRegistrationNumber })
      .filter((option) => option.includedInStockCar),

    getStockCarPrice: (state, getters) => ({
      stockCarRegistrationNumber = getters.getModelSelectedMarketModelId(),
      ignoreDiscount = false,
    }) => {
      const includedSelectionIds = getters.getStockCarIncludedOptions(stockCarRegistrationNumber).map((option) => option.id);

      return getters.getConfigurationTotalCostPerFinancingOption({
        includedSelectionIds,
        useDefaultFinancingSettings: false,
        ignoreDiscount,
        ignoreElectricVat: true,
      });
    },

    getStockCarsOnlineSalesDisabledInformation: (state, getters) => {
      if (!getters.isStockCarsOnlineSalesDisabled) return '';

      return getters.appConfig?.stockCars?.onlineSales?.disabledInformation;
    },

    isStockCarsInitiated: (state) => !!state.stockCarsDataTimestamp,

    isCustomerStockCarsDisabled: (state, getters) => !!getters.appConfig?.stockCars?.disable?.customer,

    isDealerStockCarsDisabled: (state, getters) => !!getters.appConfig?.stockCars?.disable?.dealer,

    isStockCarsOnlineSalesDisabled: (state, getters) => !!getters.appConfig?.stockCars?.onlineSales?.disabled,

    isDealerStockCars: (state, getters, rootState) => {
      const { name } = rootState.route;
      const dealerRoutes = [
        ROUTES.dealerStockCars,
        ROUTES.dealerStockCarsConfigurator,
      ];

      return dealerRoutes.includes(name);
    },
  },
  mutations: {
    UPDATE_STOCK_CARS(state, stockCars = []) {
      state.stockCars = stockCars;
    },
    UPDATE_STOCK_CARS_DATA_TIMESTAMP(state, dataTimestamp = Date.now()) {
      state.stockCarsDataTimestamp = dataTimestamp;
    },
  },
  actions: {
    async fetchAndSetStockCars({
      state, getters, commit, dispatch,
    }, {
      manageLoader = false,
      updateActiveFilters = false,
      forceUpdate = false,
    } = {}) {
      const timeDiff = Date.now() - state.stockCarsDataTimestamp;
      if (timeDiff < 1000 && !forceUpdate) return; // @todo Check timeDiff less than 300000 or something

      if (manageLoader) dispatch('setLoader', true);

      if (!getters.isStockCarsInitiated) {
        if (!await dispatch('checkModule', { module: MODULES.configurator, redirect: true })) return;
      }

      let stockCarsResponse = null;

      const stockCarsRequest = async () => {
        try {
          // @todo Handle Filter Query
          const filters = getters.getStockCarsActiveFilterValues;
          const {
            filterKey: orderBy = '',
            direction = '',
          } = getters.getStockCarsActiveSortByData ?? {};

          const params = {
            filters,
            sortOrder: {
              orderBy,
              direction,
            },
            // @todo Add limit, offset
          };

          stockCarsResponse = await httpInternal({
            method: 'get',
            url: getters.apiGetStockCarsUrl,
            params,
          });
        } catch (error) {
          commit('UPDATE_STOCK_CARS');
          dispatch('setAlert', {
            key: 'getStockCarsError',
            message: 'error.stockCars.failedToFetch',
            log: error.response,
          });
          throw error;
        }
      };

      await stockCarsRequest();

      const { data: stockCarsData } = stockCarsResponse || {};

      const {
        hits: stockCars,
        filter: stockCarsFilterData,
        sortBy: sortByData,
        totalAvailable,
        totalAvailableFiltered,
      } = stockCarsData || {};

      commit('UPDATE_STOCK_CARS', stockCars);
      // @todo API Should return total available cars... This will give incorrect numbers if pagination is used
      commit('UPDATE_STOCK_CARS_TOTAL_AVAILABLE', totalAvailable);
      commit('UPDATE_STOCK_CARS_TOTAL_AVAILABLE_FILTERED', totalAvailableFiltered);
      commit('UPDATE_STOCK_CARS_SORT_BY_DATA', sortByData);

      await dispatch('initStockCarsFilter', { filterData: stockCarsFilterData });

      commit('UPDATE_STOCK_CARS_DATA_TIMESTAMP', Date.now());

      if (updateActiveFilters) console.log('@todo fetchAndSetStockCars UPDATE ACTIVE FILTERS');
      if (manageLoader) dispatch('setLoader', false);
    },

    async initStockCarsConfiguratorData({ getters, dispatch }, {
      stockCarRegistrationNumber = '',
    } = {}) {
      try {
        await dispatch('fetchAndSetStockCarByRegistrationNumber', { stockCarRegistrationNumber });
        await dispatch('fetchAndSetStockCarOptionsByRegistrationNumber', { stockCarRegistrationNumber });
        await dispatch('initFinancingSettings', {
          financingStateKey: stockCarRegistrationNumber,
          financingConfig: getters.getMarketModelFinancingConfig(stockCarRegistrationNumber),
          force: false,
        });
        await dispatch('initMileageYearPriceStateSettings', stockCarRegistrationNumber);
        await dispatch('initWltpState', { marketModelId: stockCarRegistrationNumber, skipWltpData: true });

        await dispatch('setModelSelectedMarketModel', {
          marketModelId: stockCarRegistrationNumber,
          updateConfiguratorKey: false,
        });
      } catch (error) {
        dispatch('setAlert', {
          key: 'initStockCarsConfiguratorDataError',
          message: 'error.stockCars.initConfigurator',
          log: error,
        });
        await dispatch('resetModelConfiguratorStepsAfterCurrent', { modelId: createStockCarModelId(stockCarRegistrationNumber) });
        const router = await getClientRouter();
        router.push({ name: ROUTES.stockCars });
      }
    },

    async fetchAndSetStockCarByRegistrationNumber({ getters, commit, dispatch }, {
      stockCarRegistrationNumber = '',
    } = {}) {
      // @todo Set fetchTime and check if there's a need to update

      let stockCarResponse;

      try {
        stockCarResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetStockCarByIdUrl(stockCarRegistrationNumber),
        });
      } catch (error) {
        consoleLog(error);
        throw error;
      }

      const { stockCar = {}, stockModel = {} } = stockCarResponse?.data ?? {};

      if (!stockCar.id || !stockModel.id) throw new Error('error.noStockCarFound');

      await dispatch('initMarketModelStates', { marketModelData: stockCar });

      commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_SOURCES', {
        marketModelId: stockCarRegistrationNumber,
        images: stockCar.images,
      });

      await dispatch('setStockCarModelData', { stockModel });
    },

    async fetchAndSetStockCarOptionsByRegistrationNumber({ getters, commit, dispatch }, {
      stockCarRegistrationNumber = '',
    } = {}) {
      try {
        const { data: stockCarOptions } = await httpInternal({
          method: 'get',
          url: getters.apiGetStockCarOptionsUrl(stockCarRegistrationNumber),
        });

        const {
          categories = {},
          groups = {},
          items = {},
        } = stockCarOptions;

        commit('UPDATE_MARKET_MODEL_DATA_CATEGORIES', { marketModelId: stockCarRegistrationNumber, categories });
        commit('UPDATE_MARKET_MODEL_DATA_GROUPS', { marketModelId: stockCarRegistrationNumber, groups });
        commit('UPDATE_MARKET_MODEL_DATA_OPTIONS', { marketModelId: stockCarRegistrationNumber, marketModelOptions: items });
      } catch (error) {
        consoleLog(error);
        throw error;
      }

      await dispatch('preselectMarketModelStandardOptions', { marketModelId: stockCarRegistrationNumber });
    },

    async setStockCarModelData({ commit, dispatch }, {
      stockModel = {},
    } = {}) {
      commit('ADD_MODEL', stockModel);
      await dispatch('setConfigActiveModelId', { modelId: stockModel.id });
    },
  },
};

export default StockCarsStore;
