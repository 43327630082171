import axios from 'axios';
import isObject from 'lodash/isObject';

const axiosParams = {
  version: process.env.version,
};

if (process.env.NODE_ENV === 'development') {
  axiosParams.XDEBUG_SESSION_START = 'PHPSTORM';
}

// @todo Dirty fix for httpInternal loading before checkAndSetPreviewAuthorizationKey is finished
let pak;
const searchParams = new URLSearchParams(window.location.search);
pak = searchParams.get('pak');
// @todo Should import store properly
if (!pak) {
  pak = JSON.parse(window.sessionStorage.getItem('storageConfig'))?.Config?.appPreviewAuthorizationKey;
}

if (pak) {
  axiosParams.pak = pak;
}

const httpInternal = axios.create({
  params: axiosParams,
  withCredentials: true,
  crossDomain: true,

  paramsSerializer(params) {
    return Object.keys(params).reduce((output, key) => {
      const value = params[key];

      if (value === null) return output;

      const valueString = isObject(value) ? JSON.stringify(value) : value;
      output.append(key, valueString);
      return output;
    }, new URLSearchParams());
  },
  headers: {
    'Content-Type': 'application/json',
  },
});

if (pak) {
  httpInternal.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.params = { ...config.params, cacheBuster: Date.now() };
      return config;
    },
  );
}

export default httpInternal;
