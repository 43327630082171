export const ICONS = {
  arrowBack: 'arrow-back',
  arrowForward: 'arrow-forward',
  cancel: 'cancel',
  car: 'car',
  carDocument: 'car-document',
  cardPayment: 'card-payment',
  carInfo: 'car-info',
  cart: 'cart',
  chain: 'chain',
  chevronBack: 'chevron-back',
  chevronDown: 'chevron-down',
  chevronForward: 'chevron-forward',
  chevronUp: 'chevron-up',
  cog: 'cog',
  discount: 'discount',
  documentPdf: 'document-pdf',
  download: 'download',
  edit: 'edit',
  error: 'error',
  filter: 'filter',
  fullscreenClose: 'fullscreen-close',
  fullscreenOpen: 'fullscreen-open',
  happy: 'happy',
  help: 'help',
  image: 'image',
  info: 'info',
  link: 'link',
  lock: 'lock',
  missingImage: 'missing-image',
  preview: 'preview',
  receipt: 'receipt',
  sad: 'sad',
  sort: 'sort',
  spinExterior: 'spin-exterior',
  spinInterior: 'spin-interior',
  userCompany: 'user-company',
  userConsumer: 'user-consumer',
  plus: 'plus',
  minus: 'minus',
  warning: 'warning',
  wltpCo2: 'wltp-co2',
  wltpConsumption: 'wltp-consumption',
};
