<template>
  <div
    class="ui-scroll-top-bar"
    :data-show="shouldBeVisible"
    @click="scrollToTop()"
  >
    <TransitionHeight :is-toggle="true">
      <div
        v-if="shouldBeVisible"
        class="scroll-top-inner"
      >
        <div class="scroll-top">
          {{ $t(label) }}
        </div>
      </div>
    </TransitionHeight>
  </div>
</template>

<script>
import { scrollToTop } from '@/assets/js/helpers/general-helpers';
import TransitionHeight from '@/components/transitions/TransitionHeight.vue';

export default {
  name: 'UiScrollTopBar',
  components: {
    TransitionHeight,
  },
  props: {
    label: {
      type: String,
      default: 'general.scrollTop',
    },
  },
  data() {
    return {
      scrollToTop,
      shouldBeVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.shouldBeVisible = document.documentElement.scrollTop > 200;
    },
  },
};
</script>

<style lang="scss" src="./uiScrollTopBar.scss"></style>
<style lang="scss" theme="audi" src="./uiScrollTopBar.audi.scss"></style>
<style lang="scss" theme="seat" src="./uiScrollTopBar.seat.scss"></style>
<style lang="scss" theme="cupra" src="./uiScrollTopBar.cupra.scss"></style>
<style lang="scss" theme="carbuy" src="./uiScrollTopBar.carbuy.scss"></style>
<style lang="scss" theme="catalog" src="./uiScrollTopBar.catalog.scss"></style>
