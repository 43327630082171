import { register } from 'register-service-worker';
import consoleLog from '@/assets/js/helpers/console-log';

let refreshing = false;
const MAX_TRIES = 7;
const DELAY_INCREMENT = 200;
let tryControllerChangeTimeout;
let tryInstallNewTimeout;
let reloadTimeout;
let initialCheckTimeout;

const completeInitialCheck = (store) => {
  store.commit('UPDATE_SERVICE_WORKER', { key: 'initialCheckDone', value: true });
};

const setNewContentAvailable = (store) => {
  store.commit('UPDATE_SERVICE_WORKER', { key: 'newContentAvailable', value: true });
};

const controllerChange = () => {
  if (refreshing) return;
  refreshing = true;
  consoleLog('SW: controllerChange triggered');

  if (tryControllerChangeTimeout) clearTimeout(tryControllerChangeTimeout);
  if (reloadTimeout) clearTimeout(reloadTimeout);

  setTimeout(() => {
    window.location.reload();
  }, 0);
};

const tryControllerChange = (store, registration, tries = 0) => {
  if (refreshing) return;

  consoleLog(`SW: Trying to switch controller. Tries: ${tries}`);

  const { installing, waiting } = registration || {};

  if (waiting?.state === 'installed') {
    controllerChange();
    return;
  }

  if (tries > MAX_TRIES || (!installing && !waiting)) {
    completeInitialCheck(store);
    return;
  }

  if (installing && tries <= MAX_TRIES) {
    tryControllerChangeTimeout = setTimeout(() => {
      const tryCount = tries + 1;
      tryControllerChange(store, registration, tryCount);
    }, tries * DELAY_INCREMENT);
  }
};

const tryInstallNew = (store, registration, tries = 0) => {
  consoleLog(`SW: New content is available; installing. Tries: ${tries}`);

  if (registration.waiting?.state !== 'installed') {
    tryInstallNewTimeout = setTimeout(() => {
      const tryCount = tries + 1;
      tryInstallNew(store, registration, tryCount);
    }, tries * DELAY_INCREMENT);
    return;
  }

  if (tryInstallNewTimeout) clearTimeout(tryInstallNewTimeout);

  if (!store.getters.serviceWorker.initialCheckDone) {
    store.dispatch('installNewServiceWorker').then();
  } else {
    setNewContentAvailable(store);
  }
};

const setInitialCheckLimit = (store) => {
  if (initialCheckTimeout) clearTimeout(initialCheckTimeout);

  if (!store.getters.serviceWorker.initialCheckDone) {
    initialCheckTimeout = setTimeout(() => {
      if (tryControllerChangeTimeout) clearTimeout(tryControllerChangeTimeout);
      if (tryInstallNewTimeout) clearTimeout(tryInstallNewTimeout);
      if (reloadTimeout) clearTimeout(reloadTimeout);

      completeInitialCheck(store);
    }, 4000);
  }
};

const addControllerchangeListener = () => {
  navigator.serviceWorker.removeEventListener('controllerchange', controllerChange);
  navigator.serviceWorker.addEventListener('controllerchange', controllerChange);
};

const addMessageListener = (store, registration) => {
  const swMessageHandler = (event) => {
    if (event.data?.action === 'skipWaitingExecuted') {
      consoleLog('SW: Got skipWaitingExecuted message from worker');
      tryControllerChange(store, registration, 0);
    }
  };

  navigator.serviceWorker.removeEventListener('message', swMessageHandler);
  navigator.serviceWorker.addEventListener('message', swMessageHandler);
};

const checkForUpdate = (store, registration, initialCheck = false) => {
  if ('serviceWorker' in store.getters && store.getters.serviceWorker.online) {
    const initialCheckMessage = initialCheck ? ` Initial: ${initialCheck}` : '';
    consoleLog(`SW: Check for updates. ${initialCheckMessage}`);

    setInitialCheckLimit(store);

    registration.update()
      .then((swRegistration) => {
        const { installing, waiting } = swRegistration || {};

        if (refreshing) return;
        if (initialCheck && (!installing && !waiting)) {
          completeInitialCheck(store);
          consoleLog('SW: Initial check done');
        }
      })
      .catch((error) => {
        completeInitialCheck(store);
        consoleLog(`SW: Update error: ${error}`);
      });
  }
};

export default function registerServiceWorker(store /* , router */) {
  const assertServiceWorkerApi = 'serviceWorker' in navigator;

  if (!assertServiceWorkerApi || process.env.NODE_ENV !== 'production') return;

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      consoleLog('SW: App is being served from cache by a service worker.');
    },
    registered(registration) {
      consoleLog('SW: Service worker has been registered.');

      addControllerchangeListener();
      addMessageListener(store, registration);

      checkForUpdate(store, registration, true);

      setInterval(() => {
        checkForUpdate(store, registration);
      }, 60000);
    },
    cached() {
      consoleLog('SW: Content has been cached for offline use.');
    },
    updatefound() {
      consoleLog('SW: New content found; downloading.');
    },
    updated(registration) {
      consoleLog('SW: Service worker is updated.');
      tryInstallNew(store, registration);
    },
    offline() {
      consoleLog('SW: No internet connection found. App is running in offline mode.');
    },
    error(error) {
      consoleLog(`SW: Error during service worker registration: ${error}`);
    },
  });
}
