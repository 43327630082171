function initialState() {
  return {
    orgNumber: '',
    companyName: '',
    companyAddress: '',
    companyZipCode: '',
    companyCity: '',
  };
}

const UserCompanyStore = {
  state: initialState(),
  getters: {
    companyInfo: (state) => state,
  },
  mutations: {
    UPDATE_COMPANY_INFO_BY_KEY(state, { key = '', value = '' }) {
      state[key] = value;
    },
    RESET_COMPANY_INFO(state) {
      const s = initialState();
      Object.keys(s)
        .forEach((key) => {
          state[key] = s[key];
        });
    },
  },
  actions: {
    async setCompanyInputAndValidate({ state, commit, dispatch }, { key = '', value = '' }) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        commit('UPDATE_COMPANY_INFO_BY_KEY', { key, value });
        if (key === 'orgNumber') await dispatch('setCompanyInfoByOrgNumber');
        dispatch('validate', { key, value });
      }
    },
  },
};

export default UserCompanyStore;
