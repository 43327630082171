import VueTypes from 'vue-types';
import { isNullable } from '@/assets/js/helpers/cms-helpers';

const WLTP_VALUES_ITEM = {
  consumption: [],
  energyClass: '',
  wltp: [],
};

export const WLTP_ENERGY_CLASSES = [
  'A+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

export const WLTP_VALUES_PROP = VueTypes.shape({
  consumption: isNullable(Array).def([]),
  energyClass: isNullable(String).def(''),
  wltp: isNullable(Array).def([]),
}).loose;

export const createWltpValuesItem = (wltpValues = WLTP_VALUES_ITEM) => ({
  ...WLTP_VALUES_ITEM,
  ...wltpValues,
});
