<template>
  <div class="ui-modal">
    <simple-portal selector="#modal-portal">
      <transition
        @enter="onEnter"
        @leave="onLeave"
      >
        <div
          v-if="isOpen"
          :key="modalId"
          :class="[classes]"
        >
          <div
            ref="modalMask"
            class="modal-mask"
            :data-fullscreen="isFullscreen"
            v-on="maskHandlers"
          >
            <div
              ref="modalWrapper"
              class="modal-wrapper"
            >
              <div class="modal-container">
                <div class="modal-close-wrapper">
                  <UiIcon
                    v-if="!disableClose"
                    class="modal-close"
                    :icon="ICONS.cancel"
                    :use-background="true"
                    :click-function="emitClose"
                  />
                </div>
                <div
                  class="modal-content"
                  data-scroll-lock-scrollable
                >
                  <div
                    v-if="hasImageContent"
                    class="modal-image"
                    :data-no-padding="noImagePadding"
                  >
                    <slot name="image" />
                  </div>

                  <div
                    v-if="hasHeaderContent"
                    class="modal-header"
                  >
                    <slot name="header" />
                  </div>

                  <div
                    v-if="hasBodyContent"
                    class="modal-body"
                  >
                    <slot name="body" />
                    <div class="body-gradiant" />
                  </div>

                  <div
                    v-if="hasFooterContent"
                    class="modal-footer"
                  >
                    <slot name="footer" />
                  </div>
                </div>

                <StatusLoader
                  v-if="useStatusLoader"
                  position="absolute"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </simple-portal>
  </div>
</template>

<script>
import Velocity from 'velocity-animate';
import { mapGetters } from 'vuex';
import { Portal as SimplePortal } from '@linusborg/vue-simple-portal';
import { bodyScroll } from '@/assets/js/helpers/general-helpers';
import { getModalCount, increaseModalCount, decreaseModalCount } from '@/assets/js/helpers/modal-helpers';
import { ICONS } from '@/constants/constants-icons';
import { DEVICES } from '@/constants/constants-devices';
import StatusLoader from '@/components/status/StatusLoader.vue';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';

const EVENTS = {
  close: 'close',
  opening: 'opening',
  isOpen: 'is-open',
  isClosed: 'is-closed',
};

export default {
  name: 'UiModal',
  components: {
    SimplePortal,
    StatusLoader,
    UiIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    mobileFullscreen: {
      type: Boolean,
      default: false,
    },
    tabletFullscreen: {
      type: Boolean,
      default: false,
    },
    imageOverride: {
      type: Boolean,
      default: false,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    useStatusLoader: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    ...Object.values(EVENTS),
  ],
  data() {
    return {
      classes: '',
      modalId: `modal${this._uid}`,
      maskHandlers: {
        mousedown: this.closeModal,
        touchstart: this.closeModal,
      },
      transitionDuration: 300,
      ICONS,
    };
  },
  computed: {
    ...mapGetters([
      'currentDevice',
    ]),
    hasImageContent() {
      return this.$slots.image || this.imageOverride;
    },
    hasHeaderContent() {
      return this.$slots.header;
    },
    hasBodyContent() {
      return this.$slots.body;
    },
    hasFooterContent() {
      return this.$slots.footer;
    },
    noImagePadding() {
      return !this.hasHeaderContent && !this.hasBodyContent && !this.hasFooterContent;
    },
    isFullscreen() {
      const tabletFullscreenDevices = [DEVICES.tablet, DEVICES.mobile];
      const mobileFullscreenDevices = [DEVICES.mobile];

      const tabletFullscreen = this.tabletFullscreen && tabletFullscreenDevices.includes(this.currentDevice);
      const mobileFullscreen = this.mobileFullscreen && mobileFullscreenDevices.includes(this.currentDevice);

      return tabletFullscreen || mobileFullscreen;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        // Add or remove navigation guard
        if (isOpen) {
          this.$router.beforeEach(this.modalGuard);
        } else {
          this.removeHook(this.modalId);
        }
      },
    },
  },
  mounted() {
    this.modalGuard.stopNavigation = true;
    this.modalGuard.modalId = this.modalId;
    this.modalGuard.modalClose = true;
    this.modalGuard.closeModalFunction = () => this.emitClose();

    this.classes = this.$el.className;
  },
  beforeDestroy() {
    this.removeHook(this.modalId);

    if (this.isOpen) {
      decreaseModalCount();
      if (getModalCount() === 0) bodyScroll.enable();
    }
  },
  methods: {
    closeModal(e) {
      if (!this.disableClose) {
        const { modalWrapper } = this.$refs;
        if (!modalWrapper) return;

        if (!modalWrapper !== e.target && !modalWrapper.contains(e.target)) {
          this.emitClose();
        }
      }
    },
    modalGuard(to, from, next) {
      if (this.$router.beforeHooks.findIndex((x) => x.preventModalClose) === -1) {
        setTimeout(() => {
          this.emitClose();
        }, 0);
        this.removeHook(this.modalId);
      }

      if (this.$router.beforeHooks.findIndex((x) => x.modalClose) !== -1) {
        next();
      } else {
        next(false);
      }
    },
    removeHook(modalId) {
      const { beforeHooks } = this.$router;
      const hookIndex = beforeHooks.findIndex((x) => x.modalId === modalId);
      if (hookIndex !== -1) {
        beforeHooks.splice(hookIndex, 1);
      }
    },
    emitClose() {
      this.$emit(EVENTS.close);
    },
    onEnter(el, done) {
      const { modalMask, modalWrapper } = this.$refs;
      if (!modalMask || !modalWrapper) return;

      this.$emit(EVENTS.opening);

      Velocity(modalMask, 'stop');
      Velocity(modalWrapper, 'stop');

      if (getModalCount() === 0) bodyScroll.disable();
      increaseModalCount();

      Velocity(
        modalMask,
        { opacity: 1 },
        {
          duration: this.transitionDuration,
          easing: 'ease',
        },
      );

      Velocity(
        modalWrapper,
        { top: 0 },
        {
          duration: this.transitionDuration,
          easing: 'ease',
          complete: () => {
            this.$emit(EVENTS.isOpen);
            done();
          },
        },
      );
    },
    onLeave(el, done) {
      const { modalMask, modalWrapper } = this.$refs;
      if (!modalMask || !modalWrapper) return;

      Velocity(modalMask, 'stop');
      Velocity(modalWrapper, 'stop');

      Velocity(
        modalMask,
        { opacity: 0 },
        {
          duration: this.transitionDuration,
          easing: 'ease',
        },
      );

      Velocity(
        modalWrapper,
        { top: 200 },
        {
          duration: this.transitionDuration * 1.5,
          easing: 'ease',
          complete: () => {
            decreaseModalCount();
            if (getModalCount() === 0) bodyScroll.enable();
            this.$emit(EVENTS.isClosed);
            done();
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" src="./uiModal.scss" />
