<template>
  <TransitionFade>
    <div
      v-if="showLoader"
      class="status-loader"
      :data-position="position"
      :data-transparent="transparent"
    >
      <div
        v-if="!transparent"
        class="page-loader-mask"
      />
      <div class="loader-container">
        <div class="lds-ring">
          <div /><div /><div /><div />
        </div>
        <div
          v-if="loadingText"
          class="loading-text"
        >
          {{ loadingText }}
        </div>
      </div>
    </div>
  </TransitionFade>
</template>

<script>
import { mapGetters } from 'vuex';
import VueTypes from 'vue-types';
import TransitionFade from '@/components/transitions/TransitionFade.vue';

export default {
  name: 'StatusLoader',
  components: {
    TransitionFade,
  },
  props: {
    position: VueTypes.oneOf(['fixed', 'absolute'])
      .def('fixed'),
    isLoading: {
      type: Boolean,
      default: undefined,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters([
      'loading',
      'loadingMessage',
    ]),
    showLoader() {
      return this.isLoading !== undefined ? this.isLoading : this.loading;
    },
    loadingText() {
      return this.message !== undefined ? this.message : this.loadingMessage;
    },
  },
};
</script>

<style lang="scss" src="./statusLoader.scss" />
<style lang="scss" theme="audi" src="./statusLoader.audi.scss" />
<style lang="scss" theme="skoda" src="./statusLoader.skoda.scss" />
<style lang="scss" theme="carbuy" src="./statusLoader.carbuy.scss" />
