// Usage: sessionStorage.setItem('debug', true)
const consoleLog = (message, data) => {
  const showConsoleLog = sessionStorage.getItem('debug') || process.env.NODE_ENV === 'development';

  if (showConsoleLog && message !== undefined) {
    // eslint-disable-next-line no-console
    console.log(message, data);
  }
};

export default consoleLog;
