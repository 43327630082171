export const STOCK_CARS_FILTER_TYPES = {
  facet: 'facet',
  swatch: 'swatch',
};

export const STOCK_CARS_FILTER_TOP_LEVEL_TYPES = {
  section: 'section',
  category: 'category',
};

export const STOCK_CARS_ORDER_STATUSES = {
  available: 'Available',
  pending: 'Pending',
  sold: 'Sold',
};

export const STOCK_CARS_ORDER_ERROR_REASONS = {
  pending: 'pending',
  purchased: 'purchased',
};
