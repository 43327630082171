/* eslint-disable */
import { mediaCheck } from '@/assets/js/vendor/matchMedia.js';
import { bodyScroll } from '@/assets/js/helpers/general-helpers';
import { getModalCount } from '@/assets/js/helpers/modal-helpers';

function menuScripts() {
  'use strict';

  $(document).ready(function($) {
    let header = $('#app-header');
    let menu = $('.nav-wrapper');
    let nav = menu.find('li');
    let mobileMenuItem = menu.find('.has-sub .nav');
    let navToggle = $('.menu-toggle');
    let groups = menu.find('.model-group-wrapper');
    let modelItems = menu.find('.model-list-item');
    let isDesktop, menuOpenDelay, menuCloseDelay;

    let menuResetters = $('#app-header .logo-container, .nav-wrapper li:not(.has-sub), .nav-sub .menu-item, .nav-sub .extra-link');

    let slideLimit;

    let navBar = {
      init: function () {
        let self = this;

        self.setMedia();
        self.setupMenu();
      },
      setupMenu: function () {
        let self = this;

        nav.mouseenter(function() {
          if (isDesktop) {
            let navSub = $(this).find('.nav-sub');
            let nav = $(this).find('.nav');

            if (navSub.is(':visible')) {
              clearTimeout(menuCloseDelay);
            }
            let figure = navSub.find('.model-list-item');
            menuOpenDelay = setTimeout( function() {
              navSub.slideDown(200);
              nav.addClass('open');
              navSub.removeClass('closed');
              navSub.addClass('open');
            }, 200);

            let timeout = 240;
            $.each(figure, function() {
              let elem = $(this);
              setTimeout(function(){
                elem.addClass("show");
              },timeout);
              timeout = timeout + 30;
            });

            if (navSub.hasClass('nav-sub-links')) {
              self.loadImages(this);
            }
          }
        });

        nav.mouseleave(function() {
          if (isDesktop) {
            clearTimeout(menuOpenDelay);
            let navSub = $(this).find('.nav-sub');
            let nav = $(this).find('.nav');
            menuCloseDelay = setTimeout( function() {
              navSub.slideUp(200);
              navSub.find('.model-list-item').removeClass('show');
              nav.removeClass('open');
              navSub.addClass('closed');
              navSub.removeClass('open');
            }, 200);
          }
        });

        navToggle.click(function() {
          if (!isDesktop) {
            if ($(this).hasClass('active')) {
              self.resetMenu();
            }else{
              $(this).addClass('active');
              header.addClass('mobile-menu-open');
              bodyScroll.disable();
              mobileMenuItem.each(function() {
                if ($(this).parent('.has-sub').find('.nav-links-container').hasClass('nav-cars')) {
                  const subMenu = $(this).parent('.has-sub');
                  if (!subMenu.hasClass('images-loaded')) {
                    self.loadImages(subMenu);
                  }
                }
              })
            }
          }
        });

        mobileMenuItem.click(function() {
          if (!isDesktop && !$(this).closest('.has-sub').hasClass('no-accordion')) {
            $(this).toggleClass('open');
            $(this).closest('.has-sub').find('.nav-sub').slideToggle(200);
          }
        });

        menuResetters.click(function() {
          self.resetMenu();
        });

      },
      loadImages: function(elem) {
        if  (!$(elem).hasClass('images-loaded')) {
          let navSub = $(elem).find('.nav-sub');
          let images = navSub.find('.menu-item-image');
          images.each(function(){
            let url = $(this).data('src');
            $(this).css('backgroundImage', 'url('+url+')');
          })
          $(elem).addClass('images-loaded');
        }
      },
      resetMenu: function () {
        navToggle.removeClass('active');
        header.removeClass('mobile-menu-open');
        if (getModalCount() === 0) bodyScroll.enable();

        if (isDesktop) {
          let navSub = header.find('.nav-sub');
          let nav = header.find('.nav');
          navSub.slideUp(200);
          navSub.find('.model-list-item').removeClass('show');
          nav.removeClass('open');
          navSub.addClass('closed');
          navSub.removeClass('open');
        }
      },
      setMedia: function () {
        let self = this;

        mediaCheck({
          media: '(min-width: 768px) and (max-width: 1015px)',
          entry: function () {

            groups.each(function() {
              let group = $(this);
              if (!group.hasClass('content-loaded')) {
                let groupNr = group.data('model-group');
                modelItems.each(function() {
                  if ($(this).data('model-item') == groupNr) {
                    let html = $(this).clone();
                    group.append(html);
                    $(this).addClass('hidden-tablet');
                  }
                })
                group.addClass('content-loaded');
              }
            });
            groups.show();
          },
          exit: function () {
            groups.hide();
            modelItems.show();
          }
        });

        mediaCheck({
          media: '(min-width: 1016px)',
          entry: function () {

            isDesktop = true;
            self.resetMenu();

          },
          exit: function () {
            isDesktop = false;
            self.resetMenu();
          }
        });

      },
      setCookie: function (key, value) {
        let expires = new Date();
        expires.setTime(expires.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));
        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + '; path=/';
      },
      getCookie: function (key) {
        let nameEQ = key + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      }
    };

    navBar.init();
  });
}

export { menuScripts };
