<template>
  <div id="service-worker">
    <StatusBanner
      :show="showNewContentAvailable"
      position="right"
      class="new-content-banner"
    >
      <div class="info banner-item">
        {{ $t('serviceWorker.newContentAvailable') }}
      </div>

      <div class="action banner-item">
        <UiButton
          :link="installNewServiceWorker"
        >
          {{ $t('serviceWorker.newContentUpdate') }}
        </UiButton>
      </div>
    </StatusBanner>

    <StatusBanner
      :show="showOffline"
      class="offline-banner"
    >
      <div class="info">
        {{ $t('serviceWorker.offlineInfo') }}
      </div>
    </StatusBanner>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import StatusBanner from '@/components/status/StatusBanner.vue';
import UiButton from '@/components/ui/uiButton/UiButton.vue';

export default {
  name: 'ServiceWorker',
  components: {
    StatusBanner,
    UiButton,
  },
  computed: {
    ...mapGetters([
      'serviceWorker',
    ]),
    showOffline() {
      return !this.serviceWorker.online;
    },
    showNewContentAvailable() {
      return this.serviceWorker.newContentAvailable && this.serviceWorker.online;
    },
  },
  created() {
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    ...mapMutations([
      'UPDATE_SERVICE_WORKER',
    ]),
    ...mapActions([
      'installNewServiceWorker',
    ]),
    updateOnlineStatus() {
      this.UPDATE_SERVICE_WORKER({ key: 'online', value: navigator.onLine });
    },
  },
};
</script>

<style lang="scss" scoped>
  #service-worker {
    .banner-item {
      width: 100%;
    }

    .action {
      padding-top: 20px;

      .ui-button {
        font-size: 1rem;
        width: 100%;
      }
    }

    .offline-banner {
      .info {
        display: flex;
        align-items: center;
        &:after {
          flex-shrink: 0;
          content: "";
          display: block;
          background-image: url('~images/icons/icon-wifi-offline-white.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          height: 2em;
          width: 2em;
          margin-left: .5em;
        }
      }
    }
  }
</style>
