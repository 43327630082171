<template>
  <transition
    name="transition-fade"
    :mode="mode"
  >
    <slot />
  </transition>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'TransitionFade',
  props: {
    mode: VueTypes.oneOf(['out-in', 'in-out']).def('out-in'),
  },
};
</script>

<style scoped lang="scss">
.transition-fade-enter-active, .transition-fade-leave-active {
  transition: all .3s ease;
}

.transition-fade-enter, .transition-fade-leave-to {
  opacity: 0;
}
</style>
