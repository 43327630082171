<template>
  <div
    id="app"
    :class="routeClass"
    :data-admin-mode="isAdminMode"
  >
    <div
      v-if="ready"
      class="page-wrapper"
    >
      <AdminModeIndicator />
      <slot name="header">
        <AppHeader />
      </slot>

      <main
        id="app-main"
        class="page-main"
        style="height: 0; opacity: 0;"
      >
        <template v-if="appCriticalError">
          <AppCriticalError />
        </template>
        <template v-else>
          <transition
            name="page"
            mode="out-in"
            :duration="{ enter: 0, leave: pageTransitionDuration * 2 }"
          >
            <router-view :key="routerViewKey" />
          </transition>
        </template>
      </main>
      <UiScrollTopBar />
      <slot name="footer">
        <AppFooter />
      </slot>
      <vue-progress-bar />
    </div>

    <template v-if="ready">
      <ApiQueueModal />
      <CookieConsent />
      <ServiceWorker v-if="assertServiceWorkerApi" />
      <StatusLoader />
      <StatusAlert />
      <StatusFullscreenOverlay />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiQueueModal from '@/components/ApiQueueModal.vue';
import ServiceWorker from '@/components/status/ServiceWorker.vue';
import StatusAlert from '@/components/status/StatusAlert.vue';
import StatusLoader from '@/components/status/StatusLoader.vue';
import AppHeader from '@/components/app/AppHeader.vue';
import AppFooter from '@/components/app/AppFooter.vue';
import UiScrollTopBar from '@/components/ui/uiScrollTopBar/UiScrollTopBar.vue';
import CookieConsent from '@/components/status/CookieConsent.vue';
import AppCriticalError from '@/views/error/AppCriticalError.vue';
import StatusFullscreenOverlay from '@/components/status/StatusFullscreenOverlay.vue';
import AdminModeIndicator from '@/components/administration/adminMode/AdminModeIndicator.vue';

export default {
  name: 'CommonApp',
  components: {
    AppCriticalError,
    ApiQueueModal,
    ServiceWorker,
    StatusAlert,
    StatusLoader,
    AppHeader,
    AppFooter,
    UiScrollTopBar,
    CookieConsent,
    StatusFullscreenOverlay,
    AdminModeIndicator,
  },
  emits: [
    'ready',
  ],
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    ...mapGetters([
      'appCriticalError',
      'pageTransitionDuration',
      'serviceWorker',
      'isAdminMode',
    ]),
    ready() {
      return this.mounted && this.initialCheckDone;
    },
    routerViewKey() {
      const { routerViewKey } = this.$route.meta;
      return routerViewKey || this.$route.path;
    },
    routeClass() {
      const { name } = this.$route;
      return name ? `route-${name}` : '';
    },
    assertServiceWorkerApi() {
      return 'serviceWorker' in navigator;
    },
    initialCheckDone() {
      return this.assertServiceWorkerApi ? this.serviceWorker.initialCheckDone : true;
    },
  },
  mounted() {
    this.mounted = true;
    this.$emit('ready');
  },
};
</script>

<style lang="scss">
  // Base styles
  @import '~@/assets/styles/source/_base.scss';

  #app {
    --app-admin-mode-indicator-height: 0;

    &[data-admin-mode] {
      --app-admin-mode-indicator-height: 30px;
    }
  }
</style>

<style lang="scss" theme="audi">
  // Audi fonts
  @import '~@/assets/styles/source/_fonts.audi.scss';

  // Audi Base overrides
  @import '~@/assets/styles/source/base/_forms.audi.scss';
</style>

<style lang="scss" theme="seat">
  // Seat fonts
  @import '~@/assets/styles/source/_fonts.seat.scss';
</style>

<style lang="scss" theme="cupra">
  body {
    background: $page-background;
  }

  // Cupra fonts
  @import '~@/assets/styles/source/_fonts.cupra.scss';
</style>

<style lang="scss" theme="skoda">
  // Skoda fonts
  @import '~@/assets/styles/source/_fonts.skoda.scss';

  // Skoda Base overrides
  @import '~@/assets/styles/source/base/_forms.skoda.scss';
</style>

<style lang="scss" theme="vw">
  // VW fonts
  @import '~@/assets/styles/source/_fonts.vw.scss';
</style>

<style lang="scss" theme="carbuy">
  // CarBuy fonts
  @import '~@/assets/styles/source/_fonts.carbuy.scss';
</style>

<style lang="scss" theme="catalog">
  .page-main {
    background-color: #f1f1f1;
  }
// Catalog fonts
@import '~@/assets/styles/source/_fonts.catalog.scss';
</style>
