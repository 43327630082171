import { MODULES } from '@/constants/constants-modules';

const InventoryCarsOldStore = {
  state: {
    old_inventoryCarsDataTimestamp: null,
  },
  getters: {
    old_isInventoryCarsInitiated: (state) => !!state.old_inventoryCarsDataTimestamp,

    old_getInventoryModels: (state, getters) => getters.getModels
      ?.filter((model) => model?.inventory?.isInventoryCar ?? false),

    old_getModelInventoryCount: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => getters.getModelById(modelId)?.inventory?.inventoryCount ?? 0,

    old_isModelInStock: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => getters.old_getModelInventoryCount(modelId) > 0,
  },
  mutations: {
    old_UPDATE_INVENTORY_CARS_DATA_TIMESTAMP(state, dataTimestamp = Date.now()) {
      state.old_inventoryCarsDataTimestamp = dataTimestamp;
    },
  },
  actions: {
    async old_initInventoryCars({
      state, getters, commit, dispatch,
    }) {
      if (!getters.old_isInventoryCarsInitiated) {
        if (!await dispatch('checkModule', { module: MODULES.configurator, redirect: true })) return;
      }

      await dispatch('fetchAndSetModels');

      const timeDiff = Date.now() - state.old_inventoryCarsDataTimestamp;
      if (timeDiff < 300000) return;

      const models = getters.old_getInventoryModels;

      if (!models?.length) {
        throw new Error('No models');
      }

      const fetchMarketModelsPromises = models.map((model) => {
        // skip fetching market model options if price is already set
        const skipOptions = model?.inventory?.inventoryPrice > 0;
        return dispatch('fetchAndSetMarketModels', {
          modelId: model.id,
          skipSelectionBasedData: false,
          skipOptions,
        });
      });

      await Promise.all(fetchMarketModelsPromises);

      commit('old_UPDATE_INVENTORY_CARS_DATA_TIMESTAMP', Date.now());
    },
  },
};

export default InventoryCarsOldStore;
