<template>
  <component
    :is="elementTag"
    class="ui-link"
    v-bind="elementAttributes"
    @[elementEvent].stop.prevent="onClickFunction"
  >
    <slot />
  </component>
</template>

<script>
import VueTypes from 'vue-types';
import { isPath, isUrl } from '@/assets/js/helpers/general-helpers';

const propValidation = (value) => {
  if (!value) return true;
  return typeof value === 'string'
    ? isUrl(value) || isPath(value)
    : typeof value === 'function';
};

export default {
  name: 'UiFunctionLinkBase',
  props: {
    link: VueTypes.custom(
      propValidation,
      'link is not a function or a valid URL/path',
    ),
  },
  data() {
    return {
      defaultTag: 'span',
    };
  },
  computed: {
    externalLink() {
      return isUrl(this.link);
    },
    internalLink() {
      return isPath(this.link);
    },
    elementTag() {
      if (this.externalLink) return 'a';
      if (this.internalLink) return 'router-link';
      return this.defaultTag;
    },
    elementAttributes() {
      const attributes = {};

      if (this.externalLink) {
        attributes.href = this.link;
        attributes.target = '_blank';
        attributes.rel = 'noreferrer';
      }

      if (this.internalLink) {
        attributes.to = this.link;
      }

      return attributes;
    },
    elementEvent() {
      return typeof this.link === 'function' ? 'click' : null;
    },
  },
  methods: {
    onClickFunction() {},
  },
};
</script>
