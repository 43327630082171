import isEmpty from 'lodash/isEmpty';
import httpInternal from '@/http/httpInternal';

const CartStore = {
  state: {
    orderLines: [],
  },
  getters: {
    cartItems(state) {
      return state.orderLines;
    },
    getReservationProduct(state) {
      const reservationProduct = state.orderLines.find((line) => line.type === 'Reservation');
      return reservationProduct === undefined ? null : reservationProduct;
    },
    getCartTotalPrice(state) {
      return state.orderLines.reduce((total, item) => {
        const withoutVat = parseInt(item.price, 10);
        const vat = withoutVat * (parseInt(item.taxRate, 10) / 100);
        return total + withoutVat + vat;
      }, 0);
    },
  },
  mutations: {
    ADD_TO_CART(state, item = {}) {
      const {
        allowFleet = null,
        brand = null,
        sku = null,
        name = null,
        price = null,
        taxRate = null,
        imageUrl = null,
        carlineId = null,
        carlineName = null,
        reservationsPerCustomer = null,
        newsletterLink = null,
        type = null,
        termsAndConditions = null,
        imageDisclaimer = null,
      } = item;
      if (
        typeof item === 'object'
        && !isEmpty(item)
        && state.orderLines.findIndex((x) => x.sku === sku) === -1
      ) {
        state.orderLines.push({
          allowFleet,
          brand,
          sku,
          name,
          price,
          taxRate,
          imageUrl,
          carlineId,
          carlineName,
          reservationsPerCustomer,
          newsletterLink,
          type,
          termsAndConditions,
          imageDisclaimer,
        });
      }
    },
    REMOVE_FROM_CART(state, itemId) {
      const index = state.orderLines.findIndex((x) => x.id === itemId);
      if (index !== -1) {
        state.orderLines.splice(index, 1);
      }
    },
    CLEAR_CART(state) {
      state.orderLines = [];
    },
  },
  actions: {
    async addReservationProduct({ getters, dispatch, commit }, sku) {
      await dispatch('resetCheckout');
      let reservationProductResponse;

      try {
        reservationProductResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetReservationProductUrl(sku),
        });
      } catch (error) {
        dispatch('setAlert', { key: 'fetchReservationError', message: 'error.productNotFound', log: error.message });
        throw new Error(`Could not add reservation product with SKU ${sku}`);
      }

      const { data: reservationProduct } = reservationProductResponse;

      if (!reservationProduct?.allowPrivate) {
        dispatch('setAlert', { key: 'fetchReservationError', message: 'error.productNotFound' });
        throw new Error(`Reservation product with SKU ${sku} is not allowed for private`);
      }

      commit('ADD_TO_CART', reservationProductResponse.data);
    },
  },
};

export default CartStore;
