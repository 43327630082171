import VuexPersist from 'vuex-persist';
import AdministrationStore from '@/stores/administration/administration';
import AnnouncementsStore from '@/stores/webSocket/announcements';
import ApiQueueStore from '@/stores/webSocket/apiQueue';
import BringStore from '@/stores/api/bring';
import BrregStore from '@/stores/api/brreg';
import CartStore from '@/stores/checkout/cart';
import CheckoutStore from '@/stores/checkout/checkout';
import ConfigStore from '@/stores/settings/config';
import ConfigurationStore from '@/stores/configurator/configuration';
import ConfiguratorStore from '@/stores/configurator/configurator';
import CookiesStore from '@/stores/cookies';
import CreditCheckStore from '@/stores/configurator/creditCheck';
import DealerStore from '@/stores/administration/dealer';
import DealersStore from '@/stores/dealers';
import FinancingStore from '@/stores/settings/financing';
import FleetStore from '@/stores/fleet/fleet';
import InventoryCarsOldStore from '@/stores/stockCars/inventoryCarsOld';
import NpsStore from '@/stores/nps/nps';
import StockCarsStore from '@/stores/stockCars/stockCars';
import StockCarsSearchStore from '@/stores/stockCars/stockCarsSearch';
import MileageYearPriceSettingsStore from '@/stores/configurator/mileageYearPriceSettings';
import ModulesStore from '@/stores/settings/modules';
import NetsStore from '@/stores/paymentGateways/nets';
import NewCarTaxationStore from '@/stores/configurator/newCarTaxation';
import OrdersStore from '@/stores/configurator/orders';
import StatusStore from '@/stores/status';
import SuccessStore from '@/stores/configurator/success';
import TrackingStore from '@/stores/tracking';
import UserAgreementsStore from '@/stores/user/userAgreements';
import UserCompanyStore from '@/stores/user/userCompany';
import UserCreationStore from '@/stores/user/userCreation';
import UserSelfServiceStore from '@/stores/api/userSelfService';
import UserStore from '@/stores/user/user';
import ValidationStore from '@/stores/user/validation';
import VehicleDataStore from '@/stores/configurator/vehicleData';
import WebSocketStore from '@/stores/webSocket/webSocket';
import WltpStore from '@/stores/configurator/wltp';

// VuexPersist:
// key: The key to store the state on in the storage provider.
// storage: window.localStorage, window.sessionStorage or localForage
// Function that passes the state and returns the state with only the objects you want to store.
// reducer: state => state,
// Function that passes a mutation and lets you decide if it should update
// the state in localStorage.
// filter: mutation => (true)
// modules: only save specified module

const configStorage = new VuexPersist({
  key: 'storageConfig',
  storage: window.sessionStorage,
  modules: ['Config'],
  reducer: (state) => ({
    Config: {
      appPreviewAuthorizationKey: state.Config.appPreviewAuthorizationKey,
    },
  }),
});

const cartStorage = new VuexPersist({
  key: 'storageCart',
  storage: window.sessionStorage,
  modules: ['Cart'],
});

const checkoutStorage = new VuexPersist({
  key: 'storageCheckout',
  storage: window.sessionStorage,
  modules: ['Checkout'],
  reducer: (state) => ({
    Checkout: {
      checkoutSteps: state.Checkout.checkoutSteps,
      currentCheckoutStepKey: state.Checkout.currentCheckoutStepKey,
    },
  }),
});

const cookiesStorage = new VuexPersist({
  key: 'storageCookies',
  storage: window.localStorage,
  modules: ['Cookies'],
});

const configurationStorage = new VuexPersist({
  key: 'storageConfiguration',
  storage: window.sessionStorage,
  modules: ['Configuration'],
});

const configuratorStorage = new VuexPersist({
  key: 'storageConfigurator',
  storage: window.sessionStorage,
  modules: ['Configurator'],
});

const dealerStorage = new VuexPersist({
  key: 'storageDealer',
  storage: window.sessionStorage,
  modules: ['Dealer'],
});

const dealersStorage = new VuexPersist({
  key: 'storageDealers',
  storage: window.sessionStorage,
  modules: ['Dealers'],
});

const financingStorage = new VuexPersist({
  key: 'storageFinancing',
  storage: window.sessionStorage,
  modules: ['Financing'],
});

const fleetStorage = new VuexPersist({
  key: 'storageFleet',
  storage: window.sessionStorage,
  modules: ['Fleet'],
});

const mileageYearPriceSettingsStorage = new VuexPersist({
  key: 'storageMileageYearPriceSettings',
  storage: window.sessionStorage,
  modules: ['MileageYearPriceSettings'],
});

const npsStorage = new VuexPersist({
  key: 'storageNps',
  storage: window.localStorage,
  modules: ['Nps'],
});

const ordersStorage = new VuexPersist({
  key: 'storageOrders',
  storage: window.sessionStorage,
  modules: ['Orders'],
});

const userStorage = new VuexPersist({
  key: 'storageUser',
  storage: window.sessionStorage,
  modules: ['User'],
});

const userAgreementsStorage = new VuexPersist({
  key: 'storageUserAgreements',
  storage: window.sessionStorage,
  modules: ['UserAgreements'],
});

const userCompanyStorage = new VuexPersist({
  key: 'storageUserCompany',
  storage: window.sessionStorage,
  modules: ['UserCompany'],
});

const validationStorage = new VuexPersist({
  key: 'storageValidation',
  storage: window.sessionStorage,
  modules: ['Validation'],
});

const vehicleDataStorage = new VuexPersist({
  key: 'storageVehicleData',
  storage: window.sessionStorage,
  modules: ['VehicleData'],
});

const wltpStorage = new VuexPersist({
  key: 'storageWltp',
  storage: window.sessionStorage,
  modules: ['Wltp'],
});

const inventoryCarsOldStorage = new VuexPersist({
  key: 'storageInventoryCarsOld',
  storage: window.sessionStorage,
  modules: ['old_InventoryCars'],
});

const stockCarsStorage = new VuexPersist({
  key: 'storageStockCars',
  storage: window.sessionStorage,
  modules: ['StockCars'],
});

const stockCarsSearchStorage = new VuexPersist({
  key: 'storageStockCarsSearch',
  storage: window.sessionStorage,
  modules: ['StockCarsSearch'],
});

// this.$store.getters.var
// this.$store.commit(var);  is for mutations
// this.$store.dispatch(var) is for actions
// actions is used for async, and then use commits after
// mapGetters vuex is used in vue files to separate scripting from vue files and into store files
// actions get context instead of store, to simplify calling commit directly,
// you can use {commit} in parameters
const CommonStore = {
  modules: {
    Administration: AdministrationStore,
    Announcements: AnnouncementsStore,
    ApiQueue: ApiQueueStore,
    Bring: BringStore,
    Brreg: BrregStore,
    Cart: CartStore,
    Checkout: CheckoutStore,
    Cookies: CookiesStore,
    Config: ConfigStore,
    Configuration: ConfigurationStore,
    Configurator: ConfiguratorStore,
    CreditCheck: CreditCheckStore,
    Dealer: DealerStore,
    Dealers: DealersStore,
    Financing: FinancingStore,
    Fleet: FleetStore,
    old_InventoryCars: InventoryCarsOldStore,
    StockCars: StockCarsStore,
    StockCarsSearch: StockCarsSearchStore,
    MileageYearPriceSettings: MileageYearPriceSettingsStore,
    Modules: ModulesStore,
    Nets: NetsStore,
    NewCarTaxation: NewCarTaxationStore,
    Nps: NpsStore,
    Orders: OrdersStore,
    Status: StatusStore,
    Success: SuccessStore,
    Tracking: TrackingStore,
    User: UserStore,
    UserAgreements: UserAgreementsStore,
    UserCompany: UserCompanyStore,
    UserCreation: UserCreationStore,
    UserSelfService: UserSelfServiceStore,
    Validation: ValidationStore,
    VehicleData: VehicleDataStore,
    WebSocket: WebSocketStore,
    Wltp: WltpStore,
  },
  plugins: [
    cartStorage.plugin,
    checkoutStorage.plugin,
    configStorage.plugin,
    cookiesStorage.plugin,
    configurationStorage.plugin,
    configuratorStorage.plugin,
    dealerStorage.plugin,
    dealersStorage.plugin,
    financingStorage.plugin,
    fleetStorage.plugin,
    inventoryCarsOldStorage.plugin,
    npsStorage.plugin,
    stockCarsStorage.plugin,
    stockCarsSearchStorage.plugin,
    mileageYearPriceSettingsStorage.plugin,
    ordersStorage.plugin,
    userAgreementsStorage.plugin,
    userCompanyStorage.plugin,
    userStorage.plugin,
    validationStorage.plugin,
    vehicleDataStorage.plugin,
    wltpStorage.plugin,
  ],
};

export default CommonStore;
