<template>
  <StatusBanner
    v-if="hasCookiesConfig"
    :show="shouldShowCookieConsent"
    position="left"
    class="cookie-consent"
  >
    <div class="content-wrapper">
      <div class="cookie-information">
        <div
          v-if="title"
          class="title"
        >
          {{ title }}
        </div>
        <div
          v-if="text || link.href"
          class="text"
        >
          {{ text }}
          <UiLink
            v-if="link.href"
            :link="link.href"
          >
            {{ link.text }}
          </UiLink>
        </div>
      </div>
      <div class="cookie-actions">
        <UiButton
          class="cookie-reject cookie-action"
          action-type="secondary"
          contrast="light"
          :link="rejectCookies"
        >
          {{ $t('cookies.reject') }}
        </UiButton>
        <UiButton
          class="cookie-accept cookie-action"
          :link="acceptCookies"
        >
          {{ $t('cookies.accept') }}
        </UiButton>
      </div>
    </div>
  </StatusBanner>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StatusBanner from '@/components/status/StatusBanner.vue';
import UiLink from '@/components/ui/uiLink/UiLink.vue';
import UiButton from '@/components/ui/uiButton/UiButton.vue';

export default {
  name: 'CookieConsent',
  components: {
    UiButton,
    UiLink,
    StatusBanner,
  },
  computed: {
    ...mapGetters([
      'shouldShowCookieConsent',
      'hasCookiesConfig',
      'cookiesConfig',
    ]),
    title() {
      return this.cookiesConfig.title;
    },
    text() {
      return this.cookiesConfig.text;
    },
    link() {
      return this.cookiesConfig.link || {};
    },
  },
  methods: {
    ...mapActions([
      'acceptCookies',
      'rejectCookies',
    ]),
  },
};
</script>

<style lang="scss">
.cookie-consent {
  width: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .ui-link {
    color: $color-white;
    font-weight: $font-weight__heavier;
  }

  .cookie-information {
    & > div {
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .title {
      font-weight: $font-weight__semibold;
    }
  }

  .cookie-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: -8px -10px;

    .cookie-action {
      margin: 8px 10px;
      min-width: auto;
      width: auto;
      flex: 1 0 auto;
      max-width: calc(100% - 20px);
    }
  }
}
</style>

<style lang="scss" theme="carbuy">
.cookie-consent {
  .ui-link {
    &:hover {
      color: $color-white;
      opacity: .8;
    }
  }
}
</style>
