import Velocity from 'velocity-animate';
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import { DEVICES } from '@/constants/constants-devices';
import {
  bodyScroll, scrollToTop, waitFor,
} from '@/assets/js/helpers/general-helpers';
import consoleLog from '@/assets/js/helpers/console-log';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';
import { ALERT_TYPES } from '@/constants/constants-status';

const SET_REMOVAL = {};

// eslint-disable-next-line func-names
const resizeDeviceChecker = debounce((commit, breakpoints = []) => {
  const matchedBreakpoint = breakpoints.find((breakpoint) => !!window.matchMedia(`(max-width: ${breakpoint.maxWidth}px)`).matches);
  const device = matchedBreakpoint
    ? matchedBreakpoint.device
    : breakpoints[breakpoints.length - 1].device;

  commit('UPDATE_CURRENT_DEVICE', device);
}, 50);

const StatusStore = {
  state: {
    // @todo Refactor loader
    loading: false,
    loadingMessage: undefined,
    pageTransitionActive: true,
    pageTransitionDuration: 100,
    alerts: {},
    serviceWorker: {
      online: true,
      newContentAvailable: false,
      initialCheckDone: process.env.NODE_ENV !== 'production',
    },
    currentDevice: undefined,
    deviceBreakpoints: [
      {
        device: DEVICES.mobile,
        maxWidth: 767,
      },
      {
        device: DEVICES.tablet,
        maxWidth: 1016,
      },
      {
        device: DEVICES.desktop,
        maxWidth: 1436,
      },
    ],
  },
  getters: {
    loading: (state) => state.loading,
    loadingMessage: (state) => state.loadingMessage,
    pageTransitionActive: (state) => state.pageTransitionActive,
    pageTransitionDuration: (state) => state.pageTransitionDuration,
    alerts: (state) => state.alerts,
    serviceWorker: (state) => state.serviceWorker,
    currentDevice: (state) => state.currentDevice,
  },
  mutations: {
    ADD_ALERT(state, { key = '', message = '', type = 'error' }) {
      this._vm.$set(state.alerts, key, { message, type });
    },
    UPDATE_PAGE_TRANSITION_ACTIVE(state, transitioning = false) {
      this._vm.$set(state, 'pageTransitionActive', transitioning);
    },
    UPDATE_SERVICE_WORKER(state, { key = '', value }) {
      if (key in state.serviceWorker) state.serviceWorker[key] = value;
    },
    UPDATE_CURRENT_DEVICE(state, device = '') {
      state.currentDevice = device;
    },
  },
  actions: {
    // @todo @refactor Clean up and get rid of jQuery...
    async pageTransitionOut({ getters, commit }, next) {
      const pageContainer = $('#app-main');

      if (getters.pageTransitionActive || !pageContainer.length) {
        if (isFunction(next)) next();
        return;
      }

      commit('UPDATE_PAGE_TRANSITION_ACTIVE', true);

      const oldHeight = pageContainer.outerHeight(true);

      pageContainer.velocity('stop');
      pageContainer.css({ height: oldHeight });

      const readyReference = { ready: false };

      await Velocity(
        pageContainer,
        { opacity: 0 },
        {
          duration: getters.pageTransitionDuration,
          complete() {
            scrollToTop();
            setTimeout(() => {
              if (isFunction(next)) next();
              readyReference.ready = true;
            }, 100);
          },
        },
      );

      await waitFor(readyReference);
    },
    async pageTransitionIn({ getters, commit, dispatch }, { stopLoader = true } = {}) {
      const disableLoader = () => {
        if (stopLoader) dispatch('setLoader', false);
      };

      if (!getters.pageTransitionActive) {
        disableLoader();
        return;
      }

      const self = this;
      const pageContainer = $('#app-main');
      const pageContent = $('.page-content');
      const newHeight = pageContent.outerHeight(true);

      pageContainer.velocity('stop');
      pageContent.velocity('stop');

      await Velocity(
        pageContainer,
        { height: newHeight },
        {
          duration: getters.pageTransitionDuration,
          complete() {
            Velocity(
              pageContainer,
              { opacity: 1 },
              {
                duration: getters.pageTransitionDuration,
                complete() {
                  pageContainer.removeAttr('style');
                  commit('UPDATE_PAGE_TRANSITION_ACTIVE', false);
                  self._vm.$Progress.finish();
                  disableLoader();
                },
              },
            );
          },
        },
      );
    },
    setLoader({ state }, isLoading = false) {
      state.loading = isLoading;
      if (!isLoading) state.loadingMessage = undefined;
    },
    setLoadingMessage({ state }, message = undefined) {
      state.loadingMessage = message;
    },
    async setFullScreenOverlay({ state }, showFullscreenOverlay = false) {
      state.fullscreenOverlay = showFullscreenOverlay;

      const fullscreenElement = document.getElementById('status-fullscreen-overlay');
      if (!fullscreenElement) return;

      const onFullscreenCallback = () => {
        bodyScroll.disable();
      };

      const offFullscreenCallback = () => {
        fullscreenElement.removeAttribute('style');
        bodyScroll.enable();
      };

      const opacity = showFullscreenOverlay ? 1 : 0;

      await Velocity(fullscreenElement, 'stop');
      await Velocity(
        fullscreenElement,
        { opacity },
        {
          duration: 300,
          complete() {
            showFullscreenOverlay
              ? onFullscreenCallback()
              : offFullscreenCallback();
          },
        },
      );
    },
    setAlert({ dispatch, commit }, {
      key = '',
      message = '',
      type = ALERT_TYPES.error,
      disableLoader = true,
      log,
      autoRemove = true,
    }) {
      const alertType = Object.values(ALERT_TYPES).includes(type) ? type : ALERT_TYPES.error;
      commit('ADD_ALERT', { key, message, type: alertType });

      if (log !== undefined) {
        consoleLog(log);
      }

      if (autoRemove) {
        clearTimeout(SET_REMOVAL[key]);
        SET_REMOVAL[key] = setTimeout(() => {
          dispatch('removeAlert', key);
        }, 10000);
      }

      if (alertType === 'error' && disableLoader) {
        dispatch('setLoader', false);
      }
    },
    removeAlert({ state }, key) {
      this._vm.$delete(state.alerts, key);
      clearTimeout(SET_REMOVAL[key]);
    },
    async installNewServiceWorker() {
      const registration = await navigator.serviceWorker.getRegistration();

      if (registration?.waiting?.state === 'installed') {
        await registration.waiting.postMessage({ action: 'skipWaiting' });
      }
    },
    async precacheAndRouteServiceWorker() {
      const registration = await navigator.serviceWorker.getRegistration();

      await registration.waiting.postMessage({ action: 'precacheAndRoute' });
    },
    initDeviceCheck({ state, commit }) {
      const deviceCheck = () => resizeDeviceChecker(commit, state.deviceBreakpoints);

      window.removeEventListener('resize', deviceCheck);
      deviceCheck();
      window.addEventListener('resize', deviceCheck);
    },
    async preventModalCloseOnRouteChange() {
      const router = await getClientRouter();

      const preventModalCloseGuard = (to, from, next) => {
        next();
      };

      preventModalCloseGuard.preventModalClose = true;
      router.beforeEach(preventModalCloseGuard);
    },
    async allowModalCloseOnRouteChange() {
      const router = await getClientRouter();

      const { beforeHooks } = router;
      const hookIndex = beforeHooks.findIndex((x) => x.preventModalClose);
      if (hookIndex !== -1) {
        beforeHooks.splice(hookIndex, 1);
      }
    },
  },
};

export default StatusStore;
