/* eslint-disable */
import Swiper from 'swiper';
import { Video } from './video.js';

function fullSlideshow() {
  'use strict';

  let body = $('body');

  let fullSlideshow = {
    init: function(){
      let self = this;
      let slideshows = $('.full-slideshow');

      slideshows.each(function(){
        let slideshow = $(this);
        slideshow.videoPlayer = false;
        self.initVideo(slideshow);
        self.initSwiper(slideshow);
        if (slideshow.data('slideshow-gallery') === true) {
          let swiper = this.querySelector('.swiper').swiper;
          self.galleryLoaded = false;
          self.initGallery(slideshow, swiper);
        }
      });
    },
    initSwiper: function(slideshow){
      let swiperContainer = slideshow.find('.swiper').get(0);

      let slideCount = swiperContainer.querySelectorAll('.swiper-slide').length;

      let thumb = slideshow.find('.thumb');

      if (slideCount > 1){
        if (!swiperContainer.swiper) {
          const nextEl = swiperContainer.querySelector('.swiper-button-next');
          const prevEl = swiperContainer.querySelector('.swiper-button-prev');
          const paginationEl = swiperContainer.querySelector('.swiper-pagination');

          let slideSwiper = new Swiper(swiperContainer, {
            autoHeight: true,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 0,
            navigation: {
              nextEl,
              prevEl,
            },
            pagination: {
              el: paginationEl,
              clickable: true,
              type: 'bullets',
            },
            on: {
              slideChange: function(){
                thumb.removeClass('active');
                slideshow.find('[data-slide-index="'+slideSwiper.activeIndex+'"]').addClass('active');
                if (slideshow.videoPlayer.player && slideshow.videoPlayer.player.getPlayerState() === 1) {
                  slideshow.videoPlayer.reset();
                }
              }
            }
          });
        }
      }

    },
    initVideo: function(slideshow){
      let self = this;
      let videoContainer = slideshow.find('[data-video-container]');
      let slides = slideshow.find('.slideshow-item');
      let close = videoContainer.find('[data-video-close]');

      if (videoContainer.length > 0) {
        let videoWrap = videoContainer.find('.video-container');
        let videoElem = videoContainer.find('.video-player');

        close.on('click', function() {
          videoContainer.removeClass('show');
          slideshow.videoPlayer.reset();
        });
        videoContainer.on('touchend mouseup', function (e) {
          if (!videoWrap.is(e.target) && videoWrap.has(e.target).length === 0 && videoContainer.hasClass('show')) {
            videoContainer.removeClass('show');
            slideshow.videoPlayer.reset();
          }
        });
      }


      // Add behavior for slides
      slides.each(function(){
        let slide = $(this);
        let videoTrigger = slide.find('[data-video-trigger]');

        if (videoTrigger.length > 0) {
          let videoTarget = videoTrigger.data('video-target');
          let youtubeId = videoTrigger.data('youtube-id');


          videoTrigger.on('click', function() {
            if (!slideshow.videoPlayer) {
              let wrapper = $(this).closest('.video-player-container').find('.video-player');
              wrapper.attr('src', wrapper.data('youtube-src'));
              slideshow.videoPlayer = Video.create(wrapper[0]);
            }
            slideshow.videoPlayer.loadVideo(youtubeId);
            videoContainer.addClass('show').addClass(videoTarget);
          })
        }

      });
    },
    initGallery: function(slideshow, swiper){
      let self = this;
      self.bindEvents(slideshow, swiper);
    },
    bindEvents: function(slideshow, swiper){
      let self = this;
      let trigger = slideshow.find('[data-toggle="slideshow-gallery"]');
      let thumbs = slideshow.find('.gallery-thumbs');
      let thumb = thumbs.find('.thumb');

      trigger.click(function() {
        slideshow.toggleClass('gallery-fullscreen');

        if (slideshow.hasClass('slideshow-gallery-open')) {
          body.attr( 'data-offset', $(window).scrollTop());
          body.addClass('slideshow-gallery-open');
        }else{
          body.removeClass('slideshow-gallery-open');
          $(window).scrollTop(body.attr('data-offset'));
          body.removeAttr('data-offset');
        }

        if (swiper) swiper.update();
        if (self.galleryLoaded === false) {
          thumb.each(function(i) {
            let slide = slideshow.find('[data-slide="'+i+'"]');
            let image = slide.find('[data-responsive-background-image]').css('background-image');
            $(this).css('background-image', image);
          });
          self.galleryLoaded = true;
        }
      });

      thumb.click(function() {
        self.slideToImage(slideshow, swiper, $(this).data('slide-index'));
      })
    },
    slideToImage: function(slideshow, swiper, slideIndex){
      if (!swiper) return;
      let thumb = slideshow.find('.thumb');
      let currentThumb = slideshow.find('[data-slide-index="'+slideIndex+'"]');
      swiper.slideTo(slideIndex, 500);
      currentThumb.addClass('active');
      thumb.not(currentThumb).removeClass('active');
    }
  };

  $(document).ready(function($) {
    fullSlideshow.init();
  });

}

export { fullSlideshow };
