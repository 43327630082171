import httpInternal from '@/http/httpInternal';
import head from 'lodash/head';
import { CHECKOUT_STEPS } from '@/constants/constants-checkout';
import { scrollToTop } from '@/assets/js/helpers/general-helpers';
import consoleLog from '@/assets/js/helpers/console-log';

// @todo Get this from config maybe?
function initialCheckoutSteps() {
  return [
    {
      key: CHECKOUT_STEPS.login,
      label: 'general.verification',
      completed: false,
      mainComponent: 'CheckoutStepLogin',
      mainColumns: 4,
      asideComponent: 'CheckoutAsideDefault',
      asideMobile: 'CheckoutAsideDefault',
    },
    {
      key: CHECKOUT_STEPS.information,
      label: 'general.contactInfo',
      completed: false,
      mainComponent: 'CheckoutStepInformation',
      mainColumns: 4,
      asideComponent: 'CheckoutAsideReservationCart',
      asideMobile: 'CheckoutAsideReservationCart',
    },
    {
      key: CHECKOUT_STEPS.paymentError,
      label: 'general.payment',
      completed: false,
      mainComponent: 'CheckoutStepPaymentError',
      mainColumns: 8,
      asideComponent: 'CheckoutAsideDefault',
      asideMobile: 'CheckoutAsideDefault',
      isExternal: true,
    },
    {
      key: CHECKOUT_STEPS.confirmation,
      label: 'general.confirmation',
      completed: false,
      mainComponent: 'CheckoutStepConfirmation',
      mainColumns: 8,
      asideComponent: 'CheckoutAsideDefault',
      asideMobile: 'CheckoutAsideDefault',
    },
    {
      key: CHECKOUT_STEPS.bilhold,
      label: 'general.mittBilhold',
      isExternal: true,
    },
  ];
}

function initialOrderData() {
  return {
    orderCompleted: false,
    orderNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    totalAmount: '',
    dealer: '',
    cartItems: [],
  };
}

const CheckoutStore = {
  state: {
    checkoutOpen: true,
    checkoutSteps: initialCheckoutSteps(),
    currentCheckoutStepKey: head(initialCheckoutSteps()).key,
    checkoutOrderData: initialOrderData(),
  },
  getters: {
    checkoutOpen: (state) => state.checkoutOpen,
    checkoutSteps: (state) => state.checkoutSteps,
    checkoutStepIndex: (state) => (
      stepKey = state.currentCheckoutStepKey,
    ) => state.checkoutSteps.findIndex((x) => x.key === stepKey),
    checkoutStepByKey: (state) => (
      stepKey = state.currentCheckoutStepKey,
    ) => state.checkoutSteps.find((x) => (x.key === stepKey)),
    currentCheckoutStepKey: ({ currentCheckoutStepKey }) => currentCheckoutStepKey,
    checkoutOrderData: (state) => state.checkoutOrderData,
    isCheckoutConfirmation: ({
      currentCheckoutStepKey,
    }) => currentCheckoutStepKey === CHECKOUT_STEPS.confirmation,
    isFreeCheckout: (state, getters) => getters.getCartTotalPrice === 0,
  },
  mutations: {
    COMPLETE_CHECKOUT_STEP(state, stepKey = state.currentCheckoutStepKey) {
      const stepIndex = state.checkoutSteps.findIndex((x) => x.key === stepKey);
      this._vm.$set(state.checkoutSteps[stepIndex], 'completed', true);
    },
    INCOMPLETE_CHECKOUT_STEP(state, stepKey = state.currentCheckoutStepKey) {
      const stepIndex = state.checkoutSteps.findIndex((x) => x.key === stepKey);
      this._vm.$set(state.checkoutSteps[stepIndex], 'completed', false);
    },
    UPDATE_CHECKOUT_STEPS(state, checkoutSteps = initialCheckoutSteps()) {
      this._vm.$set(state, 'checkoutSteps', checkoutSteps);
    },
    UPDATE_CURRENT_CHECKOUT_STEP_KEY(state, stepKey = head(initialCheckoutSteps()).key) {
      state.currentCheckoutStepKey = stepKey;
    },
    UPDATE_CHECKOUT_ORDER_DATA(state, checkoutOrderData = initialOrderData()) {
      state.checkoutOrderData = checkoutOrderData;
    },
    UPDATE_CHECKOUT_OPEN(state, checkoutOpen = true) {
      state.checkoutOpen = checkoutOpen;
    },
  },
  actions: {
    async resetCheckout({ dispatch, commit }, clearCart = true) {
      try {
        commit('UPDATE_CHECKOUT_STEPS');
        commit('UPDATE_CURRENT_CHECKOUT_STEP_KEY');
        commit('UPDATE_CHECKOUT_ORDER_DATA');
        if (clearCart) commit('CLEAR_CART');
        await dispatch('logout', false);
        return true;
      } catch (e) {
        consoleLog(e);
        dispatch('setAlert', { key: 'resetCheckoutError', message: 'error.resetCheckout' });
        return false;
      }
    },
    nextCheckoutStep({ state, getters, commit }) {
      const lastIndex = state.checkoutSteps.length - 1;
      const currentIndex = getters.checkoutStepIndex();
      if (currentIndex < lastIndex) {
        scrollToTop();
        commit('COMPLETE_CHECKOUT_STEP');
        commit('UPDATE_CURRENT_CHECKOUT_STEP_KEY', state.checkoutSteps[currentIndex + 1].key);
      }
    },
    prevCheckoutStep({ state, getters, commit }) {
      const currentIndex = getters.checkoutStepIndex();
      if (currentIndex > 0) {
        commit('UPDATE_CURRENT_CHECKOUT_STEP_KEY', state.checkoutSteps[currentIndex - 1].key);
      }
      return true;
    },
    goToCheckoutStep({ getters, commit, dispatch }, stepKey = '') {
      if (getters.checkoutStepIndex(stepKey) === -1) {
        dispatch('setAlert', { key: 'checkoutStepError', message: 'error.checkoutStepNotFound' });
        return false;
      }

      commit('UPDATE_CURRENT_CHECKOUT_STEP_KEY', stepKey);
      return true;
    },
    async completeAllCheckoutSteps({ state, commit }) {
      state.checkoutSteps.forEach((step) => {
        if (!step.completed && step.key !== CHECKOUT_STEPS.bilhold) commit('COMPLETE_CHECKOUT_STEP', step.key);
      });
      return true;
    },
    async completeCheckout({ commit, dispatch }, orderData) {
      commit('UPDATE_CHECKOUT_ORDER_DATA', {
        orderCompleted: true,
        orderNumber: orderData.orderNumber,
        orderId: orderData.orderId,
        firstName: orderData.firstName,
        lastName: orderData.lastName,
        email: orderData.email,
        zipCode: orderData.zipCode,
        brandConsent: orderData.brandConsent,
        modelConsent: orderData.modelConsent,
        totalAmount: orderData.totalAmount,
        dealer: orderData.dealer,
        cartItems: orderData.cartItems,
        companyInfo: orderData.companyInfo,
      });

      await dispatch('completeAllCheckoutSteps');
      await dispatch('goToCheckoutStep', CHECKOUT_STEPS.confirmation);

      return true;
    },
    async cancelCheckout({ dispatch }) {
      await dispatch('resetCheckout', false);
      scrollToTop();

      if (this.getters.cartItems && this.getters.cartItems.length > 0) {
        const cartItem = this.getters.cartItems[0];
        this._vm.$tracking.trackEvent('CancelCheckout', {
          carlineId: cartItem.carlineId,
          carlineName: cartItem.carlineName,
          linkName: 'Avbryt',
          linkUrl: `${window.location.origin}/checkout/login`,
          contentId: parseInt('checkcancel', 36),
          userZip: this.getters.user.info.zipCode,
          formCancel: true,
        });
      }
    },
    async isAllowedToReserve({ getters, dispatch }) {
      const cartItem = getters.cartItems;
      let reservationLimitCustomer = null;

      if (cartItem.length > 0) {
        reservationLimitCustomer = cartItem[0].reservationsPerCustomer;
      } else {
        return dispatch('resetCheckout');
      }

      if (!reservationLimitCustomer) {
        return true;
      }

      let response;
      try {
        // @TODO This should be a get in the future. Post today due to caching in cloudfront
        response = await httpInternal(
          {
            method: 'post',
            url: getters.apiAllowedToReserveUrl,
            data: {
              userUuid: getters.userInfo.userUuid,
              cartItem: getters.cartItems,
            },
            crossDomain: true,
          },
        );
        return response.data.allowedToReserve;
      } catch (error) {
        dispatch('setAlert', { key: 'allowedToReserveError', message: 'error.allowedToReserveError', log: error.message });
        throw error;
      }
    },
    async fetchCheckoutOpen({ getters, dispatch, commit }) {
      try {
        const reservationProduct = getters.getReservationProduct;
        // @TODO This should be a get in the future. Post today due to caching in cloudfront
        const response = await httpInternal(
          {
            method: 'post',
            url: getters.apiProductReservationOpenUrl,
            data: {
              sku: reservationProduct.sku,
            },
          },
        );
        commit('UPDATE_CHECKOUT_OPEN', response.data.isOpen);
      } catch (error) {
        commit('UPDATE_CHECKOUT_OPEN', false);
        dispatch('setAlert', { key: 'reservationOpenError', message: 'error.reservationOpen' });
      }
    },
  },
};

export default CheckoutStore;
