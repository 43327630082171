import httpExternal from '@/http/httpExternal';

const BringStore = {
  state: {
    bringApiHost: process.env.bringApiHost,
    bringEndpoints: {
      postalCodeUrl: 'postalCode.json',
    },
    zipCodeApiStatus: true,
    zipCodeValid: true,
  },
  getters: {
    bringPostalCode: (state) => `${state.bringApiHost}${state.bringEndpoints.postalCodeUrl}`,
    zipCodeApiStatus: (state) => state.zipCodeApiStatus,
    zipCodeValid: (state) => state.zipCodeValid,
  },
  mutations: {
    UPDATE_ZIP_CODE_API_STATUS(state, zipCodeApiStatus = true) {
      state.zipCodeApiStatus = zipCodeApiStatus;
    },
    UPDATE_ZIP_CODE_VALID(state, zipCodeValid = false) {
      state.zipCodeValid = zipCodeValid;
    },
  },
  actions: {
    async setCityByZip({ getters, commit, dispatch }) {
      try {
        const response = await httpExternal(
          {
            method: 'get',
            url: getters.bringPostalCode,
            params: {
              clientUrl: `${window.location.protocol}//${window.location.hostname}`,
              pnr: getters.userInfo.zipCode,
              country: getters.userInfo.countryCode,
            },
          },
        );
        commit('UPDATE_ZIP_CODE_API_STATUS', true);
        let city = '';
        let valid = false;
        if (response.data.result !== 'EmptyPostnumber') {
          city = response.data.result;
        }

        if (response.data.valid) {
          valid = true;
          dispatch('fetchSuggestedDealersFromZip', getters.userInfo.zipCode);
        }

        commit('UPDATE_ZIP_CODE_VALID', valid);
        commit('UPDATE_USER_INFO_BY_KEY', { key: 'city', value: city });
      } catch (error) {
        commit('UPDATE_ZIP_CODE_API_STATUS', false);
        commit('UPDATE_ZIP_CODE_VALID', true);
      }
    },
  },
};

export default BringStore;
