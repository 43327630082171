// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto';
import '@/polyfills/closest';
import Vue from 'vue';
import Vuex from 'vuex';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueYoutube from 'vue-youtube';
import Meta from 'vue-meta';
import VueProgressBar from 'vue-progressbar';
import AsyncComputed from 'vue-async-computed';
import { sync } from 'vuex-router-sync';
import i18n from '@/setup/i18n-init';
import filters from '@/filters/general-filters';
import Tracking from '@/tracking/tracking';
import initializeDatadog from '@/tracking/datadog';

const defaultProgressBarOptions = {
  color: '#00287E',
  failedColor: '#000',
  thickness: '3px',
  transition: {
    speed: '1s',
    opacity: '0.6s',
    termination: 500,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
};

const defaultAppOptions = {
  useDealers: true,
};

export default async function bootstrap({
  App,
  store,
  router,
  progressbarOptions = defaultProgressBarOptions,
  appOptions = defaultAppOptions,
} = {}) {
  const appEnvOptions = { ...defaultAppOptions, ...appOptions };
  const vueProgressBarOptions = { ...defaultProgressBarOptions, ...progressbarOptions };

  Vue.config.productionTip = false;
  Vue.use(Vuex);
  Vue.use(Meta);
  Vue.use(AsyncComputed);
  Vue.use(VueYoutube);
  Vue.use(VueProgressBar, vueProgressBarOptions);
  Vue.use(VueDOMPurifyHTML, {
    default: {
      ADD_ATTR: ['target'],
    },
  });
  Vue.use(Tracking, {});
  sync(store, router);

  Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
  });

  await store.dispatch('initAppEnvironment', appEnvOptions);

  initializeDatadog();

  /* eslint-disable-next-line no-new */
  new Vue({
    i18n,
    el: '#app',
    router,
    store,
    components: {
      App,
    },
    template: '<App/>',
  });
}
