export const getClientRouter = () => new Promise((resolve) => {
  /* eslint-disable-next-line prefer-template */
  import('@/clients/' + process.env.client + '/' + process.env.client + '-router.js')
    .then(({ default: router }) => {
      resolve(router);
    });
});

export const getClientStore = () => new Promise((resolve) => {
  /* eslint-disable-next-line prefer-template */
  import('@/clients/' + process.env.client + '/' + process.env.client + '-store.js')
    .then(({ default: store }) => {
      resolve(store);
    });
});
