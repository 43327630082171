import isElement from 'lodash/isElement';
import { responsiveBg } from '@/assets/js/bricks/responsive-bg';
import { fullSlideshow } from '@/assets/js/bricks/full-slideshow';
import { articleGrid } from '@/assets/js/bricks/article-grid';
import { featureTabsScripts } from '@/assets/js/bricks/feature-tabs-carousel';
import { ctaScripts } from '@/assets/js/bricks/cta-block';
import { accordionScripts } from '@/assets/js/bricks/accordion';
import { formatNumber } from '@/assets/js/helpers/general-helpers';
import { FINANCING_OPTIONS, LOAN_KEYS, LEASING_KEYS } from '@/constants/constants-financing';
import VueTypes from 'vue-types';

// CMS related
export function getBrickScripts(element) {
  if (isElement(element)) {
    const brickScripts = {
      responsiveBg,
      fullSlideshow,
      articleGrid,
      featureTabsScripts,
      ctaScripts,
      accordionScripts,
    };
    const bricks = element.querySelectorAll('[data-vue-script]');
    const scriptList = [];

    if (bricks.length) {
      [...bricks].forEach((brick) => {
        const tempList = brick.dataset.vueScript.split(',');
        tempList.forEach((scriptName) => {
          const tempName = scriptName.trim();
          if (!scriptList.includes(tempName)) {
            scriptList.push(tempName);
            brickScripts[tempName]();
          }
        });
      });
    }
  }
}

export function parseTotalCostVariables(text = '', totalCostGetter = () => {}, marketModelId = undefined) {
  if (!text) return '';

  const estimatedCash = formatNumber(
    totalCostGetter({ marketModelId, financingOptionType: FINANCING_OPTIONS.cash }),
  );
  const estimatedLoan = formatNumber(
    totalCostGetter({ marketModelId, financingOptionType: FINANCING_OPTIONS.loan }),
  );
  const estimatedLeasing = formatNumber(
    totalCostGetter({ marketModelId, financingOptionType: FINANCING_OPTIONS.leasing }),
  );

  return text
    .replace(/{{TOTAL_CASH}}/, estimatedCash)
    .replace(/{{TOTAL_LOAN}}/, estimatedLoan)
    .replace(/{{TOTAL_LEASING}}/, estimatedLeasing);
}

export function formatLeasingValues({ leasingValues = {}, suffixes = {} } = {}) {
  return {
    [LEASING_KEYS.advanceRent]: `${formatNumber(leasingValues.ADVANCE_RENT)} ${suffixes.currency}`,
    [LEASING_KEYS.establishmentFee]: `${formatNumber(leasingValues.ESTABLISHMENT_FEE)} ${suffixes.currency}`,
    [LEASING_KEYS.perTermFee]: `${formatNumber(leasingValues.PER_TERM_FEE)} ${suffixes.currency}`,
    [LEASING_KEYS.advanceRentEstablishmentFee]: `${formatNumber(leasingValues.ADVANCE_RENT_ESTABLISHMENT_FEE)} ${suffixes.currency}`,
    [LEASING_KEYS.totalCost]: `${formatNumber(leasingValues.TOTAL_COST)} ${suffixes.currency}`,
    [LEASING_KEYS.leasePeriod]: `${leasingValues.LEASE_PERIOD} ${suffixes.year}`,
    [LEASING_KEYS.annualMileage]: `${formatNumber(leasingValues.ANNUAL_MILEAGE)} ${suffixes.distance}`,
    [LEASING_KEYS.totalMileage]: `${formatNumber(leasingValues.TOTAL_MILEAGE)} ${suffixes.distance}`,
  };
}

export function parseLeasingVariables({ text = '', leasingValues = {}, suffixes = {} } = {}) {
  if (typeof text !== 'string') return '';

  const formatted = formatLeasingValues({ leasingValues, suffixes });

  return text
    .replace(new RegExp(`{{${LEASING_KEYS.advanceRent}}}`), formatted.ADVANCE_RENT)
    .replace(new RegExp(`{{${LEASING_KEYS.establishmentFee}}}`), formatted.ESTABLISHMENT_FEE)
    .replace(new RegExp(`{{${LEASING_KEYS.perTermFee}}}`), formatted.PER_TERM_FEE)
    .replace(new RegExp(`{{${LEASING_KEYS.advanceRentEstablishmentFee}}}`), formatted.ADVANCE_RENT_ESTABLISHMENT_FEE)
    .replace(new RegExp(`{{${LEASING_KEYS.totalCost}}}`), formatted.TOTAL_COST)
    .replace(new RegExp(`{{${LEASING_KEYS.leasePeriod}}}`), formatted.LEASE_PERIOD)
    .replace(new RegExp(`{{${LEASING_KEYS.annualMileage}}}`), formatted.ANNUAL_MILEAGE)
    .replace(new RegExp(`{{${LEASING_KEYS.totalMileage}}}`), formatted.TOTAL_MILEAGE);
}

export function formatLoanValues({ loanValues = {}, suffixes = {} } = {}) {
  return {
    [LOAN_KEYS.downPayment]: `${formatNumber(loanValues.DOWN_PAYMENT)} ${suffixes.currency}`,
    [LOAN_KEYS.downPaymentPercent]: `${loanValues.DOWN_PAYMENT_PERCENT}${suffixes.percent}`,
    [LOAN_KEYS.establishmentFee]: `${formatNumber(loanValues.ESTABLISHMENT_FEE)} ${suffixes.currency}`,
    [LOAN_KEYS.propertyRegistrationFee]: `${formatNumber(loanValues.PROPERTY_REGISTRATION_FEE)} ${suffixes.currency}`,
    [LOAN_KEYS.perTermFee]: `${formatNumber(loanValues.PER_TERM_FEE)} ${suffixes.currency}`,
    [LOAN_KEYS.loanPeriod]: `${loanValues.LOAN_PERIOD} ${suffixes.year}`,
    [LOAN_KEYS.loanAmount]: `${formatNumber(loanValues.LOAN_AMOUNT)} ${suffixes.currency}`,
    [LOAN_KEYS.loanNeeds]: `${formatNumber(loanValues.LOAN_NEEDS)} ${suffixes.currency}`,
    [LOAN_KEYS.totalLoanCost]: `${formatNumber(loanValues.TOTAL_LOAN_COST)} ${suffixes.currency}`,
    [LOAN_KEYS.totalCost]: `${formatNumber(loanValues.TOTAL_COST)} ${suffixes.currency}`,
    [LOAN_KEYS.nominalInterestRate]: `${loanValues.NOMINAL_INTEREST_RATE}${suffixes.percent}`,
    [LOAN_KEYS.effectiveInterestRate]: `${loanValues.EFFECTIVE_INTEREST_RATE}${suffixes.percent}`,
  };
}

export function parseLoanVariables({ text = '', loanValues = {}, suffixes = {} } = {}) {
  if (typeof text !== 'string') return '';

  const formatted = formatLoanValues({ loanValues, suffixes });

  return text
    .replace(new RegExp(`{{${LOAN_KEYS.downPayment}}}`), formatted.DOWN_PAYMENT)
    .replace(new RegExp(`{{${LOAN_KEYS.downPaymentPercent}}}`), formatted.DOWN_PAYMENT_PERCENT)
    .replace(new RegExp(`{{${LOAN_KEYS.establishmentFee}}}`), formatted.ESTABLISHMENT_FEE)
    .replace(new RegExp(`{{${LOAN_KEYS.propertyRegistrationFee}}}`), formatted.PROPERTY_REGISTRATION_FEE)
    .replace(new RegExp(`{{${LOAN_KEYS.perTermFee}}}`), formatted.PER_TERM_FEE)
    .replace(new RegExp(`{{${LOAN_KEYS.loanPeriod}}}`), formatted.LOAN_PERIOD)
    .replace(new RegExp(`{{${LOAN_KEYS.loanAmount}}}`), formatted.LOAN_AMOUNT)
    .replace(new RegExp(`{{${LOAN_KEYS.loanNeeds}}}`), formatted.LOAN_NEEDS)
    .replace(new RegExp(`{{${LOAN_KEYS.totalLoanCost}}}`), formatted.TOTAL_LOAN_COST)
    .replace(new RegExp(`{{${LOAN_KEYS.totalCost}}}`), formatted.TOTAL_COST)
    .replace(new RegExp(`{{${LOAN_KEYS.nominalInterestRate}}}`), formatted.NOMINAL_INTEREST_RATE)
    .replace(new RegExp(`{{${LOAN_KEYS.effectiveInterestRate}}}`), formatted.EFFECTIVE_INTEREST_RATE);
}

export function isNullable(propType) {
  return VueTypes.oneOfType([
    propType,
    { type: null, validator: (value) => value === null },
  ]);
}

export const CMS_MODAL_CONTENT_ITEM_PROP = VueTypes.shape({
  title: isNullable(String).def(null),
  text: isNullable(String).def(null),
  wysiwyg: isNullable(String).def(null),
  images: VueTypes.arrayOf(VueTypes.string).def([]),
  youtube: isNullable(String).def(null),
  disclaimer: isNullable(String).def(null),
}).loose;

export const CMS_MODAL_CONTENT_ARRAY_PROP = VueTypes.arrayOf(isNullable(CMS_MODAL_CONTENT_ITEM_PROP)).def(() => []);

export const MARKET_MODEL_HIGHLIGHTED_FEATURE_PROP = VueTypes.shape({
  key: VueTypes.string.isRequired,
  name: isNullable(String).def(null),
  description: isNullable(String).def(null),
  modalContent: isNullable(CMS_MODAL_CONTENT_ITEM_PROP),
}).loose;

export const MARKET_MODEL_HIGHLIGHTED_FEATURE_ARRAY_PROP = VueTypes.arrayOf(isNullable(MARKET_MODEL_HIGHLIGHTED_FEATURE_PROP)).def(() => []);
