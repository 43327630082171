import isEmpty from 'lodash/isEmpty';
import { CAR_ATTRIBUTES } from '@/constants/constants-car-attributes';
import { formatNumber, roundNumber } from '@/assets/js/helpers/general-helpers';

// @todo Labels and suffixes should probably be translations but...
export const CAR_BUY_CAR_ATTRIBUTE_LABELS = {
  [CAR_ATTRIBUTES.baseColorCode]: 'Farge',
  [CAR_ATTRIBUTES.brand]: 'Merke',
  [CAR_ATTRIBUTES.campaignPrice]: 'Campaign',
  [CAR_ATTRIBUTES.carBody]: 'Karosseri',
  [CAR_ATTRIBUTES.carWarranty]: 'Garanti',
  [CAR_ATTRIBUTES.cargoVolume]: 'Str. Lasterom',
  [CAR_ATTRIBUTES.chassisNumber]: 'Chassinummer',
  [CAR_ATTRIBUTES.co2]: 'CO2-utslipp',
  [CAR_ATTRIBUTES.color]: 'Farge',
  [CAR_ATTRIBUTES.curbWeight]: 'Vekt',
  [CAR_ATTRIBUTES.departmentName]: 'Forhandler',
  [CAR_ATTRIBUTES.doors]: 'Antall dører',
  [CAR_ATTRIBUTES.driveWheel]: 'Hjuldrift',
  [CAR_ATTRIBUTES.enginePowerHp]: 'Effekt (Hk)',
  [CAR_ATTRIBUTES.enginePowerKw]: 'Effekt (kW)',
  [CAR_ATTRIBUTES.engineVolumeCcm]: 'Motorvolum',
  [CAR_ATTRIBUTES.engineVolumeLiter]: 'Sylindervolum',
  [CAR_ATTRIBUTES.firstTimeRegistered]: '1.gang registrert',
  [CAR_ATTRIBUTES.fuel]: 'Drivstoff',
  [CAR_ATTRIBUTES.hasTwoSetOfWheels]: 'To hjulsett',
  [CAR_ATTRIBUTES.interior]: 'Interiørfarge',
  [CAR_ATTRIBUTES.lastModifiedOn]: 'Sist oppdatert',
  [CAR_ATTRIBUTES.mileage]: 'Km.stand',
  [CAR_ATTRIBUTES.modelYear]: 'Årsmodell',
  [CAR_ATTRIBUTES.model]: 'Modell',
  [CAR_ATTRIBUTES.price]: 'Bilpris',
  [CAR_ATTRIBUTES.registrationFee]: 'Omregistrering',
  [CAR_ATTRIBUTES.registrationNumber]: 'Reg.nr.',
  [CAR_ATTRIBUTES.seats]: 'Antall seter',
  [CAR_ATTRIBUTES.transmission]: 'Girkasse',
  [CAR_ATTRIBUTES.variant]: 'Variant',
  [CAR_ATTRIBUTES.vehicleGroup]: 'Avgiftsklasse',
  [CAR_ATTRIBUTES.warrantyExpireDate]: 'Utløpsdato garanti',
  [CAR_ATTRIBUTES.warrantyMileage]: 'Utløpsdato garanti',
};

export const CAR_BUY_CAR_ATTRIBUTE_SUFFIXES = {
  [CAR_ATTRIBUTES.campaignPrice]: ',-',
  [CAR_ATTRIBUTES.cargoVolume]: ' l',
  [CAR_ATTRIBUTES.co2]: ' g/km',
  [CAR_ATTRIBUTES.curbWeight]: ' kg',
  [CAR_ATTRIBUTES.engineVolumeCcm]: ' cm³',
  [CAR_ATTRIBUTES.enginePowerHp]: ' Hk',
  [CAR_ATTRIBUTES.enginePowerKw]: ' kW',
  [CAR_ATTRIBUTES.engineVolumeLiter]: ' l',
  [CAR_ATTRIBUTES.mileage]: ' km',
  [CAR_ATTRIBUTES.price]: ',-',
  [CAR_ATTRIBUTES.registrationFee]: ',-',
  [CAR_ATTRIBUTES.warrantyMileage]: ' km',
};

export const CAR_BUY_CAR_ATTRIBUTE_PRICE_FORMAT = [
  CAR_ATTRIBUTES.campaignPrice,
  CAR_ATTRIBUTES.price,
];

export const CAR_BUY_CAR_ATTRIBUTE_NUMBER_FORMAT = [
  CAR_ATTRIBUTES.cargoVolume,
  CAR_ATTRIBUTES.co2,
  CAR_ATTRIBUTES.curbWeight,
  CAR_ATTRIBUTES.enginePowerHp,
  CAR_ATTRIBUTES.enginePowerKw,
  CAR_ATTRIBUTES.engineVolumeCcm,
  CAR_ATTRIBUTES.engineVolumeLiter,
  CAR_ATTRIBUTES.mileage,
  CAR_ATTRIBUTES.registrationFee,
  CAR_ATTRIBUTES.warrantyMileage,
];

export const CAR_BUY_CAR_ATTRIBUTE_DATE_FORMAT = [
  CAR_ATTRIBUTES.firstTimeRegOriginationCountry,
  CAR_ATTRIBUTES.firstTimeRegistered,
  CAR_ATTRIBUTES.lastModifiedOn,
  CAR_ATTRIBUTES.lastRegistrationDate,
  CAR_ATTRIBUTES.warrantyExpireDate,
];

export const CAR_BUY_CAR_ATTRIBUTE_BOOLEAN_FORMAT = [
  CAR_ATTRIBUTES.hasParticleFilter,
  CAR_ATTRIBUTES.hasTwoSetOfWheels,
  CAR_ATTRIBUTES.isCommissionCar,
  CAR_ATTRIBUTES.isDemoCar,
  CAR_ATTRIBUTES.isImportedUsed,
  CAR_ATTRIBUTES.isNewCar,
  CAR_ATTRIBUTES.isRegistrationFeeIncluded,
];

const isCarBuyCarPriceAttribute = (
  attributeKey,
) => CAR_BUY_CAR_ATTRIBUTE_PRICE_FORMAT.includes(attributeKey);

const isCarBuyCarNumberAttribute = (
  attributeKey,
) => CAR_BUY_CAR_ATTRIBUTE_NUMBER_FORMAT.includes(attributeKey);

const isCarBuyCarDateAttribute = (
  attributeKey,
) => CAR_BUY_CAR_ATTRIBUTE_DATE_FORMAT.includes(attributeKey);

const isCarBuyCarBooleanAttribute = (
  attributeKey,
) => CAR_BUY_CAR_ATTRIBUTE_BOOLEAN_FORMAT.includes(attributeKey);

export const formatCarBuyCarPrice = (price = 0, allowZero = false) => {
  if (!allowZero && price === 0) return 'N/A';
  return formatNumber(price);
};

export const formatCarBuyCarDate = (dateEpoch = 0) => {
  if (dateEpoch === 0) return 'N/A';
  const dateObj = new Date(dateEpoch * 1000);
  return dateObj.toISOString().slice(0, 10);
};

export const formatCarBuyCarBoolean = (value = false) => (value ? 'Ja' : 'Nei');

export const formatCarBuyDepartmentName = (departmentName = '') => departmentName.split('.')[0];

export const formatCarBuyAttribute = (key, value, allowZero = false) => {
  const shouldFormat = (x) => x !== undefined;
  let formattedValue = value === undefined ? 'N/A' : value;

  if (isCarBuyCarPriceAttribute(key) && shouldFormat(value)) {
    formattedValue = formatCarBuyCarPrice(value, allowZero);
  }
  if (isCarBuyCarNumberAttribute(key) && shouldFormat(value)) {
    formattedValue = formatNumber(value);
  }
  if (isCarBuyCarDateAttribute(key) && shouldFormat(value)) {
    formattedValue = formatCarBuyCarDate(value);
  }
  if (isCarBuyCarBooleanAttribute(key) && shouldFormat(value)) {
    formattedValue = formatCarBuyCarBoolean(value);
  }

  if (key === CAR_ATTRIBUTES.departmentName) {
    formattedValue = formatCarBuyDepartmentName(formattedValue);
  }

  return formattedValue;
};

export const getCarBuyCarAttributeLabel = (attributeKey) => {
  const label = CAR_BUY_CAR_ATTRIBUTE_LABELS[attributeKey];
  return label || attributeKey;
};

export const getCarBuyCarAttributeSuffix = (attributeKey) => {
  const suffix = CAR_BUY_CAR_ATTRIBUTE_SUFFIXES[attributeKey];
  return suffix || '';
};

export const getCarBuyCarAttributes = (
  carBuyCar = {},
  attributeKeys = [],
  tightFormat = false,
) => attributeKeys.reduce((attributes, key) => {
  if (carBuyCar[key]) {
    const value = carBuyCar[key];

    let formattedValue = formatCarBuyAttribute(key, value);

    if (tightFormat && key === CAR_ATTRIBUTES.driveWheel) {
      formattedValue = formattedValue.replace('sdrift', '');
    }

    if (key === CAR_ATTRIBUTES.fuel) {
      formattedValue = formattedValue.replace('/', ' / ');
    }

    formattedValue = formattedValue !== 'N/A'
      ? `${formattedValue}${getCarBuyCarAttributeSuffix(key)}`
      : formattedValue;

    const label = getCarBuyCarAttributeLabel(key);
    attributes.push({ key, label, value: formattedValue });
  }
  return attributes;
}, []);

export const getCarBuyCarShortProspectHeading = (carBuyCar = {}) => {
  const {
    brand = '', model = '', modelYear = '', prospectHeading = '',
  } = carBuyCar;

  if (!brand || !model || !modelYear) return prospectHeading;
  const regex = new RegExp(`\\b${brand}|${model}|${modelYear}\\b`, 'g');
  return prospectHeading.replace(regex, '');
};

export const getCarBuyCarCampaign = ({ carBuyCar = {}, showAsterisk = true } = {}) => {
  const { campaign } = carBuyCar;
  if (!campaign) return null;

  const { interestRate, disclaimer } = campaign;
  if (!interestRate) return null;

  const asterisk = showAsterisk && !!disclaimer ? '*' : '';

  let rate = roundNumber(interestRate, 2);
  rate = rate.toString().replace('.', ',');

  return {
    rate: `${rate}%${asterisk}`,
    disclaimer: disclaimer ? `${asterisk}${disclaimer}` : null,
  };
};

export const carBuyFilterQuery = {
  queryParam: 'q',
  searchParams() {
    return new URLSearchParams(window.location.search);
  },
  hasFilter() {
    return this.searchParams().has(this.queryParam);
  },
  getFilterQuery() {
    return JSON.parse(this.searchParams().get(this.queryParam)) || {};
  },
  hasQuery() {
    return [...this.searchParams()].length;
  },
  setQuery(filterObj = {}) {
    const searchParams = this.searchParams();
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    let query = '';

    searchParams.delete(this.queryParam);

    if (!isEmpty(filterObj)) {
      searchParams.append(this.queryParam, JSON.stringify(filterObj));
    }

    if ([...searchParams].length) {
      query = `?${searchParams.toString()}`;
    }

    const path = `${url}${query}`;
    window.history.replaceState({ path }, '', path);
  },
};
