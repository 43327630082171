import httpInternal from '@/http/httpInternal';
import get from 'lodash/get';
import { scrollToTop } from '@/assets/js/helpers/general-helpers';

const NetsStore = {
  actions: {
    async netsRegisterPayment({ getters, dispatch, commit }, { setAlert = true } = {}) {
      let response;
      try {
        response = await httpInternal(
          {
            method: 'post',
            url: getters.apiNetsRegisterUrl,
            data: {
              userData: {
                firstName: getters.userInfo.firstName,
                lastName: getters.userInfo.lastName,
                mobileNumber: getters.userInfo.mobileNumber,
                email: getters.userInfo.email,
                userUuid: getters.userInfo.userUuid,
                addressCountry: getters.userInfo.countryCode,
                countryCode: getters.userInfo.countryCode,
                zipCode: getters.userInfo.zipCode,
                city: getters.userInfo.city,
                addressLine: getters.userInfo.address,
              },
              companyInfo: {
                companyName: getters.user.isCompanyCustomer
                  ? getters.companyInfo.companyName
                  : null,
                orgNumber: getters.user.isCompanyCustomer
                  ? getters.companyInfo.orgNumber
                  : null,
              },
              orderLines: getters.cartItems,
              selectedDealer: getters.selectedDealer,
              brandConsent: getters.userAgreements.brandConsent,
              modelConsent: getters.userAgreements.modelConsent,
            },
          },
        );
      } catch (error) {
        const { msg: log } = error.response.data;

        if (error.response.status === 403) {
          commit('UPDATE_CHECKOUT_OPEN', false);
          dispatch('resetCheckout', false);
          scrollToTop();
          if (setAlert) dispatch('setAlert', { key: 'netsRegisterError', message: 'error.reservationClosed', log });
        } else if (setAlert) {
          dispatch('setAlert', { key: 'netsRegisterError', message: 'error.netsRegister', log });
        }
        return false;
      }

      if (!response.data.transactionId) {
        if (setAlert) dispatch('setAlert', { key: 'registerError', message: 'error.netsRegister' });
        return false;
      }

      return response;
    },
    async netsProcessPayment({ getters, dispatch }, transactionId) {
      let response;
      try {
        response = await httpInternal(
          {
            method: 'post',
            url: getters.apiNetsProcessUrl,
            data: {
              transactionId,
            },
          },
        );
      } catch (error) {
        dispatch('setAlert', { key: 'netsProcessError', message: 'error.netsProcessError', log: error.message });
        throw error;
      }
      if (!get(response, 'data.orderData')) {
        dispatch('setAlert', { key: 'netsError', message: 'error.netsProcessError', log: response.data.msg });
        throw new Error('No order data');
      }
      return response.data;
    },
    async netsCancelPayment({ getters, dispatch }, transactionId) {
      try {
        const response = await httpInternal(
          {
            method: 'post',
            url: getters.apiNetsCancelUrl,
            data: {
              transactionId,
            },
          },
        );
        if (!response.data) {
          dispatch('setAlert', { key: 'netsCancelError', message: 'error.netsCancel', log: response.msg });
          return false;
        }
        return response.data;
      } catch (error) {
        dispatch('setAlert', { key: 'netsCancelError', message: 'error.netsCancel', log: error.message });
        return false;
      }
    },
  },
};

export default NetsStore;
