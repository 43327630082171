import Vue from 'vue';

const SmartDigitalTracking = Vue.extend({
  data() {
    return {
      bootstrapperLoaded: -1,
    };
  },
  methods: {
    async trackEvent(event, eventData) {
      const bootstrapperLoaded = await this.loadBootstrapper();
      if (!bootstrapperLoaded) {
        return;
      }

      let eventName = event;
      window.Bootstrapper[this.options.tenant] = {};

      if (event === 'PageLoad') {
        if (eventData.checkoutSteps
            && eventData.checkoutSteps.indexOf(eventData.currentCheckoutStep) === 1) {
          eventName = 'VWPreB_Form_Pageload';
        } else {
          eventName = 'VWPreB_Pageload';
          if (!eventData.checkoutSteps) {
            this.trackNonVueButtonClicks('a.ui-button');
          }
        }
      } else if (event === 'OTPSend') {
        eventName = 'VWPreB_CTA_Click';
      } else if (event === 'OTPVerify') {
        eventName = 'VWPreB_CTA_Click';
      } else if (event === 'CancelCheckout') {
        eventName = 'VWPreB_CTA_Click';
      } else if (event === 'Payment') {
        eventName = 'VWPreB_FormCTA_Click';
      } else if (event === 'CheckoutComplete') {
        eventName = 'VWPreB_FormSubmissionSuccess_Pageload';
        this.trackNonVueButtonClicks('.success-top', Object.assign(eventData, {
          path: '/checkout/confirmation',
          contentId: parseInt('checconfirm', 36),
        }));
      }

      this.setNgwEnvironment();
      this.setNgwUserData(eventName, eventData);
      this.setNgwLinkData(eventName, eventData);
      this.setNgwFormData(eventName, eventData);
      this.setNgwConfigurationData(eventName, eventData);
      this.setNgwPartnerData(eventName, eventData);
      this.setNgwVehicleData(eventName, eventData);
      this.setNgwFinancingData(eventName, eventData);

      this.setNgwEventInfo(eventName, eventData);

      window.Bootstrapper.ensEvent.trigger(eventName);
    },
    setNgwEventInfo(event, eventData) {
      let { path, title } = eventData;
      if (eventData.otpEvent) {
        path = '/checkout/login';
        title = 'Checkout : login';
      } else if (event.match(/FormCTA/) || eventData.formCancel) {
        path = '/checkout/information';
        title = 'Checkout : information';
      }

      if (window.location.pathname === '/') {
        title = 'Home';
      }

      const eventInfo = {
        eventAction: event,
        pageId: window.location.hostname + (path || ''),
      };

      const pageName = title
        ? title.replace(/.*?\| /, '').replace(/-/g, ':') : undefined;

      const contentId = (eventData && eventData.contentId)
        ? eventData.contentId.toString() : undefined;

      const contentTags = (eventData && eventData.contentTags)
        ? eventData.contentTags : undefined;

      eventInfo.pageName = pageName;

      if (/cta/i.test(event)) {
        eventInfo.contentID = contentId;
      }
      eventInfo.contentTags = contentTags;

      window.Bootstrapper[this.options.tenant].ngw_eventInfo = eventInfo;
    },

    setNgwLinkData(event, eventData) {
      if (/CTA/i.test(event)) {
        const { linkName } = eventData;
        let { linkUrl } = eventData;

        if (event === 'VWPreB_FormCTA_Click') {
          linkUrl = 'https://epayment.nets.eu/terminal/default.aspx';
        }

        window.Bootstrapper[this.options.tenant].ngw_link = {
          Url: linkUrl,
          Name: linkName,
        };
      }
    },

    setNgwFormData(event, eventData) {
      if (/form/i.test(event)) {
        const ngwFormData = {
          FormType: 'prebooking',
          FormName: 'prebooking',
          FormVersion: eventData.formVersion ? eventData.formVersion : '1',
        };

        if (event === 'VWPreB_Form_Pageload') {
          const formStart = !document.cookie.match(/FormLoaded/);
          document.cookie = 'FormLoaded=1;';
          ngwFormData.FormStart = formStart;
        }

        window.Bootstrapper[this.options.tenant].ngw_form = ngwFormData;
      }

      if (/FormSubmission/i.test(event)) {
        window.Bootstrapper[this.options.tenant].ngw_form.TextFieldValue = [
          {
            'form field id': 'clientType',
            'form field label': eventData.companyName ? 'fleet' : 'private',
          },
          {
            'form field id': 'companyName',
            'form field label': eventData.companyName ? eventData.companyName : undefined,
          },
          {
            'form field id': 'ZIP',
            'form field label': eventData.userZip,
          },
        ];

        window.Bootstrapper[this.options.tenant].ngw_form.MarketingConsent = [{
          general: `${eventData.userAgreements.brandConsent}`,
          newsletter: `${eventData.userAgreements.modelConsent}`,
        }];

        window.Bootstrapper[this.options.tenant].ngw_form.LeadID = `NOR-${eventData.orderData.orderId}`;
      }

      if (/FormCTA/i.test(event)) {
        window.Bootstrapper[this.options.tenant].ngw_form.Questionnaire = undefined;
      }
    },

    setNgwConfigurationData(event, eventData) {
      if (/form/i.test(event)) {
        window.Bootstrapper[this.options.tenant].ngw_configuration = {
          PriceRateCurrency: 'NOK',
          PriceTotalTotal: eventData.totalPrice ? eventData.totalPrice.toString() : undefined,
        };
      }
    },
    setNgwPartnerData(event, eventData) {
      if (/FormCTA|FormSubmission/i.test(event)) {
        const dealer = eventData.dealer[0];
        window.Bootstrapper[this.options.tenant].ngw_partner = {
          InformationBnr: dealer.dealerNumber,
          InformationName: dealer.dealerName,
          InformationZIP: dealer.zipCode,
          InformationDepartment: 'sales',
        };
      }
    },

    setNgwVehicleData(event, eventData) {
      const { carlineName, carlineId } = eventData;
      window.Bootstrapper[this.options.tenant].ngw_vehicleContent = {
        CarlineName: carlineName,
        CarlineId: carlineId,
      };
    },

    setNgwFinancingData(event, eventData) {
      if (/FormCTA|FormSubmission/i.test(event)) {
        window.Bootstrapper[this.options.tenant].ngw_calculation = {
          FinancingPaymentMethod: eventData.paymentMethod ? eventData.paymentMethod : 'Nets',
        };
      }
    },

    setNgwEnvironment() {
      window.Bootstrapper[this.options.tenant].ngw_environment = {
        ApplicationID: this.options.applicationId,
        Language: this.options.trackingLanguage,
        Country: this.options.trackingCountry,
      };
    },

    setNgwUserData(event, eventData) {
      const breakpoints = [320, 480, 640, 768, 1024, 1280, 1920];
      let currentBreakpoint = 1920;

      breakpoints.forEach((bp, i) => {
        if (window.innerWidth >= breakpoints[i]) {
          currentBreakpoint = bp;
        }
      });

      window.Bootstrapper[this.options.tenant].ngw_user = {
        BrowserResolutionHeight: window.innerHeight.toString(),
        BrowserResolutionWidth: window.innerWidth.toString(),
        BrowserResolutionBreakpoint: currentBreakpoint.toString(),
        BrowserResolutionOrientation: window.innerWidth >= window.innerHeight ? 'h' : 'v',
        LocationZIP: eventData.userZip ? eventData.userZip : undefined,
        LocationLatitude: undefined,
        LocationLongitude: undefined,
      };
    },

    async loadBootstrapper() {
      return new Promise((resolve) => {
        if (this.bootstrapperLoaded === 1) {
          resolve(true);
          return;
        }

        if (this.bootstrapperLoaded === 0) {
          resolve(false);
          return;
        }

        if (this.options.bootstrapperUrl) {
          const bootstrapper = document.createElement('script');
          bootstrapper.src = this.options.bootstrapperUrl;

          bootstrapper.onload = () => {
            this.bootstrapperLoaded = 1;
            resolve(true);
          };

          bootstrapper.onerror = () => {
            this.bootstrapperLoaded = 0;
            resolve(false);
          };

          document.head.appendChild(bootstrapper);
        } else {
          window.Bootstrapper = {};
          window.Bootstrapper[this.options.tenant] = {};
          window.Bootstrapper.ensEvent = {
            trigger: window.console.log,
          };
          this.bootstrapperLoaded = 1;
          resolve(true);
        }
      });
    },
    trackNonVueButtonClicks(selector, eventData = {}) {
      [].slice.call(document.querySelectorAll(selector)).forEach((element) => {
        element.addEventListener('click', (event) => {
          const link = event.target;
          let { contentId } = eventData;

          let section = link;
          if (!eventData.contentId) {
            while (section && !section.className.match(/pimcore_area_/)) {
              section = section.parentElement;
            }
            contentId = section ? parseInt(section.className.replace(/pimcore_area_/, '').replace(/\W/g, '').slice(0, 11), 36) : undefined;
          }

          if (link.className.match(/ui-button/)) {
            this.trackEvent('VWPreB_CTA_Click', Object.assign(eventData, {
              linkUrl: link.href,
              linkName: link.innerText.trim(),
              path: eventData.path ? eventData.path : window.location.pathname,
              contentId,
            }));
          }
        });
      });
    },
  },
});

export default SmartDigitalTracking;
