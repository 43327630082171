import { bodyScroll, sleep } from '@/assets/js/helpers/general-helpers';

export function getModalCount() {
  const { body } = document;
  let modalCount = parseInt(body.getAttribute('data-modal-count'), 10);
  if (Number.isNaN(modalCount)) modalCount = 0;

  return modalCount;
}

export function setModalCount(count = 0) {
  const { body } = document;
  count > 0
    ? body.setAttribute('data-modal-count', `${count}`)
    : body.removeAttribute('data-modal-count');
}

export function increaseModalCount() {
  let modalCount = getModalCount();
  modalCount += 1;
  setModalCount(modalCount);
}

export function decreaseModalCount() {
  let modalCount = getModalCount();
  modalCount -= 1;
  setModalCount(modalCount);
}

export async function clearModalHooks(router) {
  const { beforeHooks } = router;

  let closedModalCount = 0;

  for (let i = beforeHooks.length; i >= 0;) {
    const hook = beforeHooks[i] ?? {};
    if (hook.modalId) {
      if (typeof hook.closeModalFunction === 'function') {
        hook.closeModalFunction();
        closedModalCount += 1;
      }
      beforeHooks.splice(i, 1);
    }
    i -= 1;
  }

  if (closedModalCount > 0) await sleep(300);

  bodyScroll.enable();
  setModalCount(0);
}
