import { render, staticRenderFns } from "./UiTooltip.vue?vue&type=template&id=a55a1946"
import script from "./UiTooltip.vue?vue&type=script&lang=js"
export * from "./UiTooltip.vue?vue&type=script&lang=js"
import style0 from "./uiTooltip.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./uiTooltip.audi.scss?vue&type=style&index=1&prod&lang=scss&theme=audi&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports