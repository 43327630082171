import { formatNumber, formatPhone } from '@/assets/js/helpers/general-helpers';

export default {
  capitalize: (value) => {
    if (!value) return '';
    const string = value.toString();
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  phone: (phone) => formatPhone(phone),
  number: (number) => formatNumber(number),
};
