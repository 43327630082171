/* eslint-disable */
import {mediaCheck} from '@/assets/js/vendor/matchMedia.js';

'use strict'
window.Seat = {};

Seat.initSticky = function (isDesktop) {

  //Header fix
  let pageWrapper = $('.page-wrapper');
  let pageHeader = pageWrapper.find('#app-header');
  pageHeader.addClass('can-stick')
  .addClass('hide-on-downscroll')
  .attr('data-sticky-order', '1')
  .attr('data-sticky-on-container-class', 'page-wrapper');

  let stickyCarInfo = pageWrapper.find('.sticky-configurable');
  let progressBar = (pageWrapper.find('.progress-wrapper').length) ? pageWrapper.find('.progress-wrapper') : pageWrapper.find('.progress-bar');

  // Sticky framework starts here
  let body = $('body');
  let scrollingDown = true;
  let lastScrollTop = 0;
  let allowStickyAfterScrolling = pageHeader.outerHeight();
  let stickyElementsAllowed = 2;
  let stickyElementsShown = 0;
  let stickySections = [];
  let stuckHeight = 0;
  let headers = [pageHeader];
  let stickyElements = $('.can-stick').sort(function (a, b) {
    let contentA = parseInt($(a).attr('data-sticky-order'));
    let contentB = parseInt($(b).attr('data-sticky-order'));
    return (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
  }).each(function (index) {
    $(this).attr('data-sticky-index', index + 1);
    if (getStickyOnContainerClass(($(this)))) {
      let stickySection = $("." + getStickyOnContainerClass(($(this))));
      if (stickySection.data('sticky-section-index')) {
        $(this).attr('data-sticky-index', stickySection.data('sticky-section-index'));
      }
      else {
        stickySection.attr('data-sticky-section-index', index + 1);
      }
      stickySections.push(stickySection);
    }
    if ($(this).attr('data-sticky-offset-top-class')) {
      $(this).data('sticky-offset-top', pageWrapper.find('.' + $(this).attr('data-sticky-offset-top-class')).outerHeight());
    }
    if ($(this).attr('data-sticky-is-header')) {
      headers.push($(this));
    }
  });

  // Checks if top of viewport is touching element
  function isInViewport(element) {

    if (element.length !== 1)
      return false;

    // Arg 2
    let offsetBefore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // Arg 3
    let stickyElement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    let top = element.offset().top;
    let left = element.offset().left;
    let width = element.width();
    let height = element.outerHeight();

    if (stickyElement) {
      let isStuck;
      stickyElement.attr('data-is-stuck') === 'true' ? isStuck = true : isStuck = false;
      if (isStuck) {
        top = top - stuckHeight;
      }
      else {
        top = top - stuckHeight - stickyElement.outerHeight();
      }
    }

    if (offsetBefore) {
      top -= offsetBefore;
    }

    return (
      top < window.pageYOffset &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
  }

  function moreStickyElementsAllowed() {
    return stickyElementsShown < stickyElementsAllowed;
  }

  function getStickyOnContainerClass(element) {
    return $(element).attr('data-sticky-on-container-class');
  }

  function unStickElement(element) {
    element.data('top', -element.outerHeight());
    stickyElementsShown -= 1;
    if (stickyElementsShown < 0) stickyElementsShown = 0;
  }

  function changeBackground() {
    if (stickyCarInfo.length && parseInt(stickyCarInfo[0].style.top) > 0) {
      stickyCarInfo.addClass('beige-background');
    }
    else if (stickyCarInfo.length) {
      stickyCarInfo.removeClass('beige-background');
    }
    if (progressBar.length && parseInt(progressBar[0].style.top) > 0) {
      progressBar.addClass('beige-background');
    }
    else if (progressBar.length) {
      progressBar.removeClass('beige-background');
    }
  }

  function stuckElementsHeight() {
    stuckHeight = 0;
    stickyElements.each(function () {
      if ($(this).attr('data-is-stuck') === 'true') {
        stuckHeight += $(this).outerHeight();
      }
    });
  }

  let onScroll = function () {
    // Do nothing if scroll lock
    if (body.attr('data-scroll-lock-locked')) return;

    // Scrolldirection
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      scrollingDown = true;
    }
    else if (scrollTop == lastScrollTop) {
      // Do nothing if scrollTop and lastScrolltop is the same to prevent menu from showing up when you stop scrolling in IE 11
    }
    else {
      scrollingDown = false;
    }

    lastScrollTop = scrollTop;

    let headersHeight = 0;
    $(headers).each(function () {
      headersHeight += $(this).outerHeight();
    });
    pageWrapper.css('padding-top', headersHeight);
    if (scrollTop === 0) {
      pageHeader.attr('data-is-stuck', 'false');
      $(headers).each(function () {
        if ($(this).is(pageHeader)) {
          $(this).css('top', '-' + $(this).outerHeight() + 'px');
          $(this).removeClass('fixed');
        }
        else {
          $(this).css('top', 0);
          $(this).removeClass('fixed');
        }
      });
      pageWrapper.css('padding-top', 0);
      return;
    }
    else if (!scrollingDown && scrollTop < (pageHeader.outerHeight())) {
      if (pageHeader.attr('data-is-stuck') === 'false') {
        pageHeader.removeClass('fixed');
        pageWrapper.css('padding-top', 0);
      }
      else {
        pageHeader.addClass('fixed');
        pageWrapper.css('padding-top', headersHeight);
      }
      return;
    }
    else if (scrollTop < pageHeader.outerHeight()) {
      if (pageHeader.data('is-stuck') === 'false' || !pageHeader.data('is-stuck')) {
        $(headers).each(function () {
          $(this).removeClass('fixed');
          if ($(this).attr('data-sticky-always-visible')) {
            $(this).css('top', 0);
          }
          else {
            $(this).css('top', $(this.outerHeight()));
          }
        });
        pageWrapper.css('padding-top', 0);
      }
      return;
    }
    else if (pageHeader.css('position') !== 'fixed') {
      $(headers).each(function () {
        $(this).addClass('fixed');
      });
    }

    // Comment
    let zIndex = 1300;
    let topOffset = 0;

    //Remove all sticky elements
    stickyElements.each(function () {
      unStickElement($(this));
    });
    //Rearrange sticky elements
    stickyElements.each(function () {
      let stickySection = null;
      if ($(this).data('sticky-on-container-class')) {
        let index = $(this).data('sticky-index');
        $(stickySections).each(function () {
          if (parseInt(index) === parseInt($(this).data('sticky-section-index'))) {
            stickySection = $(this);
          }
        });
      }

      if (scrollTop < allowStickyAfterScrolling ||
        ($(this).hasClass('hide-on-downscroll') && scrollingDown) ||
        (!$(this).data('invert-sticky-on-container-class') && stickySection.length && !isInViewport(stickySection, $(this).data('sticky-offset-top'), $(this))) ||
        ($(this).data('invert-sticky-on-container-class') && stickySection.length && isInViewport(stickySection, $(this).data('sticky-offset-top'), $(this))) ||
        ($(this).data('sticky-disable-in-mobile') && !isDesktop) ||
        ($(this).data('sticky-disable-in-desktop') && isDesktop)
      ) {
        //dont show this sticky element
        return true;
      }

      // Show sticky element if possible
      if (
        moreStickyElementsAllowed()
      ) {
        if ($(this).is(pageHeader)) {
          $(this).css('z-index', zIndex + 200);
        }
        else {
          $(this).css('z-index', zIndex);
        }
        zIndex -= 100;
        $(this).data('top', topOffset);
        topOffset += $(this).outerHeight();
        stickyElementsShown += 1;
      }

    });

    //Move sticky elements
    stickyElements.each(function (index) {
      if ($(this).data("top") >= 0) {

        $(this).attr('data-is-stuck', true);
        $(this).addClass('fixed');
      }
      else {
        if (!$(this).is(pageHeader) && $(this).attr('data-is-stuck') === 'true') {
          let currentZ = $(this).css('z-index');
          $(this).css('z-index', (currentZ - 200));
        }
        $(this).attr('data-is-stuck', false);
        setTimeout(function () {
          $(this).removeClass('fixed');
        }, 300);
      }
      $(this).css('top', $(this).data("top"));
    });
    // Calculate height of stuck elements
    stuckElementsHeight();

    //configurale product sticky header background fix
    changeBackground();
  };

  $(document).ready(function ($) {
    // Run once to set up correctly on load
    onScroll();

    // Remove listener before adding it to ensure only one is active
    $(window).off('scroll.stickyhead');
    $(window).on('scroll.stickyhead', onScroll);
  });
};


function globalSticky() {
  // @todo @refactor STICKY GLOBAL Sticky global disabled for User tests
  // let isDesktop;
  //
  // $(document).ready(function ($) {
  //
  //   mediaCheck({
  //     media: '(min-width: 0px) and (max-width: 767px)',
  //     entry: function () {
  //       isDesktop = false;
  //       Seat.initSticky($, isDesktop);
  //     }
  //   });
  //
  //   mediaCheck({
  //     media: '(min-width: 768px) and (max-width: 1015px)',
  //     entry: function () {
  //       isDesktop = true;
  //       Seat.initSticky($, isDesktop);
  //     }
  //   });
  //
  //   mediaCheck({
  //     media: '(min-width: 1016px) and (max-width: 1435px)',
  //     entry: function () {
  //       isDesktop = true;
  //       Seat.initSticky($, isDesktop);
  //     }
  //   });
  //
  //   mediaCheck({
  //     media: '(min-width: 1436px)',
  //     entry: function () {
  //       isDesktop = true;
  //       Seat.initSticky($, isDesktop);
  //     }
  //   });
  //
  // });
}

export {globalSticky};
