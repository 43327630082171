import httpInternal from '@/http/httpInternal';
import get from 'lodash/get';
import consoleLog from '@/assets/js/helpers/console-log';
import { ROUTES } from '@/constants/constants-routes';

// eslint-disable-next-line no-unused-vars
let unwatchSelectedDealer;

const dealerDropdownMapper = (dealer, section, meta) => {
  const { dealerNumber: key, dealerName: label, deliveryCosts } = dealer || {};

  return {
    key,
    label,
    section,
    meta,
    mainTypes: Object.keys(deliveryCosts),
  };
};

const includeHiddenDealerCheck = (
  dealer = {},
  includedHiddenDealers = [],
) => !(dealer.hidden && !includedHiddenDealers.includes(dealer.dealerNumber));

const DealersStore = {
  state: {
    dealers: [],
    suggestedDealers: [],
    previousDealers: [],
    dealerRestrictions: [],
  },
  getters: {
    dealers: (state) => state.dealers,
    getDealer: (state) => (dealerNumber = '') => state.dealers.find((x) => x.dealerNumber === dealerNumber),
    getDealerByUrlKey: (state) => (urlKey = '') => state.dealers.find((x) => x.urlKey === urlKey),
    getDealerDropdownValues: (state, getters, rootState) => ({
      includedHiddenDealers = [],
    } = {}) => {
      if (state.dealerRestrictions.length > 0) {
        return state.dealers
          .filter((dealer) => state.dealerRestrictions.includes(dealer.dealerNumber))
          .map((dealer) => dealerDropdownMapper(dealer, 'all'));
      }

      const suggestedDealers = state.suggestedDealers
        .filter((dealer) => includeHiddenDealerCheck(dealer, includedHiddenDealers))
        .map((dealer) => dealerDropdownMapper(dealer, 'closest', `${dealer.distance} km`));

      const previousDealers = state.previousDealers
        .filter((dealer) => includeHiddenDealerCheck(dealer, includedHiddenDealers))
        .map((dealer) => dealerDropdownMapper(dealer, 'previous'));

      let preferredDealers = [];
      const scopeSalesRetailers = get(getters.userInfo, 'scopeSalesRetailers');
      if (scopeSalesRetailers) {
        preferredDealers = scopeSalesRetailers.map((dealer) => dealerDropdownMapper(dealer, 'preferred'));
      }

      const addedDealers = [...suggestedDealers, ...previousDealers, ...preferredDealers];
      const addedUniqueDealerNumbers = [...new Set(addedDealers.map((dealer) => dealer.key))];

      const otherDealers = state.dealers.reduce((uniqueDealers, dealer) => {
        if (!includeHiddenDealerCheck(dealer, includedHiddenDealers)) {
          return uniqueDealers;
        }

        if (!addedUniqueDealerNumbers.includes(dealer.dealerNumber)) {
          uniqueDealers.push(dealerDropdownMapper(dealer, 'all'));
        }

        return uniqueDealers;
      }, []);

      let dealerValues = [
        ...previousDealers, ...preferredDealers, ...suggestedDealers, ...otherDealers,
      ];

      if (rootState.route.name === ROUTES.configurator) {
        const { mainType } = getters.getModelById() || {};

        dealerValues = dealerValues.filter((dealerValue) => {
          const { mainTypes = [] } = dealerValue;
          return mainTypes.includes(mainType);
        });
      }

      return dealerValues;
    },
    uniqueDealerDropdownValues: (state, getters) => ({
      includedHiddenDealers = [],
    } = {}) => getters.getDealerDropdownValues({ includedHiddenDealers })
      .filter((dealer, index, self) => self.findIndex((x) => x.key === dealer.key) === index),
    uniqueDealersCount: (state, getters) => ({
      includedHiddenDealers = [],
    } = {}) => getters.uniqueDealerDropdownValues({ includedHiddenDealers })?.length ?? 0,
    dealerDropdownValue: (state, getters) => {
      const dealer = getters.getDealer(getters.selectedDealer);
      return {
        key: dealer ? dealer.dealerNumber : '',
        label: dealer ? dealer.dealerName : '',
      };
    },
    suggestedDealers: (state) => state.suggestedDealers,
    previousDealers: (state) => state.previousDealers,
    dealerRestrictions: (state) => state.dealerRestrictions || [],
  },
  mutations: {
    UPDATE_DEALERS(state, dealers = []) {
      state.dealers = dealers;
    },
    UPDATE_SUGGESTED_DEALERS(state, dealers = []) {
      state.suggestedDealers = dealers;
    },
    UPDATE_PREVIOUS_DEALERS(state, dealers = []) {
      state.previousDealers = dealers;
    },
    UPDATE_DEALER_RESTRICTIONS(state, dealerRestrictions = []) {
      state.dealerRestrictions = dealerRestrictions;
    },
  },
  actions: {
    async initDealers({ dispatch }) {
      await dispatch('fetchAndSetDealers');
      await dispatch('setDefaultDealer');

      // eslint-disable-next-line no-unused-vars
      unwatchSelectedDealer = this.watch(
        (storeState, storeGetters) => storeGetters.selectedDealer,
        () => dispatch('setDefaultDealer'),
      );
    },
    async setDefaultDealer({ getters, dispatch }) {
      if (!getters.user.loggedIn && !getters.selectedDealer && getters.getConfigByKeyString('dealer.preselectDealer')) {
        await dispatch('setSelectedDealer');
      }
    },
    async fetchAndSetDealers({ getters, commit }) {
      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetDealersUrl,
          },
        );
        if (response.data.loaded) {
          const { dealers } = response.data;
          commit('UPDATE_DEALERS', dealers);
        } else if (response.data.empty) {
          commit('UPDATE_DEALERS');
          consoleLog('No certified dealers');
        } else {
          consoleLog('Something went wrong when getting dealers');
        }
        return response;
      } catch (error) {
        consoleLog(`Something went wrong when getting dealers: ${error}`);
        return undefined;
      }
    },
    async fetchSuggestedDealersFromZip({ getters, commit }, zip = 0) {
      if (!zip) return undefined;

      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetSuggestedDealersFromZipUrl,
            params: {
              zip,
            },
          },
        );
        commit('UPDATE_SUGGESTED_DEALERS', response.data.dealers);
        return response.data.dealers;
      } catch (error) {
        consoleLog('Failed to get suggested dealers from zip', error);
        return undefined;
      }
    },
    async fetchPreviousDealersFromPhone({ getters, commit }, phone = 0) {
      if (!phone) return undefined;

      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetPreviousDealersFromPhoneUrl,
            params: {
              phone,
            },
          },
        );
        commit('UPDATE_PREVIOUS_DEALERS', response.data.dealers);
        return response.data.dealers;
      } catch (error) {
        consoleLog('Failed to get previous dealers from phone', error);
        return undefined;
      }
    },
    async setDealerRestrictionsByUrlKey({ getters, commit, dispatch }, urlKey = '') {
      const dealer = getters.getDealerByUrlKey(urlKey);
      if (!dealer) return;
      commit('UPDATE_DEALER_RESTRICTIONS', [dealer.dealerNumber]);
      dispatch('setSelectedDealer', dealer.dealerNumber);
    },
  },
};

export default DealersStore;
