import head from 'lodash/head';
import isInteger from 'lodash/isInteger';
import isEmpty from 'lodash/isEmpty';
import { roundNumber, scrollToTop } from '@/assets/js/helpers/general-helpers';
import consoleLog from '@/assets/js/helpers/console-log';
import { FLEET_RESERVATION_STEPS } from '@/constants/constants-fleet';
import httpInternal from '@/http/httpInternal';
import { ROUTES } from '@/constants/constants-routes';

const INITIAL_FLEET_RESERVATION_STEPS = () => ([
  {
    key: FLEET_RESERVATION_STEPS.information,
    label: 'general.contactInfo',
    completed: false,
    mainComponent: 'FleetReservationStepInformation',
    mainColumns: 12,
  },
  {
    key: FLEET_RESERVATION_STEPS.confirmation,
    label: 'general.confirmation',
    completed: false,
    mainComponent: 'FleetReservationStepConfirmation',
    mainColumns: 12,
  },
]);

const INITIAL_FLEET_RESERVATION_SUCCESS_DATA = () => ({
  reservationComplete: false,
  contactPerson: '',
  orgNumber: '',
  companyName: '',
  carModel: '',
  quantity: '',
  dealerNumber: '',
});

const INITIAL_FLEET_RESERVATION_STATE_ITEM = () => ({
  fleetReservationProduct: {},
  fleetReservationSteps: INITIAL_FLEET_RESERVATION_STEPS(),
  currentFleetReservationStepKey: head(INITIAL_FLEET_RESERVATION_STEPS()).key,
  fleetReservationQuantity: '',
  fleetReservationMessageToDealer: '',
  fleetReservationSuccessData: INITIAL_FLEET_RESERVATION_SUCCESS_DATA(),
});

const createFleetReservationProduct = (reservationProductData = {}) => {
  const defaultValues = {
    allowPrivate: null,
    allowFleet: null,
    sku: null,
    name: null,
    brand: null,
    price: null,
    taxRate: null,
    imageUrl: null,
    carlineId: null,
    carlineName: null,
    gdprLink: null,
    reservationsPerCustomer: null,
    newsletterLink: null,
    type: null,
    termsAndConditions: null,
    imageDisclaimer: null,
    adobeTransactionalEventName: null,
    adobeNewsletterEventName: null,
    fleetData: {
      brand: null,
      brandShort: null,
      carModelName: null,
      carModelNumber: null,
      maxQuantity: null,
      maxQuantityWithoutManualApproval: null,
      includedHiddenDealers: [],
    },
  };

  const reservationData = {
    ...defaultValues,
    ...reservationProductData,
  };

  reservationData.fleetData = {
    ...defaultValues.fleetData,
    ...reservationProductData.fleetData,
  };

  return reservationData;
};

const FleetStore = {
  state: {
    activeFleetReservation: '',
    fleetReservationStates: {},
  },
  getters: {
    getActiveFleetReservation: (state) => state.activeFleetReservation,
    getFleetReservationState: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => state.fleetReservationStates[fleetStateKey],

    getFleetReservationProduct: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.fleetReservationProduct,

    getFleetReservationQuantity: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.fleetReservationQuantity,

    getFleetReservationMessageToDealer: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.fleetReservationMessageToDealer,

    getFleetReservationSteps: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.fleetReservationSteps ?? [],

    getFleetReservationStepIndex: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
      stepKey = getters.getCurrentFleetReservationStepKey({ fleetStateKey }),
    } = {}) => getters.getFleetReservationSteps(
      fleetStateKey,
    )?.findIndex?.((x) => x.key === stepKey),

    getFleetReservationStepByKey: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
      stepKey = getters.getCurrentFleetReservationStepKey({ fleetStateKey }),
    } = {}) => state.getFleetReservationSteps({ fleetStateKey })
      ?.find?.((x) => (x.key === stepKey)),

    getCurrentFleetReservationStepKey: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.currentFleetReservationStepKey,

    getFleetReservationSuccessData: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({ fleetStateKey })?.fleetReservationSuccessData,

    getFleetReservationDealer: (state, getters, rootState) => {
      const { name, query } = rootState.route ?? {};
      return name === ROUTES.fleetReservation
        ? getters.getDealer(query?.dealer)
        : undefined;
    },

    isFleetReservationComplete: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => !!getters.getFleetReservationState({
      fleetStateKey,
    })?.fleetReservationSuccessData?.reservationComplete,

    isFleetReservationConfirmation: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => getters.getFleetReservationState({
      fleetStateKey,
    })?.currentFleetReservationStepKey === FLEET_RESERVATION_STEPS.confirmation,

    isFleetReservationDealer: (state, getters) => !!getters.getFleetReservationDealer,

    doesFleetReservationRequireManualApproval: (state, getters) => ({
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) => {
      const { maxQuantityWithoutManualApproval } = getters.getFleetReservationProduct({
        fleetStateKey,
      })?.fleetData ?? {};

      if (!maxQuantityWithoutManualApproval) return false;

      return roundNumber(getters.getFleetReservationQuantity({ fleetStateKey }), 0)
        > roundNumber(maxQuantityWithoutManualApproval, 0);
    },
  },
  mutations: {
    UPDATE_ACTIVE_FLEET_RESERVATION(state, {
      fleetStateKey = '',
    } = {}) {
      if (!fleetStateKey && fleetStateKey !== '') return;
      state.activeFleetReservation = fleetStateKey;
    },
    UPDATE_FLEET_RESERVATION_STATE(state, {
      fleetStateKey,
      fleetReservationState = INITIAL_FLEET_RESERVATION_STATE_ITEM(),
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates, fleetStateKey, fleetReservationState);
    },
    UPDATE_FLEET_RESERVATION_PRODUCT(state, {
      fleetStateKey,
      fleetReservationProduct = createFleetReservationProduct(),
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'fleetReservationProduct', fleetReservationProduct);
    },
    COMPLETE_FLEET_RESERVATION_STEP(state, {
      fleetStateKey,
      stepKey = state[fleetStateKey]?.currentFleetReservationStepKey,
    } = {}) {
      if (!fleetStateKey) return;
      const stepIndex = state.fleetReservationStates[fleetStateKey]?.fleetReservationSteps
        ?.findIndex?.((x) => x.key === stepKey);
      this._vm.$set(state.fleetReservationStates[fleetStateKey].fleetReservationSteps[stepIndex], 'completed', true);
    },
    UPDATE_FLEET_RESERVATION_STEPS(state, {
      fleetStateKey,
      fleetReservationSteps = INITIAL_FLEET_RESERVATION_STEPS(),
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'fleetReservationSteps', fleetReservationSteps);
    },
    UPDATE_CURRENT_FLEET_RESERVATION_STEP_KEY(state, {
      fleetStateKey,
      stepKey = head(INITIAL_FLEET_RESERVATION_STEPS()).key,
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'currentFleetReservationStepKey', stepKey);
    },
    UPDATE_FLEET_RESERVATION_QUANTITY(state, {
      fleetStateKey,
      quantity = '',
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'fleetReservationQuantity', quantity);
    },
    UPDATE_FLEET_RESERVATION_MESSAGE_TO_DEALER(state, {
      fleetStateKey,
      message = '',
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'fleetReservationMessageToDealer', message);
    },
    UPDATE_FLEET_RESERVATION_SUCCESS_DATA(state, {
      fleetStateKey,
      fleetReservationSuccessData = INITIAL_FLEET_RESERVATION_SUCCESS_DATA(),
    } = {}) {
      if (!fleetStateKey) return;
      this._vm.$set(state.fleetReservationStates[fleetStateKey], 'fleetReservationSuccessData', fleetReservationSuccessData);
    },
  },
  actions: {
    async setActiveFleetReservation({ getters, commit, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      if (
        fleetStateKey === getters.getActiveFleetReservation
        && !isEmpty(getters.getFleetReservationProduct({ fleetStateKey }))
      ) return;

      commit('UPDATE_ACTIVE_FLEET_RESERVATION', { fleetStateKey });
      await dispatch('initFleetReservationState', { fleetStateKey });
    },
    async initFleetReservationState({
      state, getters, commit, dispatch,
    }, {
      fleetStateKey = getters.getActiveFleetReservation,
      force = false,
    } = {}) {
      if (getters.getFleetReservationState({ fleetStateKey }) && !force) return;

      commit('UPDATE_FLEET_RESERVATION_STATE', { fleetStateKey });

      try {
        await dispatch('fetchAndSetFleetReservationProduct', { sku: fleetStateKey });
      } catch (error) {
        delete state.fleetReservationStates[fleetStateKey];
        commit('UPDATE_ACTIVE_FLEET_RESERVATION');
        consoleLog(error);
      }
    },
    async resetFleetReservation({ getters, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      try {
        await dispatch('initFleetReservationState', {
          fleetStateKey,
          force: true,
        });
        await dispatch('logout', false);
      } catch (e) {
        consoleLog(e);
        dispatch('setAlert', { key: 'resetFleetReservationError', message: 'error.resetFleetReservation' });
      }
    },
    nextFleetReservationStep({ getters, commit }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      const lastIndex = getters.getFleetReservationSteps({ fleetStateKey })?.length ?? 0 - 1;
      const currentIndex = getters.getFleetReservationStepIndex({ fleetStateKey });
      if (currentIndex < lastIndex) {
        scrollToTop();
        commit('COMPLETE_FLEET_RESERVATION_STEP', { fleetStateKey });
        commit('UPDATE_CURRENT_FLEET_RESERVATION_STEP_KEY', {
          fleetStateKey,
          stepKey: getters.getFleetReservationSteps({ fleetStateKey })?.[currentIndex + 1]?.key,
        });
      }
    },
    prevFleetReservationStep({ getters, commit }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      const currentIndex = getters.getFleetReservationStepIndex({ fleetStateKey });
      if (currentIndex > 0) {
        commit('UPDATE_CURRENT_FLEET_RESERVATION_STEP_KEY', {
          fleetStateKey,
          stepKey: getters.getFleetReservationSteps({ fleetStateKey })?.[currentIndex - 1]?.key,
        });
      }
    },
    goToFleetReservationStep({ getters, commit, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
      stepKey = '',
    } = {}) {
      if (getters.getFleetReservationStepIndex({ fleetStateKey, stepKey }) === -1) {
        dispatch('setAlert', { key: 'fleetReservationStepError', message: 'error.fleetReservationStepNotFound' });
        return;
      }

      commit('UPDATE_CURRENT_FLEET_RESERVATION_STEP_KEY', { fleetStateKey, stepKey });
    },
    async completeAllFleetReservationSteps({ getters, commit }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      const completeStepsPromises = getters.getFleetReservationSteps({ fleetStateKey })
        ?.map?.(async (step) => {
          if (!step.completed) commit('COMPLETE_FLEET_RESERVATION_STEP', { fleetStateKey, stepKey: step.key });
        });

      await Promise.all(completeStepsPromises);
    },
    async fetchAndSetFleetReservationProduct({ getters, commit, dispatch }, {
      sku,
    } = {}) {
      let reservationProductResponse;

      try {
        reservationProductResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetReservationProductUrl(sku),
        });
      } catch (error) {
        dispatch('setAlert', { key: 'fetchReservationError', message: 'error.productNotFound', log: error.message });
        throw new Error(`Could not add fleet reservation product with SKU ${sku}`);
      }

      const { data: reservationProductData } = reservationProductResponse;

      if (!reservationProductData?.allowFleet) {
        dispatch('setAlert', { key: 'fetchReservationError', message: 'error.productNotFound' });
        throw new Error(`Reservation product with SKU ${sku} is not allowed for fleet`);
      }

      const fleetReservationProduct = createFleetReservationProduct(reservationProductData);

      commit('UPDATE_FLEET_RESERVATION_PRODUCT', { fleetStateKey: sku, fleetReservationProduct });
    },
    setFleetReservationQuantity({ getters, commit, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
      quantity = '',
    }) {
      const quantityInput = roundNumber(quantity, 0);
      const { maxQuantity } = getters.getFleetReservationProduct({ fleetStateKey })?.fleetData ?? {};

      let validatedQuantity = quantityInput < 1 ? 1 : quantityInput;

      if (isInteger(maxQuantity)) {
        validatedQuantity = validatedQuantity > maxQuantity ? maxQuantity : validatedQuantity;
      }

      if (quantity === '') validatedQuantity = '';

      commit('UPDATE_FLEET_RESERVATION_QUANTITY', { fleetStateKey, quantity: validatedQuantity.toString() });

      dispatch('validate', { key: 'fleetReservationQuantity', value: validatedQuantity });
    },
    setFleetReservationMessageToDealer({ getters, commit }, {
      fleetStateKey = getters.getActiveFleetReservation,
      message = '',
    }) {
      commit('UPDATE_FLEET_RESERVATION_MESSAGE_TO_DEALER', { fleetStateKey, message });
    },
    async createFleetReservation({ getters, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
    } = {}) {
      let fleetReservationResponse;
      const generalErrorMessage = 'error.fleetReservationPost';

      const otpPhoneNumber = getters.isFleetReservationDealer
        ? getters.getDealerMobileNumber
        : getters.userInfo.mobileNumber;

      try {
        fleetReservationResponse = await httpInternal({
          method: 'post',
          url: getters.apiCreateFleetReservation,
          data: {
            sku: getters.getFleetReservationProduct({ fleetStateKey })?.sku,
            dealer: getters.user.selectedDealer,
            quantity: roundNumber(getters.getFleetReservationQuantity({ fleetStateKey }), 0),
            firstName: getters.userInfo.firstName,
            lastName: getters.userInfo.lastName,
            email: getters.userInfo.email,
            mobileNumber: getters.userInfo.mobileNumber,
            address: getters.userInfo.address,
            zipCode: getters.userInfo.zipCode,
            city: getters.userInfo.city,
            orgNumber: getters.companyInfo.orgNumber,
            companyName: getters.companyInfo.companyName,
            companyAddress: getters.companyInfo.companyAddress,
            companyZipCode: getters.companyInfo.companyZipCode,
            companyCity: getters.companyInfo.companyCity,
            messageToDealer: getters.getFleetReservationMessageToDealer({ fleetStateKey }),
            brandConsent: getters.userAgreements.brandConsent,
            confirmOtp: {
              phoneNumber: otpPhoneNumber,
              countryCode: getters.userInfo.countryCode,
              phoneCountryCode: getters.userInfo.phoneCountryCode,
              otp: getters.userOtp,
            },
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        const message = error?.response?.status === 401
          ? 'error.otpValidate'
          : generalErrorMessage;

        dispatch('setAlert', { key: 'fleetReservationPostError', message });
        throw error;
      }

      const { data: fleetReservationSuccessData } = fleetReservationResponse;

      if (!fleetReservationSuccessData?.reservationComplete) {
        dispatch('setAlert', { key: 'fleetReservationPostError', message: generalErrorMessage });
        throw new Error('No success status');
      }

      dispatch('completeFleetReservation', { fleetStateKey, fleetReservationSuccessData });
    },
    async completeFleetReservation({ getters, commit, dispatch }, {
      fleetStateKey = getters.getActiveFleetReservation,
      fleetReservationSuccessData = INITIAL_FLEET_RESERVATION_SUCCESS_DATA(),
    } = {}) {
      commit('UPDATE_FLEET_RESERVATION_SUCCESS_DATA', {
        fleetStateKey,
        fleetReservationSuccessData: {
          reservationComplete: true,
          contactPerson: fleetReservationSuccessData?.contactPerson,
          orgNumber: fleetReservationSuccessData?.orgNumber,
          companyName: fleetReservationSuccessData?.companyName,
          carModel: fleetReservationSuccessData?.carModel,
          quantity: fleetReservationSuccessData?.quantity,
          dealerNumber: fleetReservationSuccessData?.dealerNumber,
        },
      });

      await dispatch('completeAllFleetReservationSteps', { fleetStateKey });
      await dispatch('goToFleetReservationStep', {
        fleetStateKey,
        stepKey: FLEET_RESERVATION_STEPS.confirmation,
      });
    },
  },
};

export default FleetStore;
