import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=89fa658c"
import script from "./AppFooter.vue?vue&type=script&lang=js"
export * from "./AppFooter.vue?vue&type=script&lang=js"
import style0 from "./appFooter.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./appFooter.audi.scss?vue&type=style&index=1&prod&lang=scss&theme=audi&external"
import style2 from "./appFooter.seat.scss?vue&type=style&index=2&prod&lang=scss&theme=seat&external"
import style3 from "./appFooter.cupra.scss?vue&type=style&index=3&prod&lang=scss&theme=cupra&external"
import style4 from "./appFooter.skoda.scss?vue&type=style&index=4&prod&lang=scss&theme=skoda&external"
import style5 from "./appFooter.vw.scss?vue&type=style&index=5&prod&lang=scss&theme=vw&external"
import style6 from "./appFooter.catalog.scss?vue&type=style&index=6&prod&lang=scss&theme=catalog&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports