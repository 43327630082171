function initialState() {
  return {
    termsConsent: false,
    brandConsent: false,
    modelConsent: true,
    emailConfirmation: false,
  };
}

const UserAgreementsStore = {
  state: initialState(),
  getters: {
    userAgreements: (state) => state,
  },
  mutations: {
    RESET_USER_AGREEMENTS(state) {
      const s = initialState();
      Object.keys(s)
        .forEach((key) => {
          this._vm.$set(state, key, s[key]);
        });
    },
    UPDATE_USER_AGREEMENTS_BY_KEY(state, { key = '', value = false }) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = value;
      }
    },
  },
  actions: {
    async setUserAgreementAndValidate({ commit, dispatch }, { key = '', value = false }) {
      commit('UPDATE_USER_AGREEMENTS_BY_KEY', { key, value });
      await dispatch('validate', { key, value });
    },
    resetUserAgreements({ commit }) {
      commit('RESET_USER_AGREEMENTS');
    },
  },
};

export default UserAgreementsStore;
