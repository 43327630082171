<template>
  <div
    v-if="showAdminBar"
    id="admin-mode-indicator"
    @mouseenter="onHover"
    @mouseleave="offHover"
  >
    <div class="indicator-container">
      <UiIcon
        class="admin-icon"
        :icon="ICONS.cog"
        :use-background="false"
      />
      <div class="admin-label">
        Admin
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ICONS } from '@/constants/constants-icons';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';

const HOVER_ATTRIBUTE = 'data-show-admin-hover';

export default {
  name: 'AdminModeIndicator',
  components: { UiIcon },
  data() {
    return {
      ICONS,
    };
  },
  computed: {
    ...mapGetters([
      'isAdminMode',
    ]),
    showAdminBar() {
      return this.isAdminMode;
    },
  },
  methods: {
    onHover() {
      document.body.setAttribute(HOVER_ATTRIBUTE, true);
    },
    offHover() {
      document.body.removeAttribute(HOVER_ATTRIBUTE);
    },
  },
};
</script>

<style lang="scss">
#admin-mode-indicator {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  height: var(--app-admin-mode-indicator-height);
  box-sizing: content-box;
  transition: right .3s ease;

  .indicator-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: help;
    padding: 5px 10px;
    color: $color-white;
    background: $color-black;
    font-size: 12px;
    text-align: center;
  }

  .admin-label {
    line-height: 1;
    padding-left: 5px;
    letter-spacing: 1px;
  }

  .admin-icon {
    --icon-size: 18px;
  }
}
</style>
