import bootstrap from '@/common-main';
import App from './seat-app.vue';
import store from './seat-store';
import router from './seat-router';

const progressbarOptions = {
  color: '#EA5D1A',
};

bootstrap({
  App, store, router, progressbarOptions,
});
