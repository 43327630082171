<template>
  <div
    class="ui-image"
    :data-state="imageState"
    :data-width="imageWidth"
    :data-height="imageHeight"
  >
    <StatusLoader
      :is-loading="showLoader"
      :transparent="true"
      position="absolute"
    />

    <transition
      name="ui-image-fade"
      :mode="transitionMode"
    >
      <div
        v-if="showImage"
        :key="loadedImageSrc"
        class="image-loaded"
        role="img"
        :style="loadedImageStyle"
      />
      <div
        v-else-if="showError"
        key="image-error"
        class="image-error"
      >
        <UiIcon
          class="error-icon"
          :icon="errorIcon"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  createIntersectionObserver, createResizeSensor,
} from '@/assets/js/helpers/general-helpers';
import StatusLoader from '@/components/status/StatusLoader.vue';
import { BG_TYPES } from '@/constants/constants-images';
import { ICONS } from '@/constants/constants-icons';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';
import debounce from 'lodash/debounce';
import { isEqual } from 'lodash';

const IMAGE_STATES = {
  waiting: 'waiting',
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
};

const EVENTS = {
  loaded: 'loaded',
  error: 'error',
};

const SIZE_INTERVALS = [
  50,
  100,
  150,
  200,
  300,
  500,
  700,
  900,
  1200,
  1500,
  1800,
  2100,
  2500,
  3000,
];

export default {
  name: 'UiImage',
  components: {
    StatusLoader,
    UiIcon,
  },
  props: {
    imageSource: {
      type: String,
      default: undefined,
    },
    preventLoad: {
      type: Boolean,
      default: false,
    },
    useLoader: {
      type: Boolean,
      default: false,
    },
    backgroundSize: VueTypes.oneOf([BG_TYPES.cover, BG_TYPES.contain]).def(BG_TYPES.cover),
    transitionMode: {
      type: String,
      default: undefined,
    },
    onLoadedCallback: {
      type: Function,
      default: () => undefined,
    },
    onErrorCallback: {
      type: Function,
      default: () => undefined,
    },
  },
  emits: [
    ...Object.values(EVENTS),
  ],
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      elementWidth: 0,
      elementHeight: 0,
      activeOptimizeData: {},
      intersectionObserver: null,
      resizeSensor: null,
      imageState: IMAGE_STATES.waiting,
      asyncImage: new Image(),
      loadedImageSrc: '',
      firstLoadComplete: false,
    };
  },
  computed: {
    loadedImageStyle() {
      return `background-image: url(${this.loadedImageSrc}); background-size: ${this.backgroundSize}`;
    },
    showImage() {
      if (this.showError) return false;
      if (this.loadedImageSrc) return true;

      return this.imageState === IMAGE_STATES.loaded;
    },
    showError() {
      return this.imageState === IMAGE_STATES.error;
    },
    showLoader() {
      return this.useLoader && this.imageState === IMAGE_STATES.loading;
    },
    errorIcon() {
      return ICONS.missingImage;
    },
    useImageOptimizing() {
      return !!this.imageSource?.includes?.('image-cdn');
    },
  },
  watch: {
    preventLoad: {
      immediate: true,
      handler(preventLoad) {
        if (preventLoad) return;
        if (this.imageState !== IMAGE_STATES.waiting) return;
        this.fetchImage();
      },
    },
    imageSource(newImageSource, oldImageSource) {
      if (newImageSource !== oldImageSource) {
        this.imageState = IMAGE_STATES.waiting;
        this.firstLoadComplete = false;
      }

      if (this.preventLoad) return;
      this.fetchImage();
    },
  },
  mounted() {
    this.intersectionObserver = createIntersectionObserver(this.$el, this.onElementObserved);
    if (this.useImageOptimizing) {
      this.resizeSensor = createResizeSensor(this.$el, debounce(this.onElementResize, 300));
    }
  },
  beforeDestroy() {
    if (this.intersectionObserver) this.intersectionObserver.disconnect();
    if (this.resizeSensor) this.resizeSensor.detach();
  },
  methods: {
    fetchImage() {
      setTimeout(() => {
        if (!this.imageSource) return;
        this.elementWidth = this.getElementWidth();
        this.elementHeight = this.getElementHeight();

        if (this.elementWidth === 0 || this.elementHeight === 0) return;

        if (!this.firstLoadComplete) this.imageState = IMAGE_STATES.loading;

        this.asyncImage.onload = this.onImageLoaded;
        this.asyncImage.onerror = this.onImageError;
        this.asyncImage.src = this.optimizeImage(this.imageSource);
      }, 0);
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (this.preventLoad) {
          this.intersectionObserver.unobserve(target);
          return;
        }

        if (!isIntersecting) return;
        this.intersectionObserver.unobserve(target);

        if (this.imageState === IMAGE_STATES.loaded) return;

        this.fetchImage();
      });
    },
    onImageLoaded() {
      this.imageWidth = this.asyncImage.naturalWidth;
      this.imageHeight = this.asyncImage.naturalHeight;
      try {
        setTimeout(() => {
          this.loadedImageSrc = this.asyncImage.src;

          if (!this.firstLoadComplete) {
            this.imageState = IMAGE_STATES.loaded;
            this.firstLoadComplete = true;
          }

          this.$emit(EVENTS.loaded);
        }, 200);
      } catch (error) {
        this.onImageError();
        consoleLog(error);
      }
    },
    onImageError() {
      setTimeout(() => {
        this.asyncImage.onerror = () => {
          this.imageState = IMAGE_STATES.error;
          this.firstLoadComplete = true;
          this.$emit(EVENTS.error);
        };

        const cacheBusterPrefix = this.asyncImage.src?.includes?.('?') ? '&' : '?';
        this.asyncImage.src += `${cacheBusterPrefix}${+new Date()}`;
      }, 1000);
    },
    onElementResize() {
      const optimizeData = this.getOptimizeData();
      if (isEqual(this.activeOptimizeData, optimizeData)) return;

      this.fetchImage();
    },
    getElementWidth() {
      return this.$el?.offsetWidth;
    },
    getElementHeight() {
      return this.$el?.offsetHeight;
    },
    getOptimizeData() {
      const width = this.getElementWidth();
      const height = this.getElementHeight();

      const param = width > height ? 'width' : 'height';
      const size = width > height ? width : height;

      const value = SIZE_INTERVALS.find((interval) => interval > size) ?? SIZE_INTERVALS.at(-1);

      return { param, value };
    },
    optimizeImage(imageUrl = '') {
      if (!this.useImageOptimizing) return imageUrl;

      const optimizeData = this.getOptimizeData();

      const prefix = imageUrl?.includes?.('?') ? '&' : '?';
      const optimizeParam = `${prefix}${optimizeData.param}=${optimizeData.value}`;

      this.activeOptimizeData = optimizeData;
      return `${this.imageSource}${optimizeParam}`;
    },
  },
};
</script>

<style lang="scss">
.ui-image {
  --bg-color: transparent;

  width: 100%;
  padding-bottom: 100%;
  position: relative;

  .image-loaded {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    transition: opacity .3s ease-in-out;
    background-color: var(--bg-color);
  }

  .image-error,
  .error-icon,
  .icon-wrapper {
    height: 100%;
    width: 100%;
  }

  .image-error {
    display: flex;
    justify-content: center;
    transition: opacity .3s ease-in-out;
    position: absolute;
    opacity: .15;
    background-color: var(--bg-color);

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-container {
      width: 40%;
      padding-bottom: 40%;
      max-width: 200px;
    }
  }

  .ui-image-fade-enter-active, .ui-image-fade-leave-active {
    transition: opacity .9s ease;
  }

  .ui-image-fade-enter, .ui-image-fade-leave-to {
    opacity: 0;
  }
}
</style>
