import { setCookie } from '@/assets/js/helpers/general-helpers';

const DEFAULT_CONSENT_STATE = {
  hasResponded: false,
  hasConsented: false,
  respondedToVersion: null,
  responseValidTo: null,
};

const CookiesStore = {
  state: {
    consent: DEFAULT_CONSENT_STATE,
  },
  getters: {
    cookieConsent: (state) => state.consent,
    cookiesConfig: (state, getters) => getters.getConfigByKeyString('cookies.consent'),
    hasCookiesConfig: (state, getters) => {
      if (!getters.cookiesConfig) return false;

      const {
        title, text, link,
      } = getters.cookiesConfig;

      return !(!title && !text && !link);
    },
    shouldShowCookieConsent: (state, getters) => {
      if (!getters.hasCookiesConfig) return false;
      if (!getters.cookieConsent.hasResponded) return true;

      return !getters.isCookieResponseValid;
    },
    allowCookies: (state, getters) => {
      if (!getters.hasCookiesConfig) return true;
      if (!getters.cookieConsent.hasResponded) return false;
      if (!getters.isCookieResponseValid) return false;

      return getters.cookieConsent.hasConsented;
    },
    isCookieResponseValid: (state, getters) => {
      if (!getters.hasCookiesConfig) return false;

      const { responseValidTo, respondedToVersion } = getters.cookieConsent;

      if (!responseValidTo) return false;
      if (respondedToVersion !== getters.activeCookiePolicyVersion) return false;

      const dateToday = new Date().getTime();

      return dateToday < responseValidTo;
    },
    activeCookiePolicyVersion: (state, getters) => {
      if (!getters.hasCookiesConfig) return 1;

      return getters.cookiesConfig.version || 1;
    },
  },
  mutations: {
    RESET_COOKIES_CONSENT(state) {
      this._vm.$set(state, 'consent', DEFAULT_CONSENT_STATE);
    },
    UPDATE_COOKIES_HAS_RESPONDED(state, hasResponded = false) {
      this._vm.$set(state.consent, 'hasResponded', hasResponded);
    },
    UPDATE_COOKIES_HAS_CONSENTED(state, hasConsented = false) {
      this._vm.$set(state.consent, 'hasConsented', hasConsented);
    },
    UPDATE_COOKIES_RESPONDED_TO_VERSION(state, respondedToVersion = 0) {
      this._vm.$set(state.consent, 'respondedToVersion', respondedToVersion);
    },
    UPDATE_COOKIES_RESPONSE_VALID_TO(state, responseValidTo = new Date()) {
      this._vm.$set(state.consent, 'responseValidTo', responseValidTo);
    },
  },
  actions: {
    async setCookieConsentResponse({ getters, commit }, consent = false) {
      if (!getters.hasCookiesConfig) return;

      commit('UPDATE_COOKIES_HAS_RESPONDED', true);
      commit('UPDATE_COOKIES_HAS_CONSENTED', consent);
      commit('UPDATE_COOKIES_RESPONDED_TO_VERSION', getters.activeCookiePolicyVersion);

      const todayDate = new Date();
      const expirationDate = todayDate.setMonth(todayDate.getMonth() + 6);
      commit('UPDATE_COOKIES_RESPONSE_VALID_TO', expirationDate);
      setCookie('sat_track', consent, expirationDate);
    },
    async acceptCookies({ dispatch }) {
      await dispatch('setCookieConsentResponse', true);
      this._vm.$tracking.datadog.cookiesAccepted();
      dispatch('initTracking');
    },
    async rejectCookies({ dispatch }) {
      this._vm.$tracking.datadog.cookiesRejected();
      await dispatch('setCookieConsentResponse', false);
    },
  },
};

export default CookiesStore;
