import Vue from 'vue';
import Router from 'vue-router';
import { ROUTES } from '@/constants/constants-routes';
import {
  ROUTE_CONFIGURATOR_PARAMS,
  ROUTE_STOCK_CARS_CONFIGURATOR_PARAMS,
} from '@/constants/constants-router';

Vue.use(Router);

// Static paths
// These paths should ALWAYS be pushed to by the name property
// so client routers can override their paths easily while having
// something constant we can reliably use when navigating programmatically
const prodRoutes = [
  {
    name: ROUTES.home,
    path: '',
    component: () => import(/* webpackChunkName: "CmsPage" */ '@/components/cms/CmsPage.vue'),
  },
  {
    name: ROUTES.addToCart,
    path: '/reserve/:sku/:dealerUrlKey?',
    component: () => import(/* webpackChunkName: "AddToCart" */ '@/views/AddToCart.vue'),
  },
  {
    name: ROUTES.checkout,
    path: '/checkout/:checkoutKey?/:stepKey?',
    component: () => import(/* webpackChunkName: "Checkout" */ '@/views/checkout/CheckoutMain.vue'),
    meta: {
      gtm: 'reservation-checkout',
      title: 'Reservere',
      routerViewKey: 'checkout',
    },
  },
  {
    name: ROUTES.fleetReservation,
    path: '/reservasjon-bedrift/:reservationSku/:stepKey?',
    component: () => import(/* webpackChunkName: "FleetReservation" */ '@/views/fleetReservation/FleetReservation.vue'),
    meta: {
      gtm: 'fleet-reservation',
      title: 'Reservasjon Bedrift',
      routerViewKey: 'fleet-reservation',
    },
    alias: '/fleet-reservation/:reservationSku/:stepKey?',
  },
  {
    name: ROUTES.paymentVerification,
    path: '/payment-verification',
    component: () => import(/* webpackChunkName: "NetsVerification" */ '@/views/paymentVerification/NetsVerification.vue'),
  },
  {
    name: ROUTES.orderVerification,
    path: '/order-verification/:uniqueId',
    component: () => import(/* webpackChunkName: "OrderVerification" */ '@/views/paymentVerification/OrderVerification.vue'),
  },
  {
    name: ROUTES.cancelOrder,
    path: '/cancel-order/:uniqueId',
    component: () => import(/* webpackChunkName: "CancelOrder" */ '@/views/paymentVerification/CancelOrder.vue'),
  },
  {
    name: ROUTES.modelsOverview,
    path: '/models-overview',
    component: () => import(/* webpackChunkName: "Models" */ '@/views/models/ModelsOverview.vue'),
    meta: {
      gtm: 'models-overview',
      title: 'Modeller',
    },
  },
  {
    name: ROUTES.configurator,
    path: `/${ROUTES.configurator}/${ROUTE_CONFIGURATOR_PARAMS}`,
    component: () => import(/* webpackChunkName: "Configurator" */ '@/views/configurator/ConfiguratorMain.vue'),
    meta: {
      gtm: ROUTES.configurator,
      title: 'Bygg din bil',
      routerViewKey: ROUTES.configurator,
    },
    alias: `/${ROUTES.configurator}/${ROUTE_CONFIGURATOR_PARAMS}`,
  },
  {
    // @todo Remove when StockCars are ready
    name: ROUTES.carsInStock,
    path: '/cars-in-stock',
    redirect: { name: ROUTES.stockCars },
    // component: () => import(/* webpackChunkName: "CarsInStockOld" */ '@/views/stockCars/CarsInStockOld.vue'),
    // meta: {
    //   gtm: 'cars-in-stock',
    //   title: 'Biler på lager',
    //   routerViewKey: 'cars-in-stock',
    // },
  },
  {
    name: ROUTES.stockCars,
    path: `/${ROUTES.stockCars}`,
    component: () => import(/* webpackChunkName: "StockCars" */ '@/views/stockCars/StockCarsMain.vue'),
    meta: {
      gtm: ROUTES.stockCars,
      title: 'Biler på lager',
      routerViewKey: ROUTES.stockCars,
    },
  },
  {
    name: ROUTES.dealerStockCars,
    path: `/dealer/${ROUTES.stockCars}`,
    component: () => import(/* webpackChunkName: "StockCars" */ '@/views/stockCars/StockCarsMain.vue'),
    meta: {
      gtm: ROUTES.dealerStockCars,
      title: 'Biler på lager',
      routerViewKey: ROUTES.dealerStockCars,
    },
  },
  {
    name: ROUTES.stockCarsConfigurator,
    path: `/${ROUTES.stockCarsConfigurator}/${ROUTE_STOCK_CARS_CONFIGURATOR_PARAMS}`,
    component: () => import(/* webpackChunkName: "StockCarsConfigurator" */ '@/views/configurator/ConfiguratorMain.vue'),
    meta: {
      gtm: ROUTES.stockCarsConfigurator,
      title: 'Kjøp din lagerbil',
      routerViewKey: ROUTES.stockCarsConfigurator,
    },
  },
  {
    name: ROUTES.dealerStockCarsConfigurator,
    path: `/dealer/${ROUTES.stockCarsConfigurator}/${ROUTE_STOCK_CARS_CONFIGURATOR_PARAMS}`,
    component: () => import(/* webpackChunkName: "StockCarsConfigurator" */ '@/views/configurator/ConfiguratorMain.vue'),
    meta: {
      gtm: ROUTES.dealerStockCarsConfigurator,
      title: 'Kjøp din lagerbil',
      routerViewKey: ROUTES.dealerStockCarsConfigurator,
    },
  },
  {
    name: ROUTES.eSimActivation,
    path: '/activate-sim',
    component: () => import(/* webpackChunkName: "ESim" */ '@/views/eSim/ESim.vue'),
    props: {
      type: 'activation',
    },
  },
  {
    name: ROUTES.eSimDeactivation,
    path: '/deactivate-sim',
    component: () => import(/* webpackChunkName: "ESim" */ '@/views/eSim/ESim.vue'),
    props: {
      type: 'deactivation',
    },
  },
  {
    name: ROUTES.tradeIn,
    path: '/trade-in',
    component: () => import(/* webpackChunkName: "TradeIn" */ '@/views/tradeIn/TradeIn.vue'),
    alias: '/innbytte-av-bil',
  },
  {
    name: ROUTES.dealerLogin,
    path: '/dealer-login',
    component: () => import(/* webpackChunkName: "DealerLogin" */ '@/views/administration/DealerLogin.vue'),
    meta: {
      gtm: 'dealer-login',
      title: 'Modeller',
    },
  },
  {
    name: ROUTES.openOrder,
    path: '/open-order/:orderId',
    component: () => import(/* webpackChunkName: "OpenOrder" */ '@/views/OpenOrder.vue'),
  },
  // @todo @deprecated Unused or not working paths
  {
    name: ROUTES.scriveVerification,
    path: '/sign-verification',
    component: () => import(/* webpackChunkName: "ScriveVerification" */ '@/views/signVerification/ScriveVerification.vue'),
  },
  {
    name: ROUTES.openOrderAdmin,
    path: '/open-order/:orderId/admin',
    component: () => import(/* webpackChunkName: "OpenOrder" */ '@/views/OpenOrder.vue'),
    meta: { requiresAuth: true },
  },
];

// Dynamic paths
// These need to be added after all other routes so named routes gets priority.
// Clients should never override or add to dynamic paths.
const dynamicRoutes = [
  {
    name: 'cms-page',
    path: '/:dust?/:skeleton?/:grandparent?/:parent?/:page?',
    component: () => import(/* webpackChunkName: "CmsPage" */ '@/components/cms/CmsPage.vue'),
    meta: { gtm: 'CMS Page' },
  },
];

// Development routes
// Routes that are valuable for testing purposes but shouldn't be included in production
// @todo Remove this entirely from prod bundle
const devRoutes = [];
if (process.env.NODE_ENV === 'development') {
  devRoutes.push({
    path: '/form-test/',
    name: 'FormTest',
    component: () => import(/* webpackChunkName: "FormTest" */ '@/views/test/FormTest.vue'),
  });
}

function setUpRouter(clientRoutes = []) {
  const routes = [...devRoutes, ...prodRoutes];
  clientRoutes.forEach((clientRoute) => {
    const index = routes.findIndex((route) => route.name === clientRoute.name);
    index > -1
      ? routes[index] = { ...routes[index], ...clientRoute }
      : routes.push(clientRoute);
  });
  routes.push(...dynamicRoutes);

  return new Router({
    mode: 'history',
    linkActiveClass: 'active',
    transitionOnLoad: true,
    root: '/',
    routes,
    scrollBehavior() {
      // Just leave the scrolling to me...
    },
  });
}

export default setUpRouter;
