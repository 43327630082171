<template>
  <component
    :is="elementTag"
    v-bind="elementAttributes"
    ref="button"
    class="ui-button"
    :class="{
      primary: actionType === 'primary',
      secondary: actionType === 'secondary',
      dark: contrast === 'dark',
      light: contrast === 'light',
    }"
    :data-disabled="disabled"
    :type="computedType"
    @click="onClickFunction"
  >
    <UiIcon
      v-if="iconBefore"
      class="icon-before button-icon"
      :icon="iconBefore"
      :style="iconStyles"
    />
    <span
      ref="buttonText"
      class="button-text"
    >
      <slot />
    </span>
    <UiIcon
      v-if="iconAfter"
      class="icon-after button-icon"
      :icon="iconAfter"
      :style="iconStyles"
    />
  </component>
</template>

<script>
import VueTypes from 'vue-types';
import { ICONS } from '@/constants/constants-icons';
import UiIcon from '@/components/ui/uiIcon/UiIcon.vue';
import UiFunctionLinkBase from '@/components/ui/UiFunctionLinkBase.vue';

export default {
  name: 'UiButton',
  components: { UiIcon },
  extends: UiFunctionLinkBase,
  props: {
    actionType: VueTypes.oneOf(['primary', 'secondary'])
      .def('primary'),
    contrast: VueTypes.oneOf(['dark', 'light', ''])
      .def(''),
    type: VueTypes.oneOf(['button', 'submit', 'reset'])
      .def('button'),
    iconBefore: VueTypes.oneOf([...Object.values(ICONS)]),
    iconAfter: VueTypes.oneOf([...Object.values(ICONS)]),
    disabled: {
      type: Boolean,
      default: false,
    },
    forward: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultTag: 'button',
      iconColor: null,
    };
  },
  computed: {
    computedType() {
      return this.elementTag === this.defaultTag ? this.type : false;
    },
    iconStyles() {
      if (!this.iconColor) return {};

      return {
        '--icon-color': this.iconColor,
      };
    },
  },
  mounted() {
    this.iconColor = this.getTextColor();
  },
  methods: {
    onClickFunction(e) {
      const { button } = this.$refs;
      if (button) button.blur();
      if (this.disabled) return e.preventDefault();
      if (typeof this.link !== 'function') return null;

      const buttonLabel = this.$slots.default?.[0]?.text;
      if (buttonLabel) this.$tracking.adobe.buttonClick(buttonLabel);

      return this.link();
    },
    getTextColor() {
      const { buttonText } = this.$refs;
      if (!buttonText) return null;
      const textStyles = window.getComputedStyle(buttonText);

      return textStyles.color;
    },
  },
};
</script>

<style lang="scss" src="./uiButton.scss"></style>
<style lang="scss" theme="seat" src="./uiButton.seat.scss"></style>
<style lang="scss" theme="cupra" src="./uiButton.cupra.scss"></style>
<style lang="scss" theme="skoda" src="./uiButton.skoda.scss"></style>
<style lang="scss" theme="vw" src="./uiButton.vw.scss"></style>
<style lang="scss" theme="carbuy" src="./uiButton.carbuy.scss"></style>
<style lang="scss" theme="catalog" src="./uiButton.catalog.scss"></style>
