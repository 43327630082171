import Vue from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import { getClientRouter, getClientStore } from '@/assets/js/helpers/config-helpers';
import { DATADOG_ACTIONS } from '@/constants/constants-datadog';
import consoleLog from '@/assets/js/helpers/console-log';
import { FINANCING_OPTIONS } from '@/constants/constants-financing';

const initializeDatadog = () => {
  datadogRum.init({
    applicationId: '1dc6b34c-e640-4f0c-a375-284f7ae09b84',
    clientToken: 'pub11ec1d03d8730310800e5c102aa209d8',
    site: 'datadoghq.eu',
    service: process.env.client,
    env: process.env.environment,
    version: process.env.DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};

const DataDogTracking = Vue.extend({
  data() {
    return {
      app: {},
      initialized: false,
      currentView: undefined,
    };
  },
  computed: {
    configuratorActiveModel() {
      return this.app.$store.getters.getModelById();
    },
    configuratorActiveMarketModel() {
      return this.app.$store.getters.getMarketModelById();
    },
    configuratorActiveModelConfigStep() {
      return this.app.$store.getters.getModelCurrentConfigStep();
    },
  },
  methods: {
    getModelById(modelId) {
      return this.app.$store.getters.getModelById(modelId);
    },
    getMarketModelById(marketModelId) {
      return this.app.$store.getters.getMarketModelById(marketModelId);
    },
    getConfiguratorGeneralContext({
      modelId = this.configuratorActiveModel?.id,
      marketModelId = this.configuratorActiveMarketModel?.id,
    } = {}) {
      const {
        label: modelName = '',
        code: modelCode = '',
        year: modelYear = '',
      } = this.getModelById(modelId) ?? {};

      const {
        name: marketModelName = '',
      } = this.getMarketModelById(marketModelId) ?? {};

      const configuratorType = this.app.$store.getters.isStockCarConfigurator({ modelId })
        ? 'stock_car'
        : 'new_car';

      const financingOptionType = this.app.$store.getters.getSelectedFinancingOption({
        financingStateKey: modelId,
        allowCashClones: true,
      });

      return {
        scope: this.app.$store.getters.appScope,
        modelName,
        modelCode,
        modelYear,
        marketModelName,
        marketModelId,
        configuratorType,
        financingOptionType,
      };
    },
    async initDataDogTracking() {
      if (!this.initialized) {
        this.app.$router = await getClientRouter();
        this.app.$store = await getClientStore();
        this.initialized = true;
      }
    },
    addDataDogAction(actionKey = '', context = {}) {
      if (this.app.$store.getters.isAdminMode) return;
      if (!actionKey) return;

      consoleLog(`[DATADOG TRACKING]: ${actionKey}`, context);
      datadogRum.addAction(actionKey, context);
    },

    startDataDogView(routeName) {
      if (this.app.$store.getters.isAdminMode) return;
      if (!routeName) return;
      if (routeName === this.currentView) return;

      this.currentView = routeName;

      consoleLog(`[DATADOG START VIEW]: ${routeName}`);

      datadogRum.startView({
        name: routeName,
      });
    },

    // GENERAL
    cookiesAccepted() {
      this.addDataDogAction(DATADOG_ACTIONS.cookies.accepted);
    },

    cookiesRejected() {
      this.addDataDogAction(DATADOG_ACTIONS.cookies.rejected);
    },

    buttonClick(label = '') {
      console.log('button click', label);
    },

    // CONFIGURATOR
    configuratorStepVisited(modelId, stepKey) {
      const action = DATADOG_ACTIONS.configurator.stepVisited[stepKey];
      this.addDataDogAction(action, this.getConfiguratorGeneralContext({ modelId }));
    },

    configuratorStepChanged() {
      const context = {
        stepKey: this.app.$store.getters.getModelCurrentConfigStep()?.key,
        stepNumber: this.app.$store.getters.getModelCurrentConfigStepIndex() + 1,
        ...this.getConfiguratorGeneralContext(),
      };

      this.addDataDogAction(DATADOG_ACTIONS.configurator.stepChanged, context);
    },

    configuratorUserValidationStarted() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.userValidation.started, this.getConfiguratorGeneralContext());
    },

    configuratorUserValidationPhoneNumberEntered() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.userValidation.enterPhoneNumber, this.getConfiguratorGeneralContext());
    },

    configuratorUserValidationCodeEntered() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.userValidation.enterCode, this.getConfiguratorGeneralContext());
    },

    configuratorModelSelected() {

    },

    configuratorMarketModelSelected(marketModelId) {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.marketModel.selected, this.getConfiguratorGeneralContext({ marketModelId }));
    },

    configuratorOptionSelected(marketModelOption) {
      const value = this.app.$store.getters.getMarketModelOptionPrice({
        marketModelOptionId: marketModelOption?.id,
        financingOptionType: FINANCING_OPTIONS.cash,
        alwaysIncludeVat: true,
      });
      const categoryData = this.app.$store.getters.getMarketModelCategoryById({
        categoryId: marketModelOption?.categoryId,
      });
      const { name: category } = categoryData ?? {};

      const { id, label } = marketModelOption ?? {};

      const context = {
        id,
        label,
        value,
        category,
        ...this.getConfiguratorGeneralContext(),
      };

      this.addDataDogAction(DATADOG_ACTIONS.configurator.option.selected, context);
    },

    configuratorShareLinkCopied() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.shareLink.copied, this.getConfiguratorGeneralContext());
    },

    configuratorOrderSubmitted() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.order.submitted, this.getConfiguratorGeneralContext());
    },

    configuratorOrderValidated() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.order.validated, this.getConfiguratorGeneralContext());
    },

    configuratorOrderCompleted() {
      this.addDataDogAction(DATADOG_ACTIONS.configurator.order.completed, this.getConfiguratorGeneralContext());
    },

    // STOCK CARS
    stockCarSelected(stockCarRegistrationNumber) {
      const stockCar = this.getMarketModelById(stockCarRegistrationNumber);

      if (!stockCar) return;

      const {
        id: registrationNumber,
        name: stockCarType,
      } = stockCar ?? {};

      const context = {
        registrationNumber,
        stockCarType,
      };

      this.addDataDogAction(DATADOG_ACTIONS.stockCars.selected, context);
    },
  },
});

export default initializeDatadog;

export { DataDogTracking };
