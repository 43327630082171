<template>
  <div
    v-if="enabled"
    ref="tooltipOuter"
    class="ui-tooltip"
    :data-position="position"
    :data-vertical-alignment="verticalAlignment"
    :data-open="open"
  >
    <div
      ref="tooltipInner"
      class="tooltip-inner"
    >
      <div
        v-if="$slots.title"
        class="tooltip-title"
      >
        <slot name="title" />
      </div>
      <div
        v-if="$slots.body"
        class="tooltip-body"
      >
        <slot name="body" />
      </div>
      <i class="arrow" />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { sleep } from '@/assets/js/helpers/general-helpers';

const POSITIONS = {
  bottom: 'bottom',
  top: 'top',
};

const VERTICAL = {
  center: 'center',
  left: 'left',
  right: 'right',
};

export default {
  name: 'UiTooltip',
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    useHover: {
      type: Boolean,
      default: false,
    },
    position: VueTypes.oneOf([...Object.values(POSITIONS)]).def(POSITIONS.bottom),
    overflowElement: {
      type: Node,
      default: undefined,
    },
  },
  data() {
    return {
      trigger: undefined,
      open: false,
      hasListener: false,
      verticalAlignment: '',
    };
  },
  watch: {
    enabled: {
      immediate: true,
      handler(isEnabled) {
        if (this.alwaysOpen) {
          this.open = true;
          return;
        }
        this.open = false;

        this.$nextTick(() => {
          const { tooltipOuter } = this.$refs;

          if (isEnabled && tooltipOuter) this.trigger = tooltipOuter.parentElement;

          if (this.trigger) {
            if (isEnabled && this.useHover) {
              this.trigger.setAttribute('data-tooltip-hover', true);
            } else if (!isEnabled && this.useHover) {
              this.trigger.removeAttribute('data-tooltip-hover');
            }

            if (isEnabled && !this.useHover) {
              this.trigger.addEventListener('click', this.toggleTooltip);
            }
          }
        });
      },
    },
  },
  beforeDestroy() {
    if (this.trigger) {
      this.trigger.removeEventListener('click', this.toggleTooltip);
    }
  },
  methods: {
    async checkVerticalOverflow() {
      const { tooltipOuter, tooltipInner } = this.$refs;

      if (!tooltipOuter || !tooltipInner) return undefined;

      const overflowValues = {
        center: 0,
        left: 0,
        right: 0,
      };

      const containerEl = this.overflowElement instanceof Node
        ? this.overflowElement
        : document.body;

      const containerRect = containerEl.getBoundingClientRect();
      const { left: containerLeft, right: containerRight } = containerRect;

      tooltipOuter.style.transitionDuration = '0s';
      tooltipOuter.style.transform = 'scale(1)';

      for (const verticalAlignment of Object.values(VERTICAL)) {
        this.verticalAlignment = verticalAlignment;
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick();

        const tooltipRect = tooltipInner.getBoundingClientRect();
        const { left: tooltipLeft, right: tooltipRight } = tooltipRect;

        const leftDiff = tooltipLeft - containerLeft;
        const overflowLeft = leftDiff < 0
          ? Math.sign(leftDiff) * leftDiff
          : 0;

        const rightDiff = containerRight - tooltipRight;
        const overflowRight = rightDiff < 0
          ? Math.sign(rightDiff) * rightDiff
          : 0;

        overflowValues[verticalAlignment] = overflowLeft + overflowRight;
      }

      tooltipOuter.style.removeProperty('transform');
      await sleep(1);
      tooltipOuter.style.removeProperty('transition-duration');

      return overflowValues;
    },
    async setVerticalAlignment() {
      const overflowValues = await this.checkVerticalOverflow();

      if (!overflowValues || overflowValues[VERTICAL.center] === 0) {
        this.verticalAlignment = VERTICAL.center;
        return;
      }

      const leastOverflow = Object.entries(overflowValues).reduce((previous, [key, value]) => (
        value < previous.value
          ? { key, value }
          : previous
      ), { key: '', value: Infinity });

      this.verticalAlignment = leastOverflow.key ?? VERTICAL.center;
    },
    async toggleTooltip() {
      const self = this;

      if (!this.open || this.alwaysOpen) {
        await this.setVerticalAlignment();

        this.open = true;

        const { tooltipInner } = this.$refs;

        if (!tooltipInner) return;

        if (!this.hasListener && !this.hover) {
          document.addEventListener('click', function globalClose(e) {
            self.hasListener = true;
            const isClickInside = self?.trigger?.contains?.(e.target) ?? false;
            if (!isClickInside) {
              self.open = false;
              document.removeEventListener('click', globalClose, false);
              self.hasListener = false;
            }
          }, false);
        }
      } else {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" src="./uiTooltip.scss" />
<style lang="scss" theme="audi" src="./uiTooltip.audi.scss" />
