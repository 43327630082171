export const FINANCING_OPTIONS = {
  cash: 'cash',
  loan: 'loan',
  leasing: 'leasing',
  offer: 'offer',
  link: 'link',
};

export const CUSTOMER_TYPES = {
  consumer: 'CONSUMER',
  company: 'COMPANY',
};

export const LOAN_SETTINGS = {
  downPayment: 'downPayment',
  paymentPeriod: 'paymentPeriod',
};

export const LEASING_SETTINGS = {
  annualMileage: 'annualMileage',
  advanceRent: 'advanceRent',
  leasePeriod: 'leasePeriod',
};

export const LOAN_KEYS = {
  cashPrice: 'CASH_PRICE',
  downPayment: 'DOWN_PAYMENT',
  downPaymentPercent: 'DOWN_PAYMENT_PERCENT',
  effectiveInterestRate: 'EFFECTIVE_INTEREST_RATE',
  establishmentFee: 'ESTABLISHMENT_FEE',
  loanAmount: 'LOAN_AMOUNT',
  loanNeeds: 'LOAN_NEEDS',
  loanPeriod: 'LOAN_PERIOD',
  monthlyInstallment: 'MONTHLY_INSTALLMENT',
  nominalInterestRate: 'NOMINAL_INTEREST_RATE',
  perTermFee: 'PER_TERM_FEE',
  propertyRegistrationFee: 'PROPERTY_REGISTRATION_FEE',
  totalCost: 'TOTAL_COST',
  totalLoanCost: 'TOTAL_LOAN_COST',
};

export const LEASING_KEYS = {
  advanceRent: 'ADVANCE_RENT',
  advanceRentEstablishmentFee: 'ADVANCE_RENT_ESTABLISHMENT_FEE',
  advanceRentPercent: 'ADVANCE_RENT_PERCENT',
  annualMileage: 'ANNUAL_MILEAGE',
  cashPrice: 'CASH_PRICE',
  establishmentFee: 'ESTABLISHMENT_FEE',
  nominalInterestRate: 'NOMINAL_INTEREST_RATE',
  leasePeriod: 'LEASE_PERIOD',
  monthlyInstallment: 'MONTHLY_INSTALLMENT',
  perTermFee: 'PER_TERM_FEE',
  residualValueTotal: 'RESIDUAL_VALUE_TOTAL',
  residualValuePercent: 'RESIDUAL_VALUE_PERCENT',
  totalCost: 'TOTAL_COST',
  totalMileage: 'TOTAL_MILEAGE',
  // @todo Maybe temp
  electricVatMonthlyInstallmentTotal: 'ELECTRIC_VAT_MONTHLY_INSTALLMENT_TOTAL',
  electricVatResidualValueTotal: 'ELECTRIC_VAT_RESIDUAL_VALUE_TOTAL',
  electricVatPerMonth: 'ELECTRIC_VAT_PER_MONTH',
  electricVatTotalCost: 'ELECTRIC_VAT_TOTAL_COST',
  electricVatProportionateVat: 'ELECTRIC_VAT_PROPORTIONATE_VAT',
};

export const FINANCING_DISCLAIMER_TYPES = {
  full: 'full',
  short: 'short',
};

export const MILEAGE_YEAR_PRICE_SETTINGS = {
  annualMileage: 'annualMileage',
  period: 'period',
};
