<template>
  <div
    class="layout-page-content"
    :class="{ 'page-content': isLoadContainer }"
    :data-no-padding-top="!paddingTop"
    :data-no-padding-bottom="!paddingBottom"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutPageContent',
  props: {
    paddingTop: {
      type: Boolean,
      default: true,
    },
    paddingBottom: {
      type: Boolean,
      default: true,
    },
    isLoadContainer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
  .layout-page-content {
    padding: 40px 0;

    @media #{$mq_screen__s} {
      padding: 4% 0;
    }

    @media #{$mq_screen__m} {
      padding: 3% 0;
    }

    @media #{$mq_screen__xl} {
      padding: 60px 0;
    }

    &[data-no-padding-top] {
      padding-top: 0;
    }

    &[data-no-padding-bottom] {
      padding-bottom: 0;
    }
  }
</style>
