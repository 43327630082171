<template>
  <div id="status-fullscreen-overlay" />
</template>

<script>
export default {
  name: 'StatusFullscreenOverlay',
};
</script>

<style scoped lang="scss">
#status-fullscreen-overlay {
  background: $color-black;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  z-index: 2000;
}
</style>
