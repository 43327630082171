export const CAR_ATTRIBUTES = {
  accessories: 'accessories',
  baseColorCode: 'baseColorCode',
  brand: 'brand',
  campaignPrice: 'campaignPrice',
  carBody: 'carBody',
  carId: 'carId',
  carWarranty: 'carWarranty',
  cargoVolume: 'cargoVolume',
  chassisNumber: 'chassisNumber',
  co2: 'co2',
  color: 'color',
  curbWeight: 'curbWeight',
  departmentId: 'departmentId',
  departmentName: 'departmentName',
  description: 'description',
  doors: 'doors',
  driveWheel: 'driveWheel',
  enginePowerHp: 'enginePowerHp',
  enginePowerKw: 'enginePowerKw',
  engineVolumeCcm: 'engineVolumeCcm',
  engineVolumeLiter: 'engineVolumeLiter',
  firstPublicationDate: 'firstPublicationDate',
  firstTimeRegOriginationCountry: 'firstTimeRegOriginationCountry',
  firstTimeRegistered: 'firstTimeRegistered',
  fuel: 'fuel',
  hasParticleFilter: 'hasParticleFilter',
  hasTwoSetOfWheels: 'hasTwoSetOfWheels',
  images: 'images',
  interior: 'interior',
  isCommissionCar: 'isCommissionCar',
  isDemoCar: 'isDemoCar',
  isImportedUsed: 'isImportedUsed',
  isNewCar: 'isNewCar',
  isRegistrationFeeIncluded: 'isRegistrationFeeIncluded',
  lastModifiedOn: 'lastModifiedOn',
  lastRegistrationDate: 'lastRegistrationDate',
  mileage: 'mileage',
  model: 'model',
  modelYear: 'modelYear',
  price: 'price',
  prospectHeading: 'prospectHeading',
  registrationFee: 'registrationFee',
  registrationNumber: 'registrationNumber',
  seats: 'seats',
  sellerName: 'sellerName',
  shortDescription: 'shortDescription',
  transmission: 'transmission',
  urlKey: 'urlKey',
  variant: 'variant',
  vehicleGroup: 'vehicleGroup',
  warrantyExpireDate: 'warrantyExpireDate',
  warrantyMileage: 'warrantyMileage',
};
