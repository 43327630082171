<script>
import { mapGetters, mapActions } from 'vuex';

// @todo @deprecated This mixin was only used for an Audi specific brick, and it's outdated

export default {
  computed: {
    ...mapGetters([
      'getConfigByKeyString',
      'getModelCurrentConfigStepKey',
    ]),
  },
  methods: {
    ...mapActions([
      'setLoader',
      'setAlert',
      'setConfigActiveModelId',
      'setModelSelectedMarketModel',
      'setModelConfigStepComplete',
      'setModelNextConfigStep',
    ]),
    async configureLine(marketModelId) {
      const configConfigurator = this.getConfigByKeyString('configurator');
      const { defaultSelectedModelId } = configConfigurator;

      this.setLoader(true);

      try {
        await this.setConfigActiveModelId({ modelId: defaultSelectedModelId });
      } catch (error) {
        // Do nothing
      }

      try {
        await this.setModelSelectedMarketModel({ marketModelId });
      } catch (error) {
        return;
      }

      try {
        await this.setModelConfigStepComplete();
        await this.setModelNextConfigStep();
        this.$router.push('/configurator');
      } catch (error) {
        this.setAlert({ key: 'marketModelOptionsError', message: 'error.fetchMarketModelOptions' });
      }
    },
  },
};
</script>
