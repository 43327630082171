import { Base64 } from 'js-base64';
import { CONFIGURATOR_STEPS } from '@/constants/constants-configurator';
import consoleLog from '@/assets/js/helpers/console-log';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';
import sortBy from 'lodash/sortBy';

const DEFAULT_DYNAMIC_PROPERTIES = {
  validate: true,
  isDynamic: true,
};

const CATEGORY_SORT_ORDERS = {
  listPrice: 'financing.listPrice',
  sortOrder: 'sortOrder',
  label: 'label',
};

const CATEGORY_SORT_ORDERS_MAP = {
  'prices.cash': CATEGORY_SORT_ORDERS.listPrice,
  sortOrder: CATEGORY_SORT_ORDERS.sortOrder,
  label: CATEGORY_SORT_ORDERS.label,
};

export const stepProperties = (stepKey = '') => {
  switch (stepKey) {
    case CONFIGURATOR_STEPS.login:
      return {
        sortOrder: -4,
        mainComponent: 'ConfiguratorStepLogin',
        hideNextNav: true,
      };
    case CONFIGURATOR_STEPS.line:
      return {
        sortOrder: -3,
        mainComponent: 'ConfiguratorStepLine',
        hideNextNav: false,
      };
    case CONFIGURATOR_STEPS.upgrade:
      return {
        sortOrder: -2,
        mainComponent: 'ConfiguratorStepUpgrades',
        hidePrevNav: true,
        hideNextNav: true,
        scrollOnEnter: false,
      };
    case CONFIGURATOR_STEPS.configuration:
      return {
        sortOrder: -1,
        mainComponent: 'ConfiguratorStepConfiguration',
        hidePrevNav: true,
        hideNextNav: true,
        scrollOnEnter: false,
      };
    case CONFIGURATOR_STEPS.summary:
      return {
        sortOrder: 9995,
        mainComponent: 'ConfiguratorStepSummary',
        hideNextNav: true,
      };
    case CONFIGURATOR_STEPS.financing:
      return {
        sortOrder: 9996,
        mainComponent: 'ConfiguratorStepFinancing',
        hideNextNav: true,
      };
    case CONFIGURATOR_STEPS.externalContract:
      return {
        sortOrder: 9997,
      };
    case CONFIGURATOR_STEPS.confirmation:
      return {
        sortOrder: 9998,
        mainComponent: 'ConfiguratorStepConfirmation',
        hidePrevNav: true,
        hideNextNav: true,
      };
    case CONFIGURATOR_STEPS.exterior:
      return {
        ...DEFAULT_DYNAMIC_PROPERTIES,
        mainComponent: 'ConfiguratorStepExterior',
      };
    case CONFIGURATOR_STEPS.interior:
      return {
        ...DEFAULT_DYNAMIC_PROPERTIES,
        mainComponent: 'ConfiguratorStepInterior',
      };
    case CONFIGURATOR_STEPS.accessories:
      return {
        ...DEFAULT_DYNAMIC_PROPERTIES,
        mainComponent: 'ConfiguratorStepAccessories',
      };
    case CONFIGURATOR_STEPS.packages:
      return {
        ...DEFAULT_DYNAMIC_PROPERTIES,
        mainComponent: 'ConfiguratorStepPackages',
      };
    case CONFIGURATOR_STEPS.standalone:
      return {
        ...DEFAULT_DYNAMIC_PROPERTIES,
        mainComponent: 'ConfiguratorStepStandalone',
      };
    default:
      return undefined;
  }
};

export const createConfigStep = ({ stepKey = '', properties = {} } = {}) => {
  if (!Object.values(CONFIGURATOR_STEPS).includes(stepKey)) return undefined;

  return {
    key: stepKey,
    sortOrder: 0,
    mainColumns: 12,
    mainComponent: false,
    asideComponent: false,
    asideMobile: false,
    label: '',
    navLabel: '',
    title: '',
    subtitle: '',
    completed: false,
    validate: false,
    hidePrevNav: false,
    hideNextNav: false,
    isDynamic: false,
    isExternal: false,
    scrollOnEnter: true,
    ...properties,
    ...stepProperties(stepKey),
  };
};

export const DEFAULT_CONFIGURATOR_KEY_OBJECT = () => ({
  marketModelId: '',
  selections: [],
  financing: {},
  mileageYearPriceSettings: [],
});

export const encodeConfiguratorKey = (
  configuratorKeyObject = DEFAULT_CONFIGURATOR_KEY_OBJECT(),
) => {
  try {
    return Base64.encode(JSON.stringify(configuratorKeyObject));
  } catch (error) {
    consoleLog(error);
    return '';
  }
};

export const decodeConfiguratorKey = (
  encodedConfiguratorKey,
) => JSON.parse(Base64.decode(encodedConfiguratorKey));

export const getShareConfigUrl = async () => {
  const router = await getClientRouter();
  const { currentRoute } = router || {};
  return currentRoute.path ?? '';
};

export const sortCategoryOptions = (options = [], sortOrder = CATEGORY_SORT_ORDERS.listPrice) => {
  const activeSortOrder = CATEGORY_SORT_ORDERS_MAP[sortOrder] ?? CATEGORY_SORT_ORDERS.listPrice;
  return sortBy(options, [activeSortOrder]);
};
