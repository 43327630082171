const CLIENTS = {
  audi: 'audi',
  cupra: 'cupra',
  seat: 'seat',
  skoda: 'skoda',
  vw: 'vw',
  carbuy: 'carbuy',
  catalog: 'catalog',
};

module.exports = { CLIENTS };
