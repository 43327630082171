import httpExternal from '@/http/httpExternal';

const BrregStore = {
  state: {
    brregApiHost: process.env.brregApiHost,
    brregEndpoints: {
      getCompany: (orgNumber) => `enheter/${orgNumber}`,
    },
    brregApiStatus: true,
    brregShowPlaceholder: false,
    brregOrgNumberValid: true,
  },
  getters: {
    brregGetCompanyUrl: (state) => (orgNumber) => `${state.brregApiHost}${state.brregEndpoints.getCompany(orgNumber)}`,
    brregApiStatus: (state) => state.brregApiStatus,
    brregShowPlaceholder: (state) => state.brregShowPlaceholder,
    brregOrgNumberValid: (state) => state.brregOrgNumberValid,
  },
  mutations: {
    UPDATE_BRREG_API_STATUS(state, brregApiStatus = true) {
      state.brregApiStatus = brregApiStatus;
    },
    UPDATE_BRREG_SHOW_PLACEHOLDER(state, brregShowPlaceholder = false) {
      state.brregShowPlaceholder = brregShowPlaceholder;
    },
    UPDATE_BRREG_ORG_NUMBER_VALID(state, brregOrgNumberValid = false) {
      state.brregOrgNumberValid = brregOrgNumberValid;
    },
  },
  actions: {
    async setCompanyInfoByOrgNumber({ getters, commit }) {
      let response;
      try {
        response = await httpExternal({
          method: 'get',
          url: getters.brregGetCompanyUrl(getters.companyInfo.orgNumber),
        });
      } catch (error) {
        const status = error?.response?.status;
        const isUp = status === 400 || status === 404;

        commit('UPDATE_BRREG_API_STATUS', isUp);
        commit('UPDATE_BRREG_SHOW_PLACEHOLDER', isUp);
        commit('UPDATE_BRREG_ORG_NUMBER_VALID', false);
        commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyName', value: '' });
        commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyAddress', value: '' });
        commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyZipCode', value: '' });
        commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyCity', value: '' });
        return;
      }
      const {
        navn: companyName = '',
        forretningsadresse: addressObj = {},
      } = response.data ?? {};

      const [address = ''] = addressObj.adresse ?? [];
      const {
        postnummer: zipCode = '',
        poststed: city = '',
      } = addressObj;

      commit('UPDATE_BRREG_ORG_NUMBER_VALID', true);
      commit('UPDATE_BRREG_SHOW_PLACEHOLDER', true);
      commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyName', value: companyName });
      commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyAddress', value: address });
      commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyZipCode', value: zipCode });
      commit('UPDATE_COMPANY_INFO_BY_KEY', { key: 'companyCity', value: city });
    },
  },
};

export default BrregStore;
