/* eslint-disable */
import { mediaCheck } from '@/assets/js/vendor/matchMedia.js';
import Swiper from 'swiper';

function articleGrid() {
  'use strict'

  $(document).ready(function ($) {
    var isDesktop;

    // Init all article rows
    function initArticles(visibleRows) {
      var articlesGrid = $('.article-grid-container');

      if (isDesktop && !articlesGrid.hasClass('edit-mode')) {
        var articlesContainers = $('.articles-container');

        articlesContainers.each(function () {
          var articlesContainer = $(this);
          var firstImage = $('img', articlesContainer)[0];

          if (firstImage) {
            if (firstImage.complete) {
              adaptArticleRow(articlesContainer, visibleRows);
            }else{
              firstImage.addEventListener('load', function() {
                adaptArticleRow(articlesContainer, visibleRows);
              })
            }
          }else{
            adaptArticleRow(articlesContainer, visibleRows);
          }
        });
      }
    }

    // Adapt article row
    function adaptArticleRow(articlesContainer, visibleRows){
      var articlesInRow = 0;
      var articles = articlesContainer.find('.article');
      var articlesCount = articles.length;

      articles.removeClass('visible-item');

      // Count articles per row
      articles.each(function () {
        if ($(this).prev().length > 0) {
          if ($(this).position().top != $(this).prev().position().top) {
            return false;
          }
          articlesInRow++;
        }
        else {
          articlesInRow++;
        }
      });

      // Count articles in last row
      var articlesInLastRow = articles.length % articlesInRow;
      if (articlesInLastRow === 0) {
        articlesInLastRow = articlesInRow;
      }

      // Get height of each visible row and calculate visible height
      var index = 1;
      var rowHeight = 0;
      var visibleArticles = articlesInRow * visibleRows;

      while (index <= visibleRows) {
        var firstInRow = index * articlesInRow - articlesInRow;
        if ($('.article:eq(' + firstInRow + ')', articlesContainer).length) {
          rowHeight = rowHeight + $('.article:eq(' + firstInRow + ')', articlesContainer).outerHeight(true);
        }
        index++;
      }

      // Set container height and set the value to data-closed-height
      articlesContainer.height(rowHeight);
      articlesContainer.attr('data-closed-height', rowHeight);


      // Give class to articles in visible rows
      articles.each(function (i) {

        $(this).addClass('visible-item');

        if (i == (articlesInRow * visibleRows - 1)) {
          return false;
        }

      });

      // Hide toggle button if there are no more articles to show
      if (articlesCount > visibleArticles) {
        articlesContainer.parent().find('.btn-toggle-articles').removeClass('hidden');
      } else {
        articlesContainer.parent().find('.btn-toggle-articles').addClass('hidden');
      }


    }

    // Toggle visible articles
    function toggleArticles(that) {
      var articlesContainer = that.parent().find('.articles-container');
      var articlesHeight = articlesContainer.find('.articles-inner').outerHeight();
      var speed = 500;

      articlesContainer.toggleClass('open');
      that.toggleClass('close');

      if (that.hasClass('close')) {
        that.text('Vis mindre');
        articlesContainer.stop().animate({height: articlesHeight}, speed, function () {
          $(this).removeAttr('style');
        });
      } else {
        that.text('Vis mer');
        articlesContainer.stop().animate({height: articlesContainer.attr('data-closed-height')}, speed, function () {

        });
      }
    }

    $('.btn-toggle-articles').on('click', function () {
      toggleArticles($(this));
    })


    // Init swiper for mobile
    var swiperContainers = $('.article-grid-wrapper .swiper');
    mediaCheck({
      media: '(max-width: 767px)',
      entry: function () {
        isDesktop = false;

        // Set up swipers
        $('.articles-container').removeAttr('style');
        swiperContainers.each(function (index) {
          if (!$(this)[0].swiper) {
            var slideWidth = 1;
            if ($(this).hasClass('icons')) {
              slideWidth = 1;
            } else {
              slideWidth = 1.1;
            }

            var that = $(this);
            that.addClass('swiper-' + index);

            var articleSwiper = new Swiper('.article-grid-wrapper .swiper.swiper-' + index, {
              autoHeight: true,
              slidesPerView: slideWidth,
              centeredSlides: true,
              spaceBetween: 8,
              loop: true,
              pagination: {
                el: '.article-grid-wrapper .swiper.swiper-' + index + ' .swiper-pagination',
                clickable: true
              }
            });
          }
        });
      },
      exit: function () {
        isDesktop = true;

        // Init article grid
        initArticles(2);

        // Destroy swipers
        swiperContainers.each(function (index) {
          try {
            if ($(this)[0].swiper) {
              $(this)[0].swiper.destroy();
            }
          }
          catch (e) {
            // No swiper instance found to destroy
          }
        });
      }
    });


    // Resize events
    $(window).resize(function () {
      initArticles(2);
      // Close on resize
      $('.articles-container').removeClass('open');
      $('.btn-toggle-articles').removeClass('close');
      $('.btn-toggle-articles').text('Vis mer');
    });
  })

};

export { articleGrid };
