<template>
  <div class="cms-snippet">
    <AdminLink :admin-link="adminLink" :title="adminLinkTitle" />
    <div
      v-if="animate"
      :id="`block-${_uid}`"
      ref="snippetContent"
      class="cms-snippet-outer"
      style="height: 0; opacity: 0;"
    >
      <div class="cms-snippet-inner">
        <div class="cms-content">
          <component :is="bindLinks" />
        </div>
      </div>
    </div>
    <component
      :is="bindLinks"
      v-else
      ref="snippetContent"
    />
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import httpInternal from '@/http/httpInternal';
import Velocity from 'velocity-animate';
import { getBrickScripts } from '@/assets/js/helpers/cms-helpers';
import consoleLog from '@/assets/js/helpers/console-log';
import { mapGetters } from 'vuex';
import { initLazyScroll } from '@/assets/js/bricks/lazy-scroll';
import CmsMethods from '@/mixins/cmsMethods.vue';
import ModelStandardEquipment from '@/components/snippets/ModelStandardEquipment.vue';
import LayoutBaseContainer from '@/components/layout/LayoutBaseContainer.vue';
import UiLink from '@/components/ui/uiLink/UiLink.vue';
import UiTooltip from '@/components/ui/uiTooltip/UiTooltip.vue';
import AdminLink from '@/components/administration/adminMode/AdminLink.vue';

export default {
  name: 'CmsSnippet',
  components: {
    AdminLink,
  },
  props: {
    snippetId: {
      type: String,
      default: '',
    },
    addRootPath: {
      type: Boolean,
      default: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      adminData: {},
      internalBlock: this.snippetId,
    };
  },
  computed: {
    ...mapGetters([
      'apiGetSnippetUrl',
    ]),
    bindLinks() {
      const self = this;

      return {
        mixins: [CmsMethods],
        components: {
          ModelStandardEquipment,
          LayoutBaseContainer,
          UiLink,
          UiTooltip,
        },
        data() {
          return self.$attrs;
        },
        template: `<div class="vue-cms-block">${self.content}</div>`,
      };
    },
    adminLink() {
      return this.adminData?.deeplink ?? '';
    },
    adminLinkTitle() {
      return `CMS Snippet: ${this.snippetId}`;
    },
  },
  beforeMount() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.animate) {
        this.prepareAnimation();
      }

      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: (this.apiGetSnippetUrl(this.addRootPath) + this.internalBlock),
          },
        );

        if (response.data && response.data.html !== null) {
          this.content = response.data.html;
          this.adminData = response.data.admin ?? {};

          await this.$nextTick();
          const { snippetContent } = this.$refs;
          if (snippetContent) getBrickScripts(snippetContent);
          initLazyScroll();
          this.$emit('snippetLoaded', true);

          if (this.animate) {
            this.animateOpen();
          }
        }
      } catch (error) {
        this.$emit('snippetLoaded', true);
        consoleLog(error);
      }
    },
    prepareAnimation() {
      const outer = $(`#block-${this._uid}`);
      outer.velocity('stop');
    },
    animateOpen() {
      const outer = $(`#block-${this._uid}`);
      const inner = $(`#block-${this._uid} .cms-snippet-inner`);
      const newHeight = inner.outerHeight(true);

      outer.velocity('stop');
      inner.velocity('stop');

      Velocity(
        outer,
        { height: newHeight },
        {
          duration: 300,
          complete() {
            Velocity(
              outer,
              { opacity: 1 },
              {
                duration: 300,
                complete() {
                  outer.removeAttr('style');
                },
              },
            );
          },
        },
      );
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/styles/source/_snippets.scss';
@import '~@/assets/styles/source/_bricks.scss';

.cms-snippet {
  position: relative;
}
</style>
