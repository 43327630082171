import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';

const GtmTracking = Vue.extend({
  created() {
    Vue.use(VueGtm, this.options);
  },
  methods: {
    trackEvent(event = '', eventData = {}) {
      if (event === 'PageLoad') {
        this.$gtm.trackEvent({ event: 'VirtualPageView', pageTitle: eventData.title, pagePath: eventData.path });
      } else {
        const data = { event, ...eventData };
        this.$gtm.trackEvent(data);
      }
    },
  },
});

export default GtmTracking;
