import httpInternal from '@/http/httpInternal';
// import { readCookie } from '@/assets/js/helpers/general-helpers';
import consoleLog from '@/assets/js/helpers/console-log';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';

const DealerStore = {
  state: {
    dealerLoggedIn: false,
    loggedInDealer: {
      firstName: '',
      lastName: '',
    },

    // frontendSessionLocal: null,
  },
  getters: {
    isDealerLoggedIn: (state) => state.dealerLoggedIn,
    getLoggedInDealer: (state) => state.loggedInDealer,

    // frontendSessionLocal: (state) => state.frontendSessionLocal,
  },
  mutations: {
    UPDATE_DEALER_LOGGED_IN(state, dealerLoggedIn = false) {
      state.dealerLoggedIn = dealerLoggedIn;
    },

    UPDATE_DEALER_INFO_BY_KEY(state, { key = '', value = '' }) {
      this._vm.$set(state.loggedInDealer, key, value);
    },

    // UPDATE_FRONTEND_SESSION_LOCAL(state, frontendSessionLocal = null) {
    //   state.frontendSessionLocal = frontendSessionLocal;
    // },
  },
  actions: {
    async loginDealer({ getters, commit, dispatch }, { password = '' }) {
      try {
        await httpInternal({
          method: 'post',
          url: getters.apiGetAuthenticateDealerUrl,
          data: {
            password,
          },
        });

        dispatch('logout', false);
        commit('UPDATE_DEALER_LOGGED_IN', true);
        return Promise.resolve();

        // dispatch('setAlert', { key: 'loginError', message: response.data.msg });
        // consoleLog(`Could not login dealer: ${response.data.msg}`);
        // return Promise.reject(response.data.msg);
      } catch (error) {
        dispatch('setAlert', { key: 'dealerLoginError', message: 'error.dealer.login' });
        consoleLog(`Something went wrong when logging in dealer: ${error}`);
        return Promise.reject(new Error('error.dealerLogin'));
      }
    },
    async setDealerInputAndValidate({ state, commit }, { key = '', value = '' } = {}) {
      if (!Object.prototype.hasOwnProperty.call(state.loggedInDealer, key)) return;
      commit('UPDATE_DEALER_INFO_BY_KEY', { key, value });
    },
    async setDealerMobileNumberAndValidate({ commit, dispatch }, dealerMobileNumber) {
      commit('UPDATE_DEALER_MOBILE_NUMBER', dealerMobileNumber);
      await dispatch('validate', { key: 'dealerMobileNumber', value: dealerMobileNumber });
    },
    async logoutDealer({ commit }) {
      commit('UPDATE_DEALER_LOGGED_IN', false);
      // try {
      //   const response = await httpInternal({
      //     method: 'post',
      //     url: getters.apiLogoutMagentoCustomerUrl,
      //     data: {},
      //     withCredentials: true,
      //   });
      //   if (response.data.error === false) {
      //     dispatch('markDealerAsLoggedOut');
      //     return response;
      //   }
      //   dispatch('setAlert', { key: 'logoutError', message: response.data.msg });
      //   consoleLog(`Could not logout dealer: ${response.data.msg}`);
      //   return Promise.reject(response.data.msg);
      // } catch (error) {
      //   dispatch('setAlert', { key: 'logoutError', message: 'error.logout' });
      //   consoleLog(`Something went wrong when logging out dealer: : ${error}`);
      //   return Promise.reject(new Error('error.logout'));
      // }
    },
    async markDealerAsLoggedOut({ commit, dispatch }) {
      const router = await getClientRouter();

      commit('UPDATE_DEALER_LOGGED_IN');
      commit('UPDATE_LOGGED_IN_DEALER_NUMBER');
      commit('UPDATE_LOGGED_IN_USERNAME');
      commit('UPDATE_SALESMAN_NUMBER');
      commit('UPDATE_FRONTEND_SESSION_LOCAL');
      await dispatch('setSelectedDealer');
      if (router.currentRoute.meta.requiresAuth === true) {
        router.push({ path: '/signin', query: { redirect: router.currentRoute.path } });
        dispatch('setLoader', false);
      }
    },
  },
};

export default DealerStore;
