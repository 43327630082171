import Vue from 'vue';

const TrackingStore = {
  actions: {
    async initTracking({ getters }) {
      if (!Vue.prototype.$tracking.datadog) {
        await Vue.prototype.$tracking.installDataDog();
      }

      if (!getters.allowCookies) return;

      const trackingConfig = {
        gtm: {},
        smartDigital: {},
        adobe: {},
      };

      const gtmDebug = process.env.NODE_ENV === 'development';
      const gtmCode = getters.getConfigByKeyString('google.gtm.code');
      const gtmEnabled = !!gtmCode;

      trackingConfig.gtm = {
        id: gtmCode,
        enabled: gtmEnabled,
        debug: gtmDebug,
        vueRouter: this.$router,
        ignoredViews: [],
      };

      const smartDigitalConfig = getters.getConfigByKeyString('smartdigital');
      if (smartDigitalConfig) {
        trackingConfig.smartDigital = smartDigitalConfig;
      }

      trackingConfig.adobe.enabled = true;

      await Vue.prototype.$tracking.install(Vue, trackingConfig);
    },
  },
};

export default TrackingStore;
