<template>
  <UiModal
    v-if="apiQueueReady"
    class="api-queue-modal"
    :is-open="showModal"
    :disable-close="true"
  >
    <template #header>
      <div class="modal-title">
        {{ $t('apiQueue.info') }}
      </div>
    </template>
    <template #body>
      <div class="modal-subtitle">
        {{ $t('apiQueue.estimatedWait') }}
        <span class="estimated-time">
          {{ estimatedQueueTime }} {{ $t(estimateTimeUnit) }}
        </span>
      </div>
    </template>
    <template #footer>
      <UiButton :link="leaveApiQueue">
        {{ $t('general.cancel') }}
      </UiButton>
    </template>
  </UiModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UiModal from '@/components/ui/uiModal/UiModal.vue';
import UiButton from '@/components/ui/uiButton/UiButton.vue';

export default {
  name: 'ApiQueueModal',
  components: {
    UiModal,
    UiButton,
  },
  data() {
    return {
      estimatedQueueTime: 0,
      countdown: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'apiQueue',
      'apiQueueReady',
    ]),
    estimateTimeUnit() {
      return this.estimatedQueueTime === 1 ? 'general.second' : 'general.seconds';
    },
    showModal() {
      return this.apiQueue.estimatedWait > 3000;
    },
  },
  watch: {
    'apiQueue.estimatedWait': {
      handler() {
        this.startCountdown();
      },
    },
  },
  methods: {
    ...mapActions([
      'leaveApiQueue',
    ]),
    startCountdown() {
      clearInterval(this.countdown);

      this.estimatedQueueTime = this.apiQueue.estimatedWait > 0
        ? this.apiQueue.estimatedWait / 1000
        : 0;
      this.countdown = setInterval(() => {
        this.estimatedQueueTime -= 1;
        if (this.estimatedQueueTime <= 0) {
          this.estimatedQueueTime = 0;
          clearInterval(this.countdown);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
  .api-queue-modal {
    .modal-wrapper {
      max-width: 570px;
    }

    .estimated-time {
      font-weight: $font-weight__bold;
    }

    .ui-button {
      width: 100%;
    }
  }
</style>
