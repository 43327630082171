<template>
  <CommonApp />
</template>

<script>
import CommonApp from '@/common-app.vue';

export default {
  name: 'SeatApp',
  metaInfo: {
    title: process.env.siteName,
  },
  components: {
    CommonApp,
  },
};
</script>
