import { CONFIGURATOR_STEPS } from '@/constants/constants-configurator';

const CONFIGURATOR_PREFIX = 'Configurator - ';
const STEP_VISITED_PREFIX = 'Step Visited - ';
const USER_VALIDATION_PREFIX = 'User Validation - ';
const ORDER_PREFIX = 'Order - ';
const STOCK_CARS_PREFIX = 'Stock Cars - ';

export const DATADOG_ACTIONS = {
  general: {
    buttonClick: 'Button Click',
  },

  nps: {
    submitScore: 'User submitted NPS',
    submitFeedback: 'User submitted text feedback in NPS',
    dismissed: 'User dismissed NPS modal',
  },

  cookies: {
    accepted: 'Cookies - Accepted',
    rejected: 'Cookies - Rejected',
  },

  configurator: {
    stepVisited: {
      [CONFIGURATOR_STEPS.login]: `${CONFIGURATOR_PREFIX}${STEP_VISITED_PREFIX}Login`,
      [CONFIGURATOR_STEPS.line]: `${CONFIGURATOR_PREFIX}${STEP_VISITED_PREFIX}Line`,
      [CONFIGURATOR_STEPS.configuration]: `${CONFIGURATOR_PREFIX}${STEP_VISITED_PREFIX}Configuration`,
      [CONFIGURATOR_STEPS.financing]: `${CONFIGURATOR_PREFIX}${STEP_VISITED_PREFIX}Financing`,
      [CONFIGURATOR_STEPS.confirmation]: `${CONFIGURATOR_PREFIX}${STEP_VISITED_PREFIX}Confirmation`,
    },
    stepChanged: `${CONFIGURATOR_PREFIX}Step Changed`,
    userValidation: {
      started: `${CONFIGURATOR_PREFIX}${USER_VALIDATION_PREFIX}Started`,
      enterPhoneNumber: `${CONFIGURATOR_PREFIX}${USER_VALIDATION_PREFIX}Phone Number Entered`,
      enterCode: `${CONFIGURATOR_PREFIX}${USER_VALIDATION_PREFIX}Code Entered`,
    },
    shareLink: {
      copied: `${CONFIGURATOR_PREFIX}Share Link Copied`,
    },
    model: {
      selected: `${CONFIGURATOR_PREFIX}Model Selected`,
    },
    marketModel: {
      selected: `${CONFIGURATOR_PREFIX}Market Model Selected`,
    },
    option: {
      selected: `${CONFIGURATOR_PREFIX}Option Selected`,
    },
    order: {
      submitted: `${CONFIGURATOR_PREFIX}${ORDER_PREFIX}Submitted`,
      validated: `${CONFIGURATOR_PREFIX}${ORDER_PREFIX}Validated`,
      completed: `${CONFIGURATOR_PREFIX}${ORDER_PREFIX}Completed`,
    },
  },
  stockCars: {
    selected: `${STOCK_CARS_PREFIX}Car Selected`,
  },
};
