import httpExternal from '@/http/httpExternal';
import get from 'lodash/get';
import consoleLog from '@/assets/js/helpers/console-log';

const UserSelfServiceStore = {
  state: {
    ussEndpoints: {
      loginUser: 'api/login-user',
      logoutUser: 'api/logout-user',
      enrollUser: 'api/enroll-user',
      enrollNewUser: 'api/enroll-new-user',
      createNewUser: 'api/create-new-user',
      confirmOtp: 'api/confirm-otp',
      confirmUser: 'api/confirm-user',
      customerInfo: 'api/users/{uuid}/customer-info',
      userInfo: 'api/user-info',
      profile: 'api/profile',
      sessionInfo: 'protected/session-info',
      sessionCustomerInfo: 'protected/session-customer-info',
    },
  },
  getters: {
    ussHost: (state, getters) => getters.getConfigByKeyString('userSelfService.ussUrl'),
    ussCode: (state, getters) => getters.getConfigByKeyString('userSelfService.ussCode'),
    ussLoginUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.loginUser}`,
    ussLogoutUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.logoutUser}`,
    ussEnrollUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.enrollUser}`,
    ussEnrollNewUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.enrollNewUser}`,
    ussCreateNewUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.createNewUser}`,
    ussConfirmOtpUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.confirmOtp}`,
    ussConfirmUserUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.confirmUser}`,
    ussCustomerInfoUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.customerInfo}`
      .replace('{uuid}', getters.userInfo.userUuid),
    ussUserInfoUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.userInfo}`,
    ussProfileUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.profile}`,
    ussSessionInfoUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.sessionInfo}`,
    ussSessionCustomerInfoUrl: (state, getters) => `${getters.ussHost}${state.ussEndpoints.sessionCustomerInfo}`,
  },
  actions: {
    // UserSelfService
    // @TODO Do we really use this?
    ussEnrollUser({ getters }) {
      return httpExternal(
        {
          method: 'post',
          url: getters.ussEnrollUserUrl,
          data: {
            phoneNumber: getters.userInfo.mobileNumber,
            countryCode: getters.userInfo.countryCode,
            phoneCountryCode: getters.userInfo.phoneCountryCode,
          },
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
        },
      );
    },
    ussEnrollNewUser({ getters }, {
      phoneNumber = getters.userInfo.mobileNumber,
    } = {}) {
      return httpExternal(
        {
          method: 'post',
          url: getters.ussEnrollNewUserUrl,
          data: {
            phoneNumber,
            countryCode: getters.userInfo.countryCode,
            phoneCountryCode: getters.userInfo.phoneCountryCode,
          },
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
        },
      );
    },
    async ussCreateNewUser({ getters, commit, dispatch }) {
      try {
        const response = await httpExternal({
          method: 'post',
          url: getters.ussCreateNewUserUrl,
          data: {
            userInfo: {
              firstName: getters.userInfo.firstName,
              lastName: getters.userInfo.lastName,
              mobileNumber: getters.userInfo.mobileNumber,
              email: getters.userInfo.email,
              city: getters.userInfo.city,
              zipCode: getters.userInfo.zipCode,
              addressCountry: getters.userInfo.countryCode,
              addressLine: getters.userInfo.address,
              retailerServiceId: null, // @TODO this is required by this call
              retailerSalesId: getters.user.selectedDealer, // @TODO this is required by this call
            },
            phoneNumber: getters.userInfo.mobileNumber,
            phoneCountryCode: getters.userInfo.phoneCountryCode,
            countryCode: getters.userInfo.countryCode,
            otp: getters.user.oneTimePassword,
            // @todo NO NEW USER PASSWORD
            password: 'randomStringThatIsNotUsed',
            // password: getters.userCreation.password,
          },
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
        });

        const userUuid = get(response, 'data.createdUser.userUuid');
        const partyId = get(response, 'data.createdUser.partyId');

        if (userUuid && partyId) {
          commit('UPDATE_USER_INFO_BY_KEY', { key: 'userUuid', value: userUuid });
          commit('UPDATE_USER_INFO_BY_KEY', { key: 'partyId', value: partyId });
          commit('UPDATE_USER', {
            newUserCreated: true,
          });
          commit('RESET_USER_CREATION');
          return true;
        }
        dispatch('setAlert', { key: 'newUserError', message: 'error.createUser' });
        return false;
      } catch (error) {
        dispatch('setAlert', { key: 'newUserError', message: 'error.createUser' });
        return false;
      }
    },
    async ussConfirmOtp({ getters, commit, dispatch }, otp = getters.user.otp) {
      try {
        await httpExternal(
          {
            method: 'post',
            url: getters.ussConfirmOtpUrl,
            data: {
              otp,
              phoneNumber: getters.userInfo.mobileNumber,
              countryCode: getters.userInfo.countryCode,
              phoneCountryCode: getters.userInfo.phoneCountryCode,
            },
            headers: {
              'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
              'Content-Type': 'application/json',
            },
          },
        );
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch('setAlert', { key: 'loginError', message: 'error.otpValidate' });
          commit('UPDATE_OTP_ERROR', 'error.otpValidate');
          commit('UPDATE_LOGIN_ERROR', 'error.otpValidate');
        } else {
          dispatch('setAlert', { key: 'loginError', message: 'error.otpService' });
          commit('UPDATE_LOGIN_ERROR', 'error.otpService');
        }
        return false;
      }
    },
    // @TODO Do we really use this?
    ussConfirmUser({ getters }) {
      return httpExternal(
        {
          method: 'post',
          url: getters.ussConfirmUserUrl,
          data: {
            phoneNumber: getters.userInfo.mobileNumber,
            countryCode: getters.userInfo.countryCode,
            phoneCountryCode: getters.userInfo.phoneCountryCode,
            oneTimePassword: getters.userInfo.oneTimePassword,
          },
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
        },
      );
    },
    async ussUserInfo({ getters, dispatch, commit }) {
      try {
        return await httpExternal(
          {
            method: 'post',
            url: getters.ussUserInfoUrl,
            data: {
              otp: getters.userOtp,
              phoneNumber: getters.userInfo.mobileNumber,
              countryCode: getters.userInfo.countryCode,
              phoneCountryCode: getters.userInfo.phoneCountryCode,
            },
            headers: {
              'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (error) {
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
          if (error.response && error.response.status === 401) {
            dispatch('setAlert', { key: 'loginError', message: 'error.otpValidate' });
            commit('UPDATE_OTP_ERROR', 'error.otpValidate');
            commit('UPDATE_LOGIN_ERROR', 'error.otpValidate');
          } else {
            dispatch('setAlert', { key: 'loginError', message: 'error.otpService' });
            commit('UPDATE_LOGIN_ERROR', 'error.otpService');
          }
        } else {
          dispatch('setAlert', { key: 'loginError', message: 'error.otpService' });
          commit('UPDATE_LOGIN_ERROR', 'error.otpService');
          consoleLog(`Something went wrong when getting customer info: ${error}`);
        }
        throw error;
      }
    },
    async ussGetCustomerInfo({ getters, dispatch, commit }) {
      let customerInfoResponse = '';
      try {
        customerInfoResponse = await httpExternal(
          {
            method: 'post',
            url: getters.ussCustomerInfoUrl,
            data: {
              otp: getters.userOneTimePassword,
              phoneNumber: getters.userInfo.mobileNumber,
              countryCode: getters.userInfo.countryCode,
              phoneCountryCode: getters.userInfo.phoneCountryCode,
            },
            headers: {
              'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
              'Content-Type': 'application/json',
            },
          },
        );
        return customerInfoResponse;
      } catch (error) {
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
          if (error.response && error.response.status === 401) {
            dispatch('setAlert', { key: 'loginError', message: 'error.otpValidate' });
            commit('UPDATE_OTP_ERROR', 'error.otpValidate');
            commit('UPDATE_LOGIN_ERROR', 'error.otpValidate');
          } else if (error.response && error.response.status === 409) {
            dispatch('setAlert', { key: 'loginError', message: 'error.phoneMatch' });
            commit('UPDATE_LOGIN_ERROR', 'error.phoneMatch');
          } else {
            dispatch('setAlert', { key: 'loginError', message: 'error.otpService' });
            commit('UPDATE_LOGIN_ERROR', 'error.otpService');
          }
        } else {
          dispatch('setAlert', { key: 'loginError', message: 'error.otpService' });
          commit('UPDATE_LOGIN_ERROR', 'error.otpService');
          consoleLog(`Something went wrong when getting customer info: ${error}`);
        }
        throw error;
      }
    },
    ussLoginUser({ getters }, payload) {
      return httpExternal(
        {
          method: 'post',
          url: getters.ussLoginUserUrl,
          data: {
            username: payload.username,
            password: payload.password,
            countryCode: payload.countryCode,
            phoneCountryCode: payload.phoneCountryCode,
          },
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
    },
    async ussLogoutUser({ getters }) {
      return httpExternal(
        {
          method: 'post',
          url: getters.ussLogoutUserUrl,
          headers: {
            'X-Moller-UserSelfService-Api-Client-Id': getters.ussCode,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
    },
  },
};

export default UserSelfServiceStore;
