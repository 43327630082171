export const CONFIGURATOR_STEPS = {
  login: 'login',
  line: 'line',
  summary: 'summary',
  financing: 'financing',
  externalContract: 'externalContract',
  confirmation: 'confirmation',
  exterior: 'exterior',
  interior: 'interior',
  accessories: 'accessories',
  packages: 'packages',
  standalone: 'standalone',
  configuration: 'configuration',
  upgrade: 'upgrade',
  stockCar: 'stockCar',
};

export const DYNAMIC_IMAGE_TYPES = {
  all: 'all',
  interior: 'interior',
  interior360: 'interior360',
  exterior: 'exterior',
  exterior360: 'exterior360',
};

export const DYNAMIC_IMAGE_MODES = {
  studio: 'studio',
  exterior360: 'exterior360',
  interior360: 'interior360',
};

export const OPTION_TYPES = {
  accessory: 'accessory',
  equipment: 'equipment',
  exteriorColor: 'exteriorColor',
  interiorColor: 'interiorColor',
  comboProduct: 'comboProduct',
};

export const ORDER_INSTIGATOR = {
  customer: 'customer',
  salesperson: 'salesperson',
  system: 'system',
};

export const SELECTION_TYPES = {
  standard: 'STANDARD',
  optional: 'OPTIONAL',
};

export const CATEGORY_LAYOUTS = {
  default: 'default',
  checkbox: 'checkbox',
  checkboxPlain: 'checkbox-plain',
  imageBox: 'image-box',
  swatch: 'swatch',
  modal: 'modal',
};

export const CONFIGURATOR_PREVIEW_KEY = 'veileder';
