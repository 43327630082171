/* eslint-disable */
import { mediaCheck } from '@/assets/js/vendor/matchMedia.js';
import Swiper from 'swiper';

function ctaScripts() {
  'use strict';

  let ctaBlocks = {
    init: function () {
      this.setupCtaBlocks();
    },
    setupCtaBlocks: function () {

      var isDesktop;

      // Init swiper for mobile
      var swiperContainers = $('.cta-list-container .swiper');
      mediaCheck({
        media: '(max-width: 767px)',
        entry: function () {
          isDesktop = false;

          // Set up swipers
          swiperContainers.each(function (index) {
            var that = $(this);
            if (!that[0].swiper) {
              var slideCount = that.find('.swiper-slide').length;

              if (slideCount > 1) {
                that.addClass('swiper-' + index);

                var ctaSwiper = new Swiper('.cta-list-container .swiper.swiper-' + index, {
                  autoHeight: true,
                  slidesPerView: 1,
                  centeredSlides: true,
                  spaceBetween: 8,
                  loop: true,
                  pagination: {
                    el: '.cta-list-container .swiper.swiper-' + index + ' .swiper-pagination',
                  },
                });
              }
            }
          });

        },
        exit: function () {
          isDesktop = true;

          // Destroy swipers
          swiperContainers.each(function (index) {
            try {
              if ($(this)[0].swiper) {
                $(this)[0].swiper.destroy();
              }
            }
            catch (e) {
              // No swiper instance found to destroy
            }
          });
        }
      });

    }
  };

  ctaBlocks.init();
}

export {ctaScripts};
