import { NPS_DATA_KEYS } from '@/constants/constants-nps';
import i18n from '@/setup/i18n-init';
import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_ACTIONS } from '@/constants/constants-datadog';

const NPS_DEFAULT_LABELS = () => ({
  lowest: i18n.tc('nps.general.labels.lowest'),
  highest: i18n.tc('nps.general.labels.highest'),
});

const NPS_DEFAULT_TIMESTAMPS = () => ({
  responseValidTo: null,
  dismissValidTo: null,
});

const NPS_DEFAULT_USER_DATA = () => ({
  score: null,
  feedback: null,
});

const createNpsDataItem = (npsKey) => {
  let question = '';
  const labels = null;

  switch (npsKey) {
    case NPS_DATA_KEYS.newCarConfigurationComplete:
      question = i18n.tc('nps.newCarConfigurationComplete.question');
      break;
    case NPS_DATA_KEYS.stockCarConfigurationComplete:
      question = i18n.tc('nps.stockCarConfigurationComplete.question');
      break;
    default:
      break;
  }

  return {
    question,
    labels: labels ?? NPS_DEFAULT_LABELS(),
    timestamps: NPS_DEFAULT_TIMESTAMPS(),
    userData: NPS_DEFAULT_USER_DATA(),
  };
};

const NpsStore = {
  state: {
    npsStates: {},
  },
  getters: {
    getNpsScaleValues: () => [...Array(11).keys()],

    getNpsState: (state) => (npsKey) => state.npsStates[npsKey] ?? {},

    isValidNpsState: () => (npsKey) => {
      if (!npsKey) return false;
      return Object.values(NPS_DATA_KEYS).includes(npsKey);
    },
    allowedToShowNps: (state, getters) => (npsKey) => {
      if (!getters.isValidNpsState(npsKey)) return false;

      const { dismissValidTo, responseValidTo } = getters.getNpsState(npsKey)?.timestamps ?? {};

      if (!dismissValidTo && !responseValidTo) return true;

      const currentTime = Date.now();

      if (currentTime < responseValidTo) return false;
      return currentTime >= dismissValidTo;
    },
  },
  mutations: {
    UPDATE_NPS_STATE_ITEM(state, {
      npsKey,
      stateData = createNpsDataItem(npsKey),
    } = {}) {
      this._vm.$set(state.npsStates, npsKey, stateData);
    },

    UPDATE_NPS_STATE_TIMESTAMP_DISMISS(state, { npsKey, timestamp = Date.now() } = {}) {
      this._vm.$set(state.npsStates[npsKey].timestamps, 'dismissValidTo', timestamp);
    },

    UPDATE_NPS_STATE_TIMESTAMP_RESPONSE(state, { npsKey, timestamp = Date.now() }) {
      this._vm.$set(state.npsStates[npsKey].timestamps, 'responseValidTo', timestamp);
    },

    UPDATE_NPS_STATE_USER_SCORE(state, { npsKey, score = null }) {
      this._vm.$set(state.npsStates[npsKey].userData, 'score', score);
    },

    UPDATE_NPS_STATE_USER_FEEDBACK(state, { npsKey, feedback = null }) {
      this._vm.$set(state.npsStates[npsKey].userData, 'feedback', feedback);
    },
  },
  actions: {
    async initNps({ dispatch }) {
      const npsStatePromises = Object.values(NPS_DATA_KEYS).map((npsKey) => dispatch('resetNpsStateItem', { npsKey }));

      await Promise.all(npsStatePromises);
    },

    async resetNpsStateItem({ getters, commit }, {
      npsKey,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;
      const stateData = createNpsDataItem(npsKey);

      const { dismissValidTo, responseValidTo } = getters.getNpsState(npsKey)?.timestamps ?? {};

      if (dismissValidTo) stateData.timestamps.dismissValidTo = dismissValidTo;
      if (responseValidTo) stateData.timestamps.responseValidTo = responseValidTo;

      commit('UPDATE_NPS_STATE_ITEM', { npsKey, stateData });
    },

    async setNpsDismissValidTo({ getters, commit }, {
      npsKey,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;

      const now = new Date();
      const expirationDate = now.setHours(now.getHours() + 3);
      commit('UPDATE_NPS_STATE_TIMESTAMP_DISMISS', { npsKey, timestamp: expirationDate });

      datadogRum.addAction(DATADOG_ACTIONS.nps.dismissed, {
        npsKey,
      });
    },

    async setNpsResponseValidTo({ getters, commit }, {
      npsKey,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;

      const now = new Date();
      const expirationDate = now.setMonth(now.getMonth() + 2);
      commit('UPDATE_NPS_STATE_TIMESTAMP_RESPONSE', { npsKey, timestamp: expirationDate });
    },

    async setNpsUserScore({ getters, commit, dispatch }, {
      npsKey,
      score = null,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;
      commit('UPDATE_NPS_STATE_USER_SCORE', { npsKey, score });
      await dispatch('submitNpsScore', { npsKey });
    },

    async setNpsUserFeedback({ getters, commit }, {
      npsKey,
      feedback = null,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;
      commit('UPDATE_NPS_STATE_USER_FEEDBACK', { npsKey, feedback });
    },

    async submitNpsScore({ getters, dispatch }, {
      npsKey,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;

      const { score } = getters.getNpsState(npsKey)?.userData ?? {};

      datadogRum.addAction(DATADOG_ACTIONS.nps.submitScore, {
        score,
        npsKey,
      });

      await dispatch('setNpsResponseValidTo', { npsKey });
    },

    async submitNpsFeedback({ getters }, {
      npsKey,
    } = {}) {
      if (!getters.isValidNpsState(npsKey)) return;

      const { feedback } = getters.getNpsState(npsKey)?.userData ?? {};

      if (!feedback) return;

      datadogRum.addAction(DATADOG_ACTIONS.nps.submitFeedback, {
        feedback,
        npsKey,
      });
    },
  },
};

export default NpsStore;
