import httpInternal from '@/http/httpInternal';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';

export const ConfigStore = {
  state: {
    modules: {},
  },
  getters: {
    isModuleEnabled: (state) => (moduleName) => {
      const module = state.modules[moduleName];
      return module ? module.enabled : false;
    },
    isModuleChecking: (state) => (moduleName) => {
      const module = state.modules[moduleName];
      return module ? module.checking : false;
    },
  },
  mutations: {
    INIT_MODULE(state, module) {
      this._vm.$set(state.modules, module, {
        checking: true,
        enabled: false,
      });
    },
    MODULE_LOADED(state, { module, status }) {
      const moduleObject = state.modules[module];
      moduleObject.enabled = status;
      moduleObject.checking = false;
    },
  },
  actions: {
    async checkModule({ state, getters, commit }, { module, redirect = false } = {}) {
      let enabled = false;

      const returnHandler = async () => {
        if (enabled === false && redirect) {
          const router = await getClientRouter();

          try {
            await router.push({ path: '/404' });
          } catch (error) {
            // Do nothing..
          }
        }

        return enabled;
      };

      if (!module) return returnHandler();

      if (state.modules[module] && !getters.isModuleChecking(module)) {
        enabled = getters.isModuleEnabled(module);
        return returnHandler();
      }

      if (getters.isModuleChecking(module)) {
        enabled = await new Promise((resolve) => {
          const maxChecks = 50;
          let currentCheck = 0;
          const check = () => {
            currentCheck += 1;
            getters.isModuleChecking(module) && currentCheck < maxChecks
              ? setTimeout(check, 200)
              : resolve(getters.isModuleEnabled(module));
          };
          setTimeout(check, 200);
        });

        return returnHandler();
      }

      commit('INIT_MODULE', module);
      try {
        const response = await httpInternal({
          method: 'get',
          url: getters.apiCheckModuleUrl,
          params: {
            module,
          },
        });

        enabled = response.data.enabled;
      } catch (error) {
        enabled = false;
      }

      commit('MODULE_LOADED', { module, status: enabled });

      return returnHandler();
    },
  },
};

export default ConfigStore;
