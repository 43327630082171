/* eslint-disable */

const Video = (function(window, document){
  "use strict";

  var create = function(elem){
    if(elem.tagName == "IFRAME" && elem.src.match(/youtube\.com\/embed\//i)){
      return new yt(elem);
    }

    throw "Video element type not supported";
  };


  // YouTube

  var yt = function(elem){
    this.queue = new Queue();
    var videoObject = this;
    ytLoader(function(YT){
      videoObject.player = new YT.Player(elem, {
        events: {
          onReady: function(){ videoObject.queue.ready(); }
        }
      });
    });
  };

  yt.prototype.play = function(){
    var videoObject = this;
    this.queue.push(function(){
      videoObject.player.playVideo();
    });
  };

  yt.prototype.pause = function(){
    var videoObject = this;
    this.queue.push(function(){
      videoObject.player.pauseVideo();
    });
  };

  yt.prototype.mute = function(){
    var videoObject = this;
    this.queue.push(function(){
      videoObject.player.mute();
    });
  };

  yt.prototype.reset = function(){
    var videoObject = this;
    this.queue.push(function(){
      videoObject.player.pauseVideo();
      videoObject.player.seekTo(0);
    });
  };

  yt.prototype.loadVideo = function(youtubeId){
    var videoObject = this;
    this.queue.push(function(){
      videoObject.player.loadVideoById(youtubeId);
    });
  };

  yt.prototype.ended = function(callback){
    var videoObject = this;
    this.queue.push(function() {
      videoObject.player.addEventListener("onStateChange", function(event){
        if(event.data == 0){
          callback();
        }
      });
    });
  };


  // Queue

  var Queue = function(){
    this.queue = [];
    this.isReady = false;
    this.param = null;
  };

  Queue.prototype.push = function(callback){
    if(!callback)
      return;

    if(this.isReady){
      callback(this.param);
    }
    else{
      this.queue.push(callback);
    }
  };

  Queue.prototype.ready = function(param){
    this.isReady = true;
    this.param = param;
    for(var i = 0; i < this.queue.length; ++i){
      this.queue[i](param);
    }
    this.queue = [];
  };


  // YouTube loader

  var ytInitiated = false;
  var ytQueue = new Queue();

  window.onYouTubeIframeAPIReady = function(){
    ytQueue.ready(window.YT);
  };

  var ytLoader = function(callback){
    if(!ytInitiated){
      ytInitiated = true;
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    ytQueue.push(callback);
  };

  return {
    create: create,
    yt: yt,
    ytLoader: ytLoader,
  };
})(window, document);

export { Video };
