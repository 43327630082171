<template>
  <div class="layout-base-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutBaseContainer',
};
</script>

<style lang="scss">
  .layout-base-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $indent__m;
    padding-right: $indent__m;

    // 768px +
    @media #{$mq_screen__s} {
      max-width: $screen__xl;
      padding-left: $indent__l;
      padding-right: $indent__l;
    }

    @media #{$mq_screen__xl} {
      max-width: 1620px;
    }
  }
</style>
