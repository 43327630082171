<template>
  <div id="site-alerts">
    <div class="alerts">
      <transition-group
        appear
        name="fade-alert"
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div
          v-for="(alert, key) in alerts"
          :key="'alert' + key"
          class="alert"
          :class="alert.type"
        >
          <div class="alert-inner">
            <div class="alert-content base-container">
              {{ parseAlertMessage(alert.message) }}
              <div
                class="remove-alert"
                @click="removeAlert(key)"
              />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Velocity from 'velocity-animate';
import { isTranslation } from '@/assets/js/helpers/general-helpers';

export default {
  name: 'StatusAlert',
  computed: {
    ...mapGetters([
      'alerts',
    ]),
  },
  methods: {
    ...mapActions([
      'setAlert',
      'removeAlert',
    ]),
    parseAlertMessage(message) {
      return isTranslation(message)
        ? this.$t(message)
        : message;
    },
    beforeEnter(el) {
      const element = el;
      element.style.opacity = 0;
      element.style.height = 0;
    },
    enter(el, done) {
      const innerHeight = $(el)
        .find('.alert-inner')
        .outerHeight(true);

      Velocity(
        el,
        { height: innerHeight },
        {
          complete() {
            Velocity(
              el,
              { opacity: 1 },
              { complete: done },
            );
          },
        },
      );
    },
    leave(el, done) {
      Velocity(
        el,
        { opacity: 0 },
        {
          complete() {
            Velocity(
              el,
              { height: 0 },
              { complete: done },
            );
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" src="./statusAlert.scss"></style>
<style lang="scss" theme="skoda" src="./statusAlert.skoda.scss"></style>
