import Vue from 'vue';

/* eslint-disable */
function accordionScripts() {
  'use strict';

  let accordions = {
    init: function () {
      this.setupAccordions();
    },
    setupAccordions: function () {
      let accordionTrigger = '.seat-accordion .group-title';
      $(document).off('click.accordion', accordionTrigger);
      $(document).on('click.accordion', accordionTrigger, function() {
        let trigger = $(this);
        let control = trigger.attr('aria-controls');
        let allContent = trigger.closest('.seat-accordion').find('.group-content');
        let allTrigger = trigger.closest('.seat-accordion').find('.group-title');
        let content = trigger.closest('.seat-accordion').find('#' + control);

        trigger.attr('aria-expanded', function (index, attr) {
          if (attr == "true") {
            content.slideUp(200);
          } else {
            allContent.not(trigger).slideUp(200);
            allContent.not(trigger).attr('aria-hidden', "true");
            allTrigger.not(control).attr('aria-expanded', "false");
            allTrigger.not(trigger).find('.zmdi-chevron-down').removeClass('open');
            content.slideDown(200);

            try {
              Vue.prototype.$tracking.adobe.accordionClick({
                headline: trigger.attr('data-headline'),
                title: trigger.attr('data-title'),
              });
            } catch (e) {
              // Do nothing..
            }
          }
          return attr == 'true' ? 'false' : 'true';
        });
        content.attr('aria-hidden', function (index, attr) {
          return attr == 'true' ? 'false' : 'true';
        });
        trigger.find('.zmdi-chevron-down').toggleClass('open');
      });
    },
  };
  accordions.init();
}

export {accordionScripts};
