<template>
  <div class="dealer-logout-button">
    <UiButton
      :action-type="actionType"
      :contrast="contrast"
      :link="logout"
    >
      {{ $t('login.logout') }}
    </UiButton>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import VueTypes from 'vue-types';
import UiButton from '@/components/ui/uiButton/UiButton.vue';

export default {
  name: 'DealerLogoutButton',
  components: {
    UiButton,
  },
  props: {
    actionType: VueTypes.oneOf(['primary', 'secondary'])
      .def('secondary'),
    contrast: VueTypes.oneOf(['dark', 'light', ''])
      .def(''),
    callbackFunction: {
      type: Function,
      default() {
        return true;
      },
    },
  },
  methods: {
    ...mapMutations([
      'RESET_USER',
    ]),
    ...mapActions([
      'setLoader',
      'setAlert',
      'logoutDealer',
    ]),
    async logout() {
      // @todo Why not use the logout action?
      this.logoutError = false;
      this.setLoader(true);
      this.RESET_USER();
      try {
        await this.logoutDealer();
        this.setAlert({ key: 'logout', message: 'succeeded.logout', type: 'success' });
        this.callbackFunction();
        this.setLoader(false);
      } catch (error) {
        this.setAlert({ key: 'errorLogout', message: 'error.logout' });
      }
    },
  },
};
</script>
